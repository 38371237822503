import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { KeycloakService } from '../shared/services/keycloak.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard  {
    constructor(private keycloakService: KeycloakService) {}

    canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.keycloakService.isAuthenticated();
    }
}
