import { Component } from '@angular/core';
import { SavingsCalculationsService } from './savings-calculations.service';

@Component({
    selector: 'app-dax',
    templateUrl: './dax.component.html'
})
export class DaxComponent {

    nextButtonEnabled = false;
    step = 0;

    constructor(private savingsCalculationsService: SavingsCalculationsService) {}

    enableNextButton(enable: boolean) {
        this.nextButtonEnabled = enable;
        if(enable) {
            this.startCalculations();
        }
    }

    startCalculations() {
        this.savingsCalculationsService.calculateSavingsAndReturns();
    }
}
