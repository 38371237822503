<dx-text class="page__heading" type="h2">Mein Gesundheitsplan</dx-text>
<dx-card>
    <dx-card-header label="Ihre Vorteile"></dx-card-header>
    <dx-card-content>
        <div class="main-container">
            <div class="content-container">
                <div class="plaene-container">
                    <div
                        *ngIf="
                    this.gesundheitsdaten.anzahlFehlendeZaehne !== 4 &&
                    this.gesundheitsdaten.anzahlWurzelBehandelteZaehne !== 4 &&
                    !gesundheitsdaten.basisPlan.includes('EMPTY')
                "
                        [ngClass]="{
                    'plan-box': true,
                    'plan-width-big': !showFourOrMoreOptions,
                    'plan-width-small': showFourOrMoreOptions
                }"
                    >
                        <div
                            *ngIf="!gesundheitsdaten.basisPlan.includes('PLANZ') && !gesundheitsdaten.basisPlan.includes('PLANJ')"
                            class="plan-icon"
                        >
                            <img src="assets/svg/gesundheitsplaner/zusammenfassung/SchutzIcon.svg" alt="Schutz" />
                        </div>
                        <div *ngIf="gesundheitsdaten.basisPlan.includes('PLANZ')" class="plan-icon">
                            <img src="assets/svg/gesundheitsplaner/Zahn.svg" alt="Zahn"/>
                        </div>
                        <div *ngIf="gesundheitsdaten.basisPlan.includes('PLANJ')" class="plan-icon">
                            <img src="assets/svg/gesundheitsplaner/zusammenfassung/juniorIcon.svg" alt="Junior"/>
                        </div>
                        <div
                            [ngClass]="{
                        'plan-name': true,
                        'plan-name-big': !showFourOrMoreOptions,
                        'plan-name-small': showFourOrMoreOptions
                    }"
                        >
                            {{ selectedPlanText }}
                        </div>
                        <div class="plan-betrag">{{ planValue || 0 | currency: 'EUR':'symbol':'1.2-2' }}</div>
                    </div>
                    <div
                        [ngClass]="{
                    'plan-box': true,
                    'plan-width-big': !showFourOrMoreOptions,
                    'plan-width-small': showFourOrMoreOptions
                }"
                        *ngIf="showPlanAmbulant"
                    >
                        <div class="plan-icon"><img src="assets/svg/gesundheitsplaner/zusammenfassung/Pillicon.svg" alt="Pille" />
                        </div>
                        <div
                            [ngClass]="{
                        'plan-name': true,
                        'plan-name-big': !showFourOrMoreOptions,
                        'plan-name-small': showFourOrMoreOptions
                    }"
                        >
                            Plan Ambulant
                        </div>
                        <div class="plan-betrag">{{ planAmbulantValue || 0 | currency: 'EUR':'symbol':'1.2-2' }}</div>
                    </div>
                    <div
                        [ngClass]="{
                    'plan-box': true,
                    'plan-width-big': !showFourOrMoreOptions,
                    'plan-width-small': showFourOrMoreOptions
                }"
                        *ngIf="showPlanStationaer"
                    >
                        <div class="plan-icon"><img
                            src="assets/svg/gesundheitsplaner/zusammenfassung/Hospitalicon.svg" alt="Krankenhaus" />
                        </div>
                        <div
                            [ngClass]="{
                        'plan-name': true,
                        'plan-name-big': !showFourOrMoreOptions,
                        'plan-name-small': showFourOrMoreOptions
                    }"
                        >
                            Plan Stationär
                        </div>
                        <div class="plan-betrag">{{ planStationaerValue || 0 | currency: 'EUR':'symbol':'1.2-2' }}</div>
                    </div>
                    <div
                        [ngClass]="{
                    'plan-box': true,
                    'plan-width-big': !showFourOrMoreOptions,
                    'plan-width-small': showFourOrMoreOptions
                }"
                        *ngIf="
                    (showEKTZorKTBU && gesundheitsdaten.basisPlan !== 'PLANJ' && gesundheitsdaten.monatlichesBruttoEinkommen > 450) ||
                    gesundheitsdaten.hatKeinEigenesEinkommen === false
                "
                    >
                        <div class="plan-icon"><img src="assets/svg/gesundheitsplaner/zusammenfassung/ektz.svg" alt="EKTZ" /></div>
                        <div
                            [ngClass]="{
                        'plan-name': true,
                        'plan-name-big': !showFourOrMoreOptions,
                        'plan-name-small': showFourOrMoreOptions
                    }"
                        >
                            {{ ektzOrKTBUText }}
                        </div>
                        <div *ngIf="ektzOrKTBUText === 'EKTZ'" class="plan-betrag">
                            {{ kassenzetteldaten.ektz | currency: 'EUR':'symbol':'1.2-2' }}
                        </div>
                        <div *ngIf="ektzOrKTBUText === 'KTBU'" class="plan-betrag">
                            {{ kassenzetteldaten.ktbu | currency: 'EUR':'symbol':'1.2-2' }}
                        </div>
                    </div>
                    <div
                        [ngClass]="{
                    'plan-box': true,
                    'plan-width-big': !showFourOrMoreOptions,
                    'plan-width-small': showFourOrMoreOptions
                }"
                        *ngIf="fitbonusValue > 0 || sponsorGesucht"
                    >
                        <div class="plan-icon"><img src="assets/svg/gesundheitsplaner/zusammenfassung/bkk.svg" alt="BKK" /></div>
                        <div
                            [ngClass]="{
                        'plan-name': true,
                        'plan-name-big': !showFourOrMoreOptions,
                        'plan-name-small-bkk': showFourOrMoreOptions
                    }"
                        >
                            Ihr Sponsor BKK Linde
                        </div>
                        <div *ngIf="sponsorGesucht" class="plan-betrag"
                             [ngClass]="{'plan-betrag-fitbonus': fitbonusValue > 0}">
                            FitBonus<sup
                            class="plan-betrag-sup">+</sup> {{ allowedActivitiesRefundDividedBy12 + optionalActivitiesRefundDividedBy12 || 0 | currency: 'EUR':'symbol':'1.2-2' }}
                            pro Monat
                        </div>
                        <div *ngIf="fitbonusValue > 0" class="plan-betrag">
                            Wechselvorteil {{ fitbonusValue || 0 | currency: 'EUR':'symbol':'1.2-2' }}
                        </div>
                    </div>
                </div>

                <div class="vorteile-container">
                    <div class="space-above"></div>

                    <div *ngIf="showBasisschutzPlus">
                        <div class="vorteil">
                            <div class="vorteil-icon"><img
                                src="assets/svg/gesundheitsplaner/zusammenfassung/CheckboxGreen.svg" alt="Vorteil" /></div>
                            <div class="vorteil-text">Hochwertige Versorgungsformen beim Zahnarzt</div>
                        </div>

                        <div class="hr-line"></div>
                        <div class="vorteil">
                            <div class="vorteil-icon"><img
                                src="assets/svg/gesundheitsplaner/zusammenfassung/CheckboxGreen.svg" alt="Vorteil" /></div>
                            <div class="vorteil-text">Zuschuss zu Ihrer Brille, Kontaktlinsen oder einer LASIK-Operation
                            </div>
                        </div>

                        <div class="hr-line"></div>
                        <div class="vorteil">
                            <div class="vorteil-icon"><img
                                src="assets/svg/gesundheitsplaner/zusammenfassung/CheckboxGreen.svg" alt="Vorteil" /></div>
                            <div class="vorteil-text">
                                Weltweiter Versicherungsschutz für die ersten 60 Tage einer privaten oder beruflichen
                                Auslandsreise
                            </div>
                        </div>
                    </div>

                    <div class="vorteil" *ngIf="showMeinZahnschutz">
                        <div class="vorteil-icon"><img
                            src="assets/svg/gesundheitsplaner/zusammenfassung/CheckboxGreen.svg" alt="Vorteil"/></div>
                        <div class="vorteil-text">
                            {{ zahnschutzProzentValue }} % Erstattung beim Zahnersatz, Zahn- und Parodontosebehandlung
                            oder
                            Zahnreinigung
                        </div>
                    </div>

                    <div *ngIf="showPlanJunior">
                        <div class="vorteil">
                            <div class="vorteil-icon"><img
                                src="assets/svg/gesundheitsplaner/zusammenfassung/CheckboxGreen.svg" alt="Vorteil"/></div>
                            <div class="vorteil-text">
                                Hochwertigen Versorgungsformen beim Zahnarzt und bei kieferorthopädischen Maßnahmen, wie
                                z.
                                B. Zahnspangen.
                            </div>
                        </div>
                        <div class="hr-line"></div>
                        <div class="vorteil">
                            <div class="vorteil-icon"><img
                                src="assets/svg/gesundheitsplaner/zusammenfassung/CheckboxGreen.svg" alt="Vorteil"/></div>
                            <div class="vorteil-text">Erstattung für Ihre Brille, Kontaktlinsen oder eine
                                LASIK-Operation.
                            </div>
                        </div>
                        <div class="hr-line"></div>
                        <div class="vorteil">
                            <div class="vorteil-icon"><img
                                src="assets/svg/gesundheitsplaner/zusammenfassung/CheckboxGreen.svg" alt="Vorteil"/></div>
                            <div class="vorteil-text">Weltweiter Versicherungsschutz für die ersten 60 Tage einer
                                Auslandsreise
                            </div>
                        </div>
                    </div>

                    <div *ngIf="showPlanAmbulant" class="hr-line"></div>
                    <div class="vorteil" *ngIf="showPlanAmbulant">
                        <div class="vorteil-icon"><img
                            src="assets/svg/gesundheitsplaner/zusammenfassung/CheckboxGreen.svg" alt="Vorteil"/></div>
                        <div class="vorteil-text">
                            80 % Erstattung bei Behandlungen auf Basis alternativer Heilmethoden inkl. Arzneimittel,
                            Videosprechstunden,
                            Vorsorgeuntersuchungen und GKV-Präventionskurse, Schutzimpfungen
                        </div>
                    </div>

                    <div *ngIf="showPlanStationaer">
                        <div class="hr-line"></div>
                        <div class="vorteil">
                            <div class="vorteil-icon"><img
                                src="assets/svg/gesundheitsplaner/zusammenfassung/CheckboxGreen.svg" alt="Vorteil" /></div>
                            <div class="vorteil-text">Chefarztbehandlung inkl. vor- und nachstationärer Behandlung</div>
                        </div>
                        <div class="hr-line"></div>
                        <div class="vorteil">
                            <div class="vorteil-icon"><img
                                src="assets/svg/gesundheitsplaner/zusammenfassung/CheckboxGreen.svg" alt="Vorteil" /></div>
                            <div class="vorteil-text">Ein- oder Zweibettzimmer und freie Krankenhauswahl</div>
                        </div>
                        <div class="hr-line"></div>
                        <div class="vorteil">
                            <div class="vorteil-icon"><img
                                src="assets/svg/gesundheitsplaner/zusammenfassung/CheckboxGreen.svg" alt="Vorteil" /></div>
                            <div class="vorteil-text">Rooming-in einer Begleitperson bei Kindern/Jugendlichen</div>
                        </div>
                        <div class="hr-line"></div>
                        <div class="vorteil">
                            <div class="vorteil-icon"><img
                                src="assets/svg/gesundheitsplaner/zusammenfassung/CheckboxGreen.svg" alt="Vorteil" /></div>
                            <div class="vorteil-text">Ambulante Operationen</div>
                        </div>
                        <div class="hr-line"></div>
                        <div class="vorteil">
                            <div class="vorteil-icon"><img
                                src="assets/svg/gesundheitsplaner/zusammenfassung/CheckboxGreen.svg" alt="Vorteil" /></div>
                            <div class="vorteil-text">Krankentransporte zum und vom Krankenhaus (bis zu 100 km)</div>
                        </div>
                    </div>

                    <div class="space-above"></div>
                    <div class="beitrag-container">
                        <div class="beitrag-text-container">
                            <div class="beitrag-text">Monatlicher Beitrag</div>
                        </div>
                        <div
                            class="beitrag-value-container"
                            *ngIf="
                        gesundheitsdaten.sponsorGesucht === true &&
                        (kassenzetteldaten.gesamtFitBonus > 0 || kassenzetteldaten.beitragsVorteil > 0)
                    "
                        >
                            <p>Ohne Sponsor BKK Linde</p>
                            <div
                                class="beitrag-value">{{ monatlicherBeitragOhneSponsorBKKLinde | currency: 'EUR':'symbol':'1.2-2' }}
                            </div>
                        </div>
                        <div class="beitrag-value-mit-sponsor-container">
                            <p
                                *ngIf="
                            gesundheitsdaten.sponsorGesucht === true &&
                            (kassenzetteldaten.gesamtFitBonus > 0 || kassenzetteldaten.beitragsVorteil > 0)
                        "
                            >
                                Mit BKK Linde
                            </p>
                            <div *ngIf="!gesundheitsdaten.sponsorGesucht"
                                 class="beitrag-value">{{ monatlicherBeitrag - allowedActivitiesRefundDividedBy12 - optionalActivitiesRefundDividedBy12  | currency: 'EUR':'symbol':'1.2-2' }}
                            </div>
                            <div *ngIf="gesundheitsdaten.sponsorGesucht"
                                 class="beitrag-value">{{ monatlicherBeitrag - kassenzetteldaten.beitragsVorteil - allowedActivitiesRefundDividedBy12 - optionalActivitiesRefundDividedBy12  | currency: 'EUR':'symbol':'1.2-2' }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="space-below"></div>
            </div>
        </div>
        <app-card-footer [disablePrev]="!showPrevBtn" (prevPage)="prevPage()" [showNext]="false"></app-card-footer>
    </dx-card-content>
</dx-card>
