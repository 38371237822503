<p>13.04.2017</p>
<div class="headline">
    <p>Impressum</p>
</div>
<p>
    Die Deutsche Vermögensberatung AG (DVAG) ist eine Aktiengesellschaft deutschen Rechts mit Hauptsitz in Frankfurt am Main. Sie ist im
    Handelsregister des Amtsgerichts Frankfurt am Main unter der Nummer HRB 15511 eingetragen.
</p>
<div class="headline">
    <p>Vorsitzender des Aufsichtsrats</p>
</div>
<p>Friedrich Bohl</p>
<div class="headline">
    <p>Vorstandsvorsitzender</p>
</div>
<p>Andreas Pohl</p>
<div class="headline">
    <p>Umsatzsteuer-Identifikationsnummer gemäß § 27a Umsatzsteuergesetz</p>
</div>
<p>DE114139839</p>
<div class="headline">
    <p>Deutsche Vermögensberatung</p>
</div>
<p>
    Aktiengesellschaft · DVAG <br />
    Wilhelm-Leuschner-Straße 24 <br />
    60329 Frankfurt am Main <br />
    Tel. Nr.: 069 23 84-0 <br />
    Fax Nr.: 069 23 84-185 <br />
    <a rel="noopener" href="https://www.dvag.de/dvag/index.html" target="_blank">www.dvag.com</a> <br />
    E-Mail: <a href="mailto:info@dvag.com">info&#64;dvag.com</a>
</p>
<p>
    Im Versicherungsbereich als gebundener Vermittler nach § 34d Abs. 7 GewO (Gewerbeordnung) ausschließlich vermittelnd tätig für die
    AachenMünchener Lebensversicherung, Generali Deutschland Versicherung, Central Krankenversicherung, Generali Pensionskasse, ADVOCARD
    Rechtschutzversicherung.
</p>
<div class="headline">
    <p>Schlichtungsstellen</p>
</div>

<p>
    Verein Versicherungsombudsmann e. V. , Postfach 080632, 10006 Berlin Ombudsmann Private Kranken- und Pflegeversicherung, Postfach
    060222, 10052 Berlin <br />
    <a rel="noopener" href="https://www.versicherungsombudsmann.de" target="_blank">https://www.versicherungsombudsmann.de</a> ,
    <a rel="noopener" href="https://www.pkv-ombudsmann.de" target="_blank">https://www.pkv-ombudsmann.de</a>
</p>
<p>
    Im Investmentbereich als Finanzanlagenvermittler gemäß § 34f Abs. 1 Nr. 1 GewO vermittelnd tätig für: DWS Investment GmbH, DWS
    Investment S.A., Generali Investments Deutschland, Allianz Global Investors, Allianz Global Investors Luxembourg, SEB Investment, DWS
    Grundbesitz GmbH.
</p>
<div class="headline">
    <p>Erlaubnis- und Aufsichtsbehörde nach § 34c und § 34i GewO</p>
</div>
<p>
    Stadt Frankfurt am Main <br />
    Ordnungsamt <br />
    Kleyerstraße 86 <br />
    60326 Frankfurt am Main <br />
    <a rel="noopener" href="https://frankfurt.de/service-und-rathaus/verwaltung/aemter-und-institutionen/ordnungsamt" target="_blank">
        www.ordnungsamt.frankfurt.de</a
    >
</p>
<div class="headline">
    <p>Erlaubnis- und Aufsichtsbehörde nach § 34f GewO</p>
</div>
<p>
    IHK Frankfurt am Main <br />
    Börsenplatz 4 <br />
    60313 Frankfurt am Main <br />
    <a rel="noopener" href="https://www.frankfurt-main.ihk.de" target="_blank"> www.frankfurt-main.ihk.de</a>
</p>
<div class="headline">
    <p>Gemeinsame Registerstelle für § 34d GewO und § 34f GewO und § 34i GewO</p>
</div>
<p>
    Deutscher Industrie- und Handelskammertag (DIHK) e. V. <br />
    Breite Straße 29 <br />
    10178 Berlin <br />
    Tel. Nr.: 0180 600585-0 <br />
    (20 Cent/Anruf aus dem deutschen Festnetz; höchstens 60 Cent/Anruf aus Mobilfunknetzen) <br />
    <a rel="noopener" href="https://www.vermittlerregister.info" target="_blank">www.vermittlerregister.info</a>
    Registernummer nach § 34d GewO: D-LYYB-BSPX5-17 <br />
    Registernummer nach § 34f GewO: D-F-125-93J4-60 <br />
    Registernummer nach § 34i GewO: D-W-125-ZDTB-79 <br />
</p>
<p>
    Anregungen zur Gestaltung oder Fragen zum Inhalt dieser Webseiten senden Sie bitte an:
    <a href="mailto:digitale-medien@dvag.com">
        digitale-medien&#64;dvag.com
    </a>
</p>

<p>Herausgegeben vom Bereich Digitale Medien, Frankfurt am Main.</p>
<p>© Deutsche Vermögensberatung Aktiengesellschaft · DVAG, Frankfurt am Main. Alle Rechte vorbehalten.</p>
<div class="headline">
    <p>Rechtliche Hinweise</p>
</div>
<p>
    Inhalt und Struktur der Webseiten der Deutschen Vermögensberatung Aktiengesellschaft DVAG sind urheberrechtlich geschützt. Die
    Vervielfältigung von Informationen oder Daten, insbesondere die Verwendung von Texten, Textteilen oder Bildmaterial, bedarf der
    vorherigen Zustimmung der Deutschen Vermögensberatung Aktiengesellschaft DVAG.
</p>
<p>
    Information gemäß § 36 Abs. 1 Verbraucherstreitbeilegungsgesetz (VSBG): Die Deutsche Vermögensberatung Aktiengesellschaft DVAG nimmt
    nicht an Schlichtungsverfahren gemäß VSBG teil und ist dazu auch nicht verpflichtet.
</p>
