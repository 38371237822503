import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VersicherteZeitComponent } from './versicherte-zeit.component';
import { TimetableComponent } from './timetable/timetable.component';
import { SharedModule } from '../../shared/shared.module';
import { DesignSystemModule } from '@dvag/design-system-angular';
import { CardFooterComponent } from '../../layout/card-footer/card-footer.component';

@NgModule({
    declarations: [VersicherteZeitComponent, TimetableComponent],
    imports: [CommonModule, SharedModule, DesignSystemModule, CardFooterComponent]
})
export class VersicherteZeitModule {}
