import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DaxComponent } from './dax.component';
import { DaxSavingComponent } from './dax-saving/dax-saving.component';
import { DaxChartComponent } from './dax-chart/dax-chart.component';
import { DaxComparisonComponent } from './dax-comparison/dax-comparison.component';
import { ChartModule } from 'primeng/chart';
import { SavingsInputComponent } from './savings-input/savings-input.component';
import { SliderModule } from 'primeng/slider';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { DesignSystemModule } from '@dvag/design-system-angular';
import { CardFooterComponent } from '../../layout/card-footer/card-footer.component';

@NgModule({
    declarations: [DaxComponent, DaxSavingComponent, DaxChartComponent, DaxComparisonComponent, SavingsInputComponent],
    imports: [CommonModule, ChartModule, SliderModule, FormsModule, ReactiveFormsModule, SharedModule, DesignSystemModule, CardFooterComponent]
})
export class DaxModule {}
