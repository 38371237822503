import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GesundheitsplanerService } from '../../services/gesundheitsplaner.service';
import { Gender } from '../gender.enum';

@Component({
    selector: 'app-kinderauswahl',
    templateUrl: './kinderauswahl.component.html',
    styleUrls: ['./kinderauswahl.component.scss']
})
export class KinderauswahlComponent {
    showNextBtn = false;
    showPrevBtn = true;
    showResult = false;
    firstRow = Rows.FIRST_ROW;
    secondRow = Rows.SECOND_ROW;
    firstRowArray = [false, false, false, false, false];
    secondRowArray = [false, false, false, false, false];
    protected readonly Gender = Gender;

    constructor(private router: Router, private route: ActivatedRoute, private gesundheitsplanerService: GesundheitsplanerService) {}

    activateNextButton() {
        this.showNextBtn = true;
    }

    nextPage() {
        if (this.showResult === false) {
            this.showResult = true;
        } else {
            this.router.navigate(['/beratungsmodule/information-zu-gesundheitskosten'], {
                relativeTo: this.route
            });
        }
    }

    prevPage() {
        if (this.showResult === false) {
            this.router.navigate(['/beratungsmodule/personenangaben'], { relativeTo: this.route });
        } else {
            this.showResult = false;
        }
    }

    checkIfChildIsSelected(index: number, row: string): boolean {
        if (row === this.firstRow) {
            this.firstRowArray[index] = !this.firstRowArray[index];
            this.gesundheitsplanerService.gebissMap.set(this.firstRow, this.firstRowArray);
        } else {
            this.gesundheitsplanerService.gebissMap.set(this.firstRow, this.firstRowArray);
        }

        if (row === this.secondRow) {
            this.secondRowArray[index] = !this.secondRowArray[index];
            this.gesundheitsplanerService.gebissMap.set(this.secondRow, this.secondRowArray);
        } else {
            this.gesundheitsplanerService.gebissMap.set(this.secondRow, this.secondRowArray);
        }

        return false;
    }

}

export enum Rows {
    FIRST_ROW = 'firstRow',
    SECOND_ROW = 'secondRow'
}
