import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
    selector: 'app-timetable',
    templateUrl: './timetable.component.html',
    styleUrls: ['./timetable.component.scss']
})
export class TimetableComponent implements OnInit, OnChanges {
    @Input()
    type: 'gesetzlich' | 'privat' = 'gesetzlich';
    hours;

    ngOnInit(): void {
        this.setState();
    }

    ngOnChanges(): void {
        this.setState();
    }

    private setState() {
        this.hours = this.type === 'gesetzlich' ? 40 : 168;
    }
}
