import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BuComponent } from './beratungsmodule/bu/bu.component';
import { DaxComponent } from './beratungsmodule/dax/dax.component';
import { WunschbetragComponent } from './beratungsmodule/wunschbetrag/wunschbetrag.component';
import { SparplanComponent } from './beratungsmodule/sparplan/sparplan.component';
import { CostAverageComponent } from './beratungsmodule/cost-average/cost-average.component';
import { PersoenlicheVermoegenssicherungComponent } from './beratungsmodule/persoenliche-vermoegenssicherung/persoenliche-vermoegenssicherung.component';
import { SparfoerderungComponent } from './beratungsmodule/sparfoerderung/sparfoerderung.component';
import { VersicherteAktivitaetenComponent } from './beratungsmodule/versicherte-aktivitaeten/versicherte-aktivitaeten.component';
import { VersicherteZeitComponent } from './beratungsmodule/versicherte-zeit/versicherte-zeit.component';
import { InvestmentfondsComponent } from './beratungsmodule/investmentfonds/investmentfonds.component';
import { AuthGuard } from './guards/auth.guard';
import { KundenbonusComponent } from './beratungsmodule/kundenbonus/kundenbonus.component';
import {StartseiteComponent} from './startseite/startseite.component';

const routes: Routes = [
    {
        path: '',
        canActivateChild: [AuthGuard],
        children: [
            { path: 'startseite', redirectTo: '', pathMatch: 'full' },
            { path: '', component: StartseiteComponent },
            { path: 'beratungsmodule/bu', component: BuComponent },
            { path: 'beratungsmodule/dax', component: DaxComponent },
            {
                path: 'beratungsmodule/versicherte-aktivitaeten',
                component: VersicherteAktivitaetenComponent
            },
            { path: 'beratungsmodule/wunschbetrag', component: WunschbetragComponent },
            { path: 'beratungsmodule/cost-average', component: CostAverageComponent },
            { path: 'beratungsmodule/sparplan', component: SparplanComponent },
            { path: 'beratungsmodule/sparfoerderung', component: SparfoerderungComponent },
            {
                path: 'beratungsmodule/persoenliche-vermoegenssicherung',
                component: PersoenlicheVermoegenssicherungComponent
            },
            { path: 'beratungsmodule/versicherte-zeit', component: VersicherteZeitComponent },
            {
                path: 'beratungsmodule/funktionsweise-investmentfonds',
                component: InvestmentfondsComponent
            },
            {
                path: 'beratungsmodule/gesundheitsplaner',
                loadChildren: () =>
                    import('./beratungsmodule/gesundheitsplaner/gesundheitsplaner.module').then(m => m.GesundheitsplanerModule)
            },
            {
                path: 'beratungsmodule/kundenbonus',
                component: KundenbonusComponent,
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes,{ useHash: true })],
    exports: [RouterModule]
})
export class AppRoutingModule {}
