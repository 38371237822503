import {
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { CostAverageCalculationsService } from '../cost-average-calculations.service';
import 'chartjs-plugin-datalabels';

@Component({
    selector: 'app-ca-crash',
    templateUrl: './ca-crash.component.html',
    styleUrls: ['./ca-crash.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CaCrashComponent implements OnInit, OnChanges {
    public sliderOpacity = { visibility: 'visible' };
    stockData: number[] = [];
    fullStockData: number[];
    customerGuessStockData: number[] = [];
    actualStockData: number[];
    chartData;
    chartOptions;
    sliderMax: number;
    hideValueBtn = true;
    renditeValue: number;
    showInterface = false;

    @ViewChild('caCrash') caCrash;
    @ViewChild('crashWrapper') crashWrapper: ElementRef;

    @Input()
    isVisible = false;

    constructor(private calculationService: CostAverageCalculationsService) {
    }

    ngOnInit(): void {
        this.init();
        this.sliderMax = Math.max(...this.fullStockData); // * 1.5;
    }

    async ngOnChanges(changes: SimpleChanges) {
        const key = 'isVisible';
        if (changes[key].previousValue === false && changes[key].currentValue === true) {
            this.animateDataPoints();
        }
    }

    private initChart() {
        this.fullStockData = this.calculationService.getCrashScenarioData();
        this.actualStockData = new Array(12).fill(null);

        this.chartOptions = {
            animation: {
                duration: 0
            },
            plugins: {
                datalabels: {
                    align(context) {
                        return context.dataset.data[context.dataIndex] >= 130 ? 110 : -110;
                    },
                    formatter(value) {
                        return value + ' €';
                    },
                    anchor: 'start',
                    clipping: true,
                    offset(context) {
                        if (context.dataIndex === 11) {
                            return context.dataset.data[context.dataIndex] >= 130 ? 26 : 22;
                        } else {
                            return 8;
                        }
                    },
                    borderRadius: 4,
                    backgroundColor(context) {
                        return context.dataIndex === 11 ? '#3b94a3' : '#EBEBEB';
                    },
                    color(context) {
                        return context.dataIndex === 11 ? 'white' : '#4C4C4C';
                    },
                    font: {
                        weight: 'bold'
                    }
                },
                legend: {
                    display: false
                }
            },
            scales: {
                y: {
                    beginAtZero: true,
                    ticks: {
                        suggestedMax: Math.max(...this.fullStockData) * 1.5,
                        stepSize: 25,
                        callback(value) {
                            return value + ' €';
                        }
                    }
                },
                x: {
                    grid: {
                        drawOnChartArea: true
                    }
                }
            },
            elements: {
                point: {
                    radius: 0
                },
                line: {
                    tension: 0,
                    borderWidth: 1
                }
            },
            legend: {
                display: false
            }
        };

        this.chartData = {
            labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', 'VK'],
            datasets: [
                {
                    label: null,
                    data: this.customerGuessStockData,
                    borderColor: '#be1413',
                    fill: false,
                    datalabels: {
                        backgroundColor: '#be1413',
                        display(context) {
                            const index = context.dataIndex;
                            return index === 11 ? 'auto' : false;
                        }
                    }
                },
                {
                    label: '',
                    data: this.stockData,
                    borderColor: '#3B94A3',
                    fill: false
                },
                {
                    label: '',
                    data: this.actualStockData,
                    borderColor: '#3B94A3',
                    fill: false,
                    datalabels: {
                        backgroundColor: '#3b94a3',
                        align(context) {
                            // This is chart-fu for the slider animations :/
                            // We need to adjust the label position while it animates so it doesn´t get drawn out of canvas bounds
                            // context.chart.config.data.datasets[0] = this.customerGuessStockData
                            // context.dataset = this.actualStockData
                            if (context.chart.config.data.datasets[0].data[11] > 130) {
                                if (context.dataset.data[11] <= 35) {
                                    return -110; // negative values put the label above the datapoint
                                } else {
                                    return 110; // positive values put the label below the datapoint
                                }
                            } else {
                                return -110;
                            }
                        },
                        display(context) {
                            const index = context.dataIndex;
                            return index === 11;
                        }
                    }
                }
            ]
        };
    }

    handleSlide() {
        this.caCrash.refresh();
    }

    async init() {
        this.sliderOpacity.visibility = 'visible';
        this.hideValueBtn = true;
        this.initChart();
    }

    private animateDataPoints() {
        const newIndex = this.stockData.length;

        if (newIndex >= this.fullStockData.length) {
            this.activateInterface();
            return;
        }

        // The stockData array (green line) is filled up until index 10
        if (newIndex <= 10) {
            this.stockData.push(this.fullStockData[newIndex]);
        } else {
            this.stockData.push(null);
        }

        // The customerGuessStockData array (red line) is filled up until index 10
        if (newIndex >= 10) {
            this.customerGuessStockData.push(this.fullStockData[newIndex]);
        } else {
            this.customerGuessStockData.push(null);
        }

        this.caCrash.refresh();

        setTimeout(() => this.animateDataPoints(), 300);
    }

    activateInterface() {
        this.showInterface = true;
    }

    calcValue() {
        const calculationStockData = [...this.stockData];
        calculationStockData[11] = this.customerGuessStockData[11];
        this.sliderOpacity.visibility = 'hidden';
        this.renditeValue = this.calculationService.calcRenditeForEstimatedValue(100, calculationStockData, 11);
        this.actualStockData[10] = this.stockData[10];
        this.actualStockData[11] = this.customerGuessStockData[11];
        this.caCrash.refresh();

        this.animateResult();
    }

    /**
     * This function animates the result label in the crash scenario.
     */
    animateResult() {
        const animationTime = 1000; // ms
        const animationDelay = 10; // ms
        const steps = animationTime / animationDelay;
        const breakPoint = 0.79;
        const diff = this.customerGuessStockData[11] - breakPoint;
        let step = diff / steps;

        // Set a min step for the very rare occurence (if user only places the slider marginally apart from result)
        if (step < 0.05 && step > 0) {
            step = 0.05;
        } else if (step > -0.05 && step < 0) {
            step = -0.05;
        }

        this.animateResultStep(step, animationDelay);
    }

    private animateResultStep(stepSize: number, animationDelay: number) {
        if ((stepSize > 0 && this.actualStockData[11] <= 0.79) || (stepSize < 0 && this.actualStockData[11] >= 0.79)) {
            this.actualStockData[11] = 0.79;
            this.hideValueBtn = false;
            this.caCrash.refresh();
            return;
        }

        const val = this.actualStockData[11] - stepSize;

        if (stepSize > 0) {
            this.actualStockData[11] = Math.floor(Number(val.toFixed(2)));
            if (this.actualStockData[11] < 0.79) {
                this.actualStockData[11] = 0.79;
            }
        } else {
            this.actualStockData[11] = Math.ceil(Number(val.toFixed(2)));
            if (this.actualStockData[11] > 0.79) {
                this.actualStockData[11] = 0.79;
            }
        }

        this.caCrash.refresh();

        setTimeout(() => this.animateResultStep(stepSize, animationDelay), animationDelay);
    }
}
