<dx-text class="page__heading" type="h2">Funktionsweise Investmentfonds</dx-text>
<dx-card>
    <dx-card-header label="Funktionsweise Investmentfonds"></dx-card-header>
    <dx-card-content>
        <div class="content">
            <div class="anleger">
                <img src="assets/svg/funktionsweise-investmentfonds/people.svg" alt="Menschen" />
                <div>Anleger</div>
            </div>
            <div class="investment-gesellschaft-fondvermoegen">Investment-Gesellschaft</div>
            <div class="investment-gesellschaft-fondvermoegen">Fondvermögen</div>
            <div class="last-container">
                <div>
                    <img src="assets/svg/funktionsweise-investmentfonds/aktien.svg" alt="Aktien" />
                    <div>Aktien</div>
                </div>
                <div>
                    <img src="assets/svg/funktionsweise-investmentfonds/immobilien.svg" alt="Immobilien" />
                    <div>Immobilien</div>
                </div>
                <div>
                    <img src="assets/svg/funktionsweise-investmentfonds/money.svg" alt="Geld" />
                    <div>Liquidität</div>
                </div>
                <div>
                    <img src="assets/svg/funktionsweise-investmentfonds/wertpapier.svg" alt="Wertpapier" />
                    <div>Festverzinsliche Wertpapiere</div>
                </div>
            </div>
        </div>
    </dx-card-content>
</dx-card>
