<div class="animate-width">
    <div class="grid-container" #gridContainer>
        <div *ngFor="let person of personen; let i = index" [ngClass]="getLineClass(i)" class="people">
            <div (click)="selectPerson(i)">
                <svg
                    version="1.1"
                    id="Ebene_1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 26 66"
                    style="enable-background:new 0 0 26 66;"
                    xml:space="preserve"
                >
                    <g>
                        <g>
                            <path
                                class="fill"
                                [class.selected]="person"
                                [class]="theme"
                                [class.secondary]="!person && !editable"
                                d="M9.5,64.7c-0.9,0-1.6-0.7-1.7-1.6L5.9,40.5H5.2c-1.3,0-2.4-1.1-2.5-2.4L1.5,19.3c-0.1-0.9,0.2-1.7,0.8-2.3
			c0.5-0.5,1.1-0.8,1.7-0.8h18c0.7,0,1.3,0.3,1.7,0.8c0.6,0.6,0.8,1.4,0.8,2.3l-1.2,18.8c-0.1,1.3-1.2,2.4-2.5,2.4H20L18.2,63
			c-0.1,0.9-0.8,1.6-1.7,1.6H9.5z M13,13c-3.2,0-5.8-2.5-5.8-5.7S9.8,1.6,13,1.6c3.2,0,5.8,2.5,5.8,5.7S16.2,13,13,13z"
                            />
                            <path
                                class="outline"
                                [class.selected]="person"
                                [class]="theme"
                                [class.secondary]="!person && !editable"
                                d="M13,2.2c2.9,0,5.2,2.3,5.2,5.2s-2.4,5.2-5.2,5.2s-5.2-2.3-5.2-5.2S10.1,2.2,13,2.2 M22,16.7c0.7,0,1.1,0.3,1.4,0.6
			c0.5,0.5,0.7,1.2,0.6,2l-1.2,18.8c-0.1,1-1,1.9-2,1.9h-0.2h-0.9l-0.1,0.9L17.7,63c0,0.6-0.6,1.2-1.2,1.2h-7
			c-0.6,0-1.2-0.5-1.2-1.2L6.5,40.9L6.4,40H5.5H5.2c-1,0-1.9-0.8-2-1.9L2,19.3c0-0.8,0.2-1.5,0.6-2C2.9,17,3.4,16.7,4,16.7H22
			 M13,1.2c-3.4,0-6.2,2.8-6.2,6.2s2.8,6.2,6.2,6.2s6.2-2.8,6.2-6.2S16.5,1.2,13,1.2L13,1.2z M22,15.7H4c-1.8,0-3.1,1.6-3,3.6
			l1.2,18.8c0.1,1.6,1.4,2.8,3,2.8h0.2l1.8,22.1c0.1,1.2,1.1,2.1,2.2,2.1h7c1.1,0,2.1-0.9,2.2-2.1L20.5,41h0.2c1.5,0,2.9-1.2,3-2.8
			L25,19.3C25.1,17.3,23.8,15.7,22,15.7L22,15.7z"
                            />
                        </g>
                    </g>
                </svg>
            </div>
        </div>
    </div>
</div>
