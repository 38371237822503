import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SliderModule } from 'primeng/slider';
import { PersoenlicheVermoegenssicherungComponent } from './persoenliche-vermoegenssicherung.component';
import { VersicherBarComponent } from './versicher-bar/versicher-bar.component';
import { SharedModule } from '../../shared/shared.module';
import { DesignSystemModule } from '@dvag/design-system-angular';
import { CardFooterComponent } from '../../layout/card-footer/card-footer.component';

@NgModule({
    declarations: [PersoenlicheVermoegenssicherungComponent, VersicherBarComponent],
    imports: [CommonModule, SliderModule, FormsModule, SharedModule, DesignSystemModule, CardFooterComponent]
})
export class PersoenlicheVermoegenssicherungModule {}
