<dx-text class="page__heading" type="h2">Persönliche Vermögenssicherung</dx-text>

<dx-card>
    @switch (step) {
        @default {
            <dx-card-header label="Wie gut sind Sie momentan versichert?"></dx-card-header>
        }
        @case (1) {
            <dx-card-header label="Ihr Versicherungspaket weist Lücken auf ..."></dx-card-header>
        }
        @case (2) {
            <dx-card-header
                label="... mit einer Lösung unseres Premium-Anbieters können Sie diese Lücken schließen!"></dx-card-header>
        }
    }
    <dx-card-content>

        <div [class.hidden]="isDefaultPercentageHidden()" [class.green]="step == 2" class="default-percentage"> 100 %
        </div>
        <div class="insurance-container">
            <div
                *ngFor="let insuranceColumn of pageStructure"
                class="insurance-column"
                [class.hide-inactive-column]="hideInactiveColumn(insuranceColumn)"
            >
                <img
                    class="icon"
                    [class.img-active]="insuranceColumn.enabled"
                    [class.pointer]="step == 0"
                    [src]="'assets/svg/persoenliche-vermoegenssicherung/' + insuranceColumn.image"
                    (click)="selectInsurance(insuranceColumn)"
                    alt="Persönliche Vermögenssicherung"
                />
                <p class="insurance-name" [style]="{ opacity: insuranceColumn.enabled ? 0 : 1 }">
                    {{ insuranceColumn.name }}
                </p>
                <div class="percentage" [class.negative]="isNegative()">
                    <span *ngIf="insuranceColumn.enabled && step !== 2"> {{ percentageFor(insuranceColumn.percentage) }}
                        % </span>
                </div>

                <div class="content" [class.content-visible]="insuranceColumn.enabled">
                    <app-versicher-bar
                        [currentValue]="insuranceColumn.percentage"
                        (updateValue)="update($event, insuranceColumn)"
                        [isInteractive]="isInteractive()"
                        [isPositive]="isPositive()"
                    ></app-versicher-bar>
                    <div class="bottom-divider"></div>
                    <p class="insurance-name" [class.margin]="step == 0">{{ insuranceColumn.name }}</p>
                </div>
            </div>
        </div>
        <app-card-footer [showPrev]="step!==0" [disablePrev]="!showPrevBtn" (prevPage)="prevPage()"
                         [disableNext]="!showNextBtn" [showNext]="step!==2" (nextPage)="nextPage()"></app-card-footer>
    </dx-card-content>
</dx-card>
