import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VersicherteAktivitaetenComponent } from './versicherte-aktivitaeten.component';
import { ChartModule } from 'primeng/chart';
import { SharedModule } from '../../shared/shared.module';
import { DesignSystemModule } from '@dvag/design-system-angular';
import { CardFooterComponent } from '../../layout/card-footer/card-footer.component';

@NgModule({
    declarations: [VersicherteAktivitaetenComponent],
    imports: [CommonModule, ChartModule, SharedModule, DesignSystemModule, CardFooterComponent]
})
export class VersicherteAktivitaetenModule {}
