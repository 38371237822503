<div class="detailsinfo-rechenbeispiel-container" *ngIf="showCorrectPlan(); else vierOderMehr">
    <div class="header">
        <dx-text type="h5">{{ selectedTarif?.name }}</dx-text>
        <dx-button type="primary-s" [label]="buttonLabel" (click)="emitAddAndDeletePlan()"></dx-button>
    </div>

    <dx-tab-bar>
        <dx-tab-page label="Vorteile" value="1">
            <!--Vorteile-->
            <div *ngIf="showVorteile" class="vorteile-inhalt">
                <div *ngIf="selectedTarif.name === 'Plan Stationär'; else schutz">
                    <p *ngFor="let vorteil of selectedTarif.advantages; let i = index"
                       [ngClass]="{ firstOneIsHeader: i % 1 === 0 }">
                        {{ vorteil }}
                    </p>
                    <ul>
                        <li *ngFor="let weiterePunkte of selectedTarif.weitere">
                            {{ weiterePunkte }}
                        </li>
                    </ul>
                </div>
                <ng-template #schutz>
                    <p *ngFor="let inhalt of selectedTarif.advantages; let i = index"
                       [ngClass]="{ firstOneIsHeader: i % 2 === 0 }">
                        {{ inhalt }}
                    </p>
                    <ng-container *ngIf="selectedTarif.name === 'Plan Ambulant'">
                        <p *ngFor="let weiterePunkte of selectedTarif.weitere; let i = index"
                           [ngClass]="{ firstOneIsHeader: i % 2 === 0 }">
                            {{ weiterePunkte }}
                        </p>
                    </ng-container>
                </ng-template>
            </div>
        </dx-tab-page>
        <dx-tab-page label="Rechenbeispiel" value="2">
            <!--Rechenbeispiel-->
            <div class="rechenbeisiel-inhalt">
                <div class="rechenbeispiel-header">
                    <p>
                        {{ selectedTarif.title }}
                    </p>
                    <img src="{{ selectedTarif.img }}" alt="Tarif"/>
                </div>
                <div class="abstand"></div>
                <div class="rechenbeispiel-subheader">
                    <p id="text-koa">Kosten ohne Absicherung</p>
                    <p id="text-kma">Kosten mit Absicherung</p>
                </div>
                <div class="bar-container">
                    <div class="bar">
                        <div class="bar-item sie" id="barSieKoa" [ngStyle]="getKoaStyle(selectedTarif.koasie)">
                            <div class="label">
                                <p>{{ selectedTarif.koasie | number }} €</p>
                            </div>
                        </div>
                        <div class="bar-item erstattung" id="barErstattungKoa"
                             [ngStyle]="getKoaStyle(selectedTarif.koaerstattung)">
                            <div class="label">
                                <p>{{ selectedTarif.koaerstattung | number }} €</p>
                            </div>
                        </div>
                        <div class="bar-item gkv" id="barGkvKoa" [ngStyle]="getKoaStyle(selectedTarif.koagkv)">
                            <div class="label">
                                <p>{{ selectedTarif.koagkv | number }} €</p>
                            </div>
                        </div>
                    </div>
                    <div class="bar">
                        <div id="barSieKma" class="bar-item sie" [ngStyle]="getKmaStyle(selectedTarif.kmasie)">
                            <div class="label">
                                <p>{{ selectedTarif.kmasie | number }} €</p>
                            </div>
                        </div>

                        <div class="bar-item erstattung" id="barErstattungKma"
                             [ngStyle]="getKmaStyle(selectedTarif.kmaerstattung)">
                            <div class="label" *ngIf="selectedTarif.name === 'Plan Stationär'">
                                <div class="zahlen">
                                    <p>Sie zahlen 0,00 €</p>
                                </div>
                            </div>
                            <div class="label">
                                <p>{{ selectedTarif?.kmaerstattung | number }} €</p>
                            </div>
                        </div>
                        <div class="bar-item gkv" id="barGkvKma" [ngStyle]="getKmaStyle(selectedTarif.kmagkv)">
                            <div class="label">
                                <p>{{ selectedTarif.kmagkv | number }} €</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="legend-container"
                    *ngIf="selectedTarif.name === 'Plan Ambulant' || selectedTarif.name === 'Plan Stationär'; else schutz"
                >
                    <div class="square sie"></div>
                    <div class="text sie">
                        <p id="legend-sie">Das zahlen Sie</p>
                    </div>
                    <div class="square erstattung"></div>
                    <div class="text erstattung">
                        <p id="legend-erstattung">{{ selectedTarif.legend }}</p>
                    </div>
                </div>

                <ng-template #schutz>
                    <div class="legend-container">
                        <div class="square sie"></div>
                        <div class="text sie">
                            <p id="legend--schutz-sie">Das zahlen Sie</p>
                        </div>
                        <div class="square erstattung"></div>
                        <div class="text">
                            <p id="legend-schutz-erstattung">{{ selectedTarif.legend }}</p>
                        </div>

                        <div class="square gkv"></div>
                        <div class="text">
                            <p id="legend-gkv">GKV - Festzuschuss</p>
                        </div>
                    </div>
                </ng-template>
            </div>
        </dx-tab-page>
    </dx-tab-bar>
</div>
<ng-template #vierOderMehr>
    <div class="detailsinfo-rechenbeispiel-container">
        <p class="vier-oder-mehr-text">
            Die Produkte "Basisschutz Plus" und "Mein Zahnschutz" sind nur bei weniger als 4 fehlenden bzw.
            wurzelbehandelten Zähnen
            verfügbar.
        </p>
    </div>
</ng-template>
