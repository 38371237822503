<dx-alert actionsdirection="column" visible="{{modalOpenAppVisible}}" id="ds-alert-cart-{{card.image}}">
    <dx-text slot="title" type="h5">Im App-Portal öffnen</dx-text>
    <div slot="body">
        <ds-text aria-label="In App öffnen">
            Dieses Modul gibt es nur in der App "App-Portal". Bitte stellen Sie sicher, dass die App auf Ihrem Gerät
            installiert ist.
        </ds-text>
        <ds-text type="ftf" class="maintained" aria-label="Modul wird nicht gewartet">
            {{ notMaintainedText }}
        </ds-text>
    </div>

    <ds-alert-button label="{{ButtonAction.ABBRECHEN}}" (click)="modalOpenAppVisible = false"></ds-alert-button>
    <ds-alert-button highlight={true} label="{{ButtonAction.OPEN}}" (click)="openApp()"></ds-alert-button>
</dx-alert>

<dx-alert visible="{{modalOpenNoIpad}}" id="ds-alert-ipad-{{card.image}}">
    <ds-text slot="title" type="u5">App nur für das iPad</ds-text>
    <ds-text slot="body">Dieses Modul kann nur auf einem iPad mit dem "App-Portal" geöffnet werden.</ds-text>
    <ds-alert-button label="Ok" (click)="closeModalNoIpad()"></ds-alert-button>
</dx-alert>


<dx-card>
    <dx-card-image *ngIf="card.image" [url]="card.image"></dx-card-image>
    <div *ngIf="!card.image" class="dummy-image"></div>
    <dx-card-content>
        <div class="title">
            <dx-text type="h5">{{ card.title }}</dx-text>
        </div>
        <div *ngIf="!card.deactivated" slot="footer">
            <dx-button icon="link-extern" iconposition="right" stretch="true" type="primary-s" (click)="open()"
                       id="ds-button-open-{{card.image}}"
                       label="{{card.source === FipyCardSource.WEB ? 'Im Web aufrufen' : 'App aufrufen'}}">
            </dx-button>
        </div>
        <div *ngIf="card.deactivated" slot="footer">
            <dx-text type="it" color="error" *ngIf="isNichtMehrVerfuegbar"><b>Nicht mehr verfügbar</b></dx-text>
            <dx-text type="it" *ngIf="isBaldInVPDigital"><b>Bald in VP Digital verfügbar</b></dx-text>
            <dx-text type="it" *ngIf="isVPDigitalBeratungswelt"><b>VP Digital / Beratungswelt</b></dx-text>
        </div>
    </dx-card-content>
</dx-card>
