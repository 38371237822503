import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GesundheitsplanerService } from '../services/gesundheitsplaner.service';

@Component({
    selector: 'app-gebiss-start',
    templateUrl: './zahnverlust.component.html',
    styleUrls: ['./zahnverlust.component.scss']
})
export class ZahnverlustComponent implements OnInit {
    showNextBtn = false;
    showPrevBtn = true;
    showResult = false;
    count = 0;
    arrayTeethId = [
        'li9',
        're9',
        'li10',
        're10',
        'li11',
        're11',
        'li12',
        're12',
        'li13',
        're13',
        'li14',
        're14',
        'li15',
        're15',
        'li16',
        're16',
        'li8',
        're8',
        'li7',
        're7',
        'li6',
        're6',
        'li5',
        're5',
        'li4',
        're4',
        'li3',
        're3',
        'li2',
        're2',
        'li1',
        're1'
    ];
    constructor(private router: Router, private route: ActivatedRoute, private gesundheitsplanerService: GesundheitsplanerService) {}

    ngOnInit(): void {
        if (this.getNumberOfSelectedTeeth() === 0) {
            this.setTeeth();
        }
        const { data } = history.state || {};
        if (data === true) {
            this.showResult = true;
            this.count = 14;
            this.showNextBtn = true;
        } else {
            this.showResult = false;
            this.count = this.getNumberOfSelectedTeeth();
            if (this.count > 0) {
                this.showNextBtn = true;
            }
        }
    }

    nextPage() {
        if (this.showResult === false) {
            this.showResult = true;
            this.count = 14;
        } else {
            this.router.navigate(['/beratungsmodule/information-zu-gesundheitskosten'], { relativeTo: this.route });
        }
    }

    prevPage() {
        if (this.showResult === false) {
            this.router.navigate(['/beratungsmodule/personenangaben'], { relativeTo: this.route });
        } else {
            this.showResult = false;
            this.count = this.getNumberOfSelectedTeeth();
            if (this.gesundheitsplanerService.gebissMap.size === 0) {
                this.setTeeth();
            }
        }
    }

    /**
     * select/deselect teeth and count the selected teeth
     * @param id is string
     */

    selectedTeeth(id: string) {
        const status = this.gesundheitsplanerService.gebissMap.get(id);
        this.gesundheitsplanerService.gebissMap.set(id, !status);
        const arrayTeethMissing = [];
        this.arrayTeethId.forEach((i: string) => {
            if (!this.gesundheitsplanerService.gebissMap.get(i)) {
                arrayTeethMissing.push(i);
            }
        });
        this.count = arrayTeethMissing.length;
        this.showNextBtn = true;
    }

    /**
     *  get tooth state
     * @param id is string
     */
    getToothState(id: string): boolean {
        return this.gesundheitsplanerService.gebissMap.get(id);
    }

    private setTeeth() {
        this.arrayTeethId.forEach(id => {
            this.gesundheitsplanerService.gebissMap.set(id, true);
        });
    }

    private getNumberOfSelectedTeeth(): number {
        const selectedMissingTeeth = [];
        this.gesundheitsplanerService.gebissMap.forEach((value, key) => {
            if (value === false) {
                selectedMissingTeeth.push(key);
            }
        });
        return selectedMissingTeeth.length;
    }
}
