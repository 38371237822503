<div>
    <dx-modal (modalClosed)="closeModal()" [visible]="showModulesModal"
              id="module-liste"
              width="xl"
              [height]="'content'"
              label="{{modalTitle}}">
        <div class="content" slot="content">
            <app-fipy-module-cards [cards]="cards | cardsByCategory: moduleCategory"></app-fipy-module-cards>
        </div>
    </dx-modal>

    <div id="pyramide">
        <div class="row">
            <div class="image-container">
                <img src="assets/png/pyramide/1.png" alt="Anlagen" />
            </div>
        </div>
        <div class="row row-12">
            <div class="image-container">
                <img data-test="image-privater-vermoegensaufbau" (click)="open(1)" src="assets/png/pyramide/2.png" alt="Privater Vermögensaufbau" />
            </div>
        </div>
        <div class="row row-6">
            <div class="image-container">
                <img data-test="image-altervorsorge" (click)="open(2)" src="assets/png/pyramide/3.png" alt="Altervorsorge" />
            </div>
            <div class="image-container">
                <img data-test="image-wohneigentum" (click)="open(3)" src="assets/png/pyramide/4.png" alt="Wohneigentum" />
            </div>
        </div>
        <div class="row row-4">
            <div class="image-container">
                <img data-test="image-vermoegensabsicherung" (click)="open(4)" src="assets/png/pyramide/5.png" alt="Vermögensabsicherung" />
            </div>
            <div class="image-container">
                <img data-test="image-gesundheits-und-risikovorsorge" (click)="open(5)" src="assets/png/pyramide/6.png" alt="Gesundheits- und Risikovorsorge" />
            </div>
            <div class="image-container">
                <img data-test="image-ruecklagen" (click)="open(6)" src="assets/png/pyramide/7.png" alt="Rücklagen" />
            </div>
        </div>
    </div>
</div>
