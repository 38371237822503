import {Component, EventEmitter, Input, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import { Slider } from 'primeng/slider';

@Component({
    selector: 'app-versicher-bar',
    templateUrl: './versicher-bar.component.html',
    styleUrls: ['./versicher-bar.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class VersicherBarComponent {
    isInteractiveMode = true;

    @ViewChild(Slider) public slider: Slider;

    @Input()
    set isInteractive(newValue: boolean) {
        this.isInteractiveMode = newValue;
        this.updateHandle();
    }

    @Input()
    isPositive = true;

    @Input()
    currentValue = 0;

    @Output()
    updateValue: EventEmitter<number> = new EventEmitter();

    update() {
        this.updateValue.emit(this.currentValue);
    }

    getBackgroundColor(): string {
        if (this.isInteractiveMode) {
            return 'white';
        } else if (this.isPositive) {
            return '#6ea318';
        }
        return '#be1413';
    }

    private updateHandle() {
        if (this.slider) {
            this.slider.sliderHandle.nativeElement.hidden = !this.isInteractiveMode;
        }
    }
}
