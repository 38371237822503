import { AfterViewChecked, AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
    selector: 'app-dax-comparison',
    templateUrl: './dax-comparison.component.html',
    styleUrls: ['./dax-comparison.component.scss']
})
export class DaxComparisonComponent implements AfterViewInit, AfterViewChecked {
    @ViewChild('investment') investment: ElementRef;
    @ViewChild('rendite') rendite: ElementRef;

    @Input() label = 'Sparbuch';
    @Input() investmentBetrag = 0;
    @Input() renditeBetrag = 0;
    @Input() maxValue: number;

    ngAfterViewInit() {
        this.investment.nativeElement.style.width = this.getWidth(this.investmentBetrag) + '%';
        this.rendite.nativeElement.style.width = this.getWidth(this.renditeBetrag) + '%';
    }

    ngAfterViewChecked() {
        if (this.investment && this.rendite) {
            this.investment.nativeElement.style.width = this.getWidth(this.investmentBetrag) + '%';
            this.rendite.nativeElement.style.width = this.getWidth(this.renditeBetrag) + '%';
        }
    }

    private getWidth(value: number) {
        return (value / (this.maxValue * 1.05)) * 100;
    }

}
