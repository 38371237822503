import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

declare let window: any;

@Injectable({
    providedIn: 'root'
})
export class AppInitService {
    public init() {
        this.setEnvironment();
    }

    private setEnvironment() {
        const host = window.location.host;
        if (host === 'vb-apps.dvag') {
            environment.environment = 'produktion';
        } else if (host === 'vb-apps.abnahme.dvag') {
            environment.environment = 'abnahme';
        } else if (host === 'localhost:4200') {
            environment.environment = 'lokal';
        }
    }
}
