<div [ngStyle]="{ width: width }" #slider>
    <div class="value-label" *ngIf="label" [ngStyle]="{ left: percentContainerPos }" [class.no-interaction]="!interactionEnabled">
        <span *ngIf="numberFormatterEnabled">{{ currentValue | number: '1.' + fractionDigits + '-' + fractionDigits }} </span>
        <span *ngIf="!numberFormatterEnabled">{{ currentValue }}</span>
        {{ symbol }}
    </div>
    <p-slider
        [class.disabled]="!interactionEnabled"
        [(ngModel)]="currentValue"
        [min]="minValue"
        [max]="maxValue"
        [step]="sliderStep"
        (ngModelChange)="handleSliderChange()"
    >
    </p-slider>
    <p *ngIf="text != ''" class="text">
        {{ text }}
    </p>
</div>
