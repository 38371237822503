export interface KuboItemModel {
    show: boolean;
    canToggle: boolean;
    value: KuboItem;
    alternativeTitle?: string;
    additionalText?: string;
    activated: boolean;
    maxRabatt?: number;
    vspBonus?: number;

    calculatePercent?: number;
}

export enum KuboItem {
    Haftpflicht = 'Haftpflicht',
    Hausrat = 'Hausrat',
    HausratXXL = 'Hausrat Optimal XXL',
    Glas = 'Haushalts-Glas',
    Unfall = 'Unfall',
    Reise = 'Reise',
    Wohngebaeude = 'Wohngebäude',
    KFZ = 'KFZ',
    Rechtsschutz = 'Rechtsschutz',
    Gesundheit = 'Gesundheit',
}
