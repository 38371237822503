import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-cookie-banner',
    templateUrl: './cookie-modal.component.html'
})
export class CookieModalComponent implements OnInit {
    showCookieModal: boolean;

    ngOnInit(): void {
        if (this.isCookieValueSet('cookieconsent', 'allow')) {
            this.showCookieModal = false;
        } else {
            this.showCookieModal = true;
        }
    }

    public isCookieValueSet(cookieName, cookieValue): boolean {
        let foundCookieAndValue = false;
        const name = cookieName + '=';
        const ca = document.cookie.split(';');
        ca.forEach(cookie => {
            if (cookie.trim().indexOf(name) === 0) {
                if (cookie.indexOf(cookieValue) >= 0) {
                    foundCookieAndValue = true;
                }
            }
        });
        return foundCookieAndValue;
    }

    public setCookie(cname, cvalue, exdays) {
        const d = new Date();
        d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
        const expires = 'expires=' + d.toUTCString();
        document.cookie = cname + '=' + cvalue + ';' + expires;
    }

    public acceptCookie() {
        this.setCookie('cookieconsent', 'allow', 365);
        this.showCookieModal = false;
    }
}
