import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SliderComponent } from './components/slider/slider.component';
import { SliderModule } from 'primeng/slider';
import { FormsModule } from '@angular/forms';
import { CookieModalComponent } from './components/cookie-modal/cookie-modal.component';
import { DesignSystemModule } from '@dvag/design-system-angular';

@NgModule({
    declarations: [SliderComponent, CookieModalComponent],
    exports: [SliderComponent, CookieModalComponent],
    imports: [CommonModule, SliderModule, FormsModule, DesignSystemModule]
})
export class SharedModule {}
