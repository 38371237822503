import { ChangeDetectorRef, Component, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { BuSelectPeopleComponent } from './bu-select-people/bu-select-people.component';

@Component({
    selector: 'app-bu',
    templateUrl: './bu.component.html',
    styleUrls: ['./bu.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class BuComponent {
    @ViewChild('peopleContainer') public peopleContainer: BuSelectPeopleComponent;

    @ViewChildren(BuSelectPeopleComponent) public peopleComps: QueryList<BuSelectPeopleComponent>;
    numberOfSelectedPersons = 0;
    numberOfSelectedPersonsSecondPage = 0;
    enable = false;
    isModalVisible = false;


    public slideIndex = 0;

    constructor(private changeDetectorRef: ChangeDetectorRef) {

    }

    public prevPage() {
        this.numberOfSelectedPersonsSecondPage = this.numberOfSelectedPersons = 0;
        this.slideIndex--;
    }

    public nextPage() {
        this.slideIndex++;
    }

    getNumberOfSelectedPersons(numberSelectedPersons: number) {
        this.numberOfSelectedPersons = numberSelectedPersons;
    }

    getNumberOfSelectedPersonsSecondPage(numberSelectedPersons: number) {
        this.numberOfSelectedPersonsSecondPage = numberSelectedPersons;
    }

    openModal() {
        this.isModalVisible = true;
    }

    close() {
        this.isModalVisible = false;
    }

    disableNext() {
        return this.slideIndex === 0 && this.numberOfSelectedPersons < 1 || this.slideIndex === 2 && this.numberOfSelectedPersonsSecondPage < 1;
    }

}
