import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { RequestFormData } from '../../../backend-models/requestFormData';
import { Gesundheitsdaten } from '../gesundheitsdaten.model';
import { KassenzettelResponse } from '../../../backend-models/KassenzettelResponse';
import { firstValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class KassenzettelService {
    daten: KassenzettelResponse;

    constructor(private httpClient: HttpClient) {
    }

    calculate(gesundheitsdaten: Gesundheitsdaten): Promise<KassenzettelResponse> {
        const requestData = this.mapPersonToRequestData(gesundheitsdaten);
        return firstValueFrom(this.httpClient
            .put(`${environment.backend.baseUrl}/gesundheitsplanrechner`, requestData))
            .then(response => (this.daten = response));
    }

    private mapPersonToRequestData(gesundheitsdaten: Gesundheitsdaten): RequestFormData {
        return {
            aktuelleKrankenkasse: gesundheitsdaten.aktuelleKrankenkasse,
            anzahlWurzelBehandelteZaehne: gesundheitsdaten.anzahlWurzelBehandelteZaehne || 0,
            anrede: gesundheitsdaten.anrede,
            anzahlFehlendeZaehne: gesundheitsdaten.anzahlFehlendeZaehne || 0,
            basisPlan: gesundheitsdaten.basisPlan || 'PLAN1',
            bonusVerdoppeln: gesundheitsdaten.bonusVerdoppeln || false,
            ektz: gesundheitsdaten.hatektz || false,
            ktbu: gesundheitsdaten.hatktbu || false,
            geburtsDatum: gesundheitsdaten.geburtsDatum.toISOString().split('T')[0],
            gewuenschterTagesSatz: gesundheitsdaten.gewuenschterTagesSatz || 0,
            hatKeinEigenesEinkommen: gesundheitsdaten.hatKeinEigenesEinkommen || false,
            hatGesundheitsBewusstesVerhaltenFitBonus: gesundheitsdaten.hatGesundheitsBewusstesVerhaltenFitBonus || false,
            hatKinderUnter16Jahren: gesundheitsdaten.hatKinderUnter16Jahren || false,
            hatPflichtAktivitaetenFitBonus: gesundheitsdaten.hatPflichtAktivitaetenFitBonus || false,
            hatPlanAmbulant: gesundheitsdaten.hatPlanAmbulant || false,
            hatPlanStationaer: gesundheitsdaten.hatPlanStationaer || false,
            hatSicherheitsPlan: gesundheitsdaten.hatSicherheitsPlan || false,
            hatVaspVorteil: gesundheitsdaten.hatVaspVorteil || false,
            hatWahlAktivitaetenKinderFitBonus: gesundheitsdaten.hatWahlAktivitaetenKinderFitBonus || false,
            hatWahlAktivitaetenSchwangerenFitBonus: gesundheitsdaten.hatWahlAktivitaetenSchwangerenFitBonus || false,
            istInAusbildung: gesundheitsdaten.istInAusbildung || false,
            istSehHilfeVorhanden: gesundheitsdaten.istSehHilfeVorhanden || false,
            mitVspNachlass: gesundheitsdaten.mitVspNachlass || false,
            monatlichesBruttoEinkommen: gesundheitsdaten.monatlichesBruttoEinkommen,
            monatlichesNettoEinkommen: gesundheitsdaten.monatlichesNettoEinkommen,
            sponsorGesucht: gesundheitsdaten.sponsorGesucht || false,
            // Gesetzliche Vorsorge
            jugenduntersuchung: gesundheitsdaten.jugenduntersuchung || false,
            impfungen: gesundheitsdaten.impfungen || false,
            checkup35: gesundheitsdaten.checkup35 || false,
            zahnvorsorge: gesundheitsdaten.zahnvorsorge || false,
            // Gesund Leben
            vitalitysilber: gesundheitsdaten.vitalitysilber || false,
            vitalitygold: gesundheitsdaten.vitalitygold || false,
            vitalityplatin: gesundheitsdaten.vitalityplatin || false,
            nichtraucher: gesundheitsdaten.nichtraucher || false,
            sportaktivitaten: gesundheitsdaten.sportaktivitaten || false,
            bmi: gesundheitsdaten.bmi || false,
            betrieblicheGesundheitsforderung: gesundheitsdaten.betrieblichegesundheitsforderung || false,
            pravention: gesundheitsdaten.pravention || false,
            // Private Vorsorge
            zusatzversicherung: gesundheitsdaten.zusatzversicherung || false,
            eigenfinanzierteVorsorge: gesundheitsdaten.eigenfinanziertevorsorge || false
        } as RequestFormData;
    }
}
