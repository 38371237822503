import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GesundheitsplanerService } from '../services/gesundheitsplaner.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-information-zu-gesundheitskosten',
    templateUrl: './information-zu-gesundheitskosten.component.html',
    styleUrls: ['./information-zu-gesundheitskosten.component.scss']
})
export class InformationZuGesundheitskostenComponent implements OnInit {
    showNextBtn = true;
    showPrevBtn = true;
    selectedPerson$: Subscription;
    clientAge: number;
    private currentState: boolean;

    constructor(private router: Router, private route: ActivatedRoute, private gesundheitsplanerService: GesundheitsplanerService) {
        this.currentState = true;
    }

    ngOnInit() {
        this.clientAge = this.gesundheitsplanerService.gesundheitsdaten.alter;
    }

    nextPage() {
        this.router.navigate(['/beratungsmodule/plaene'], { relativeTo: this.route });
    }

    prevPage() {
        if (this.clientAge >= 15) {
            this.router.navigate(['/beratungsmodule/zahnverlust'], { relativeTo: this.route, state: { data: this.currentState } });
        } else if (this.clientAge < 15) {
            this.router.navigate(['/beratungsmodule/kinderauswahl'], { relativeTo: this.route, state: { data: this.currentState } });
        }
    }
}
