import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StartseiteComponent } from './startseite.component';
import { FipyAppContentComponent } from './fipy-app-content/fipy-app-content.component';
import { FipyStartPageComponent } from './fipy-start-page/fipy-start-page.component';
import { FipyModulesListComponent } from './fipy-modules-list/fipy-modules-list.component';
import { FipyModulesPyramidComponent } from './fipy-modules-pyramid/fipy-modules-pyramid.component';
import { FipyModuleCardsComponent } from './fipy-module-cards/fipy-module-cards.component';
import { FipyModuleCardComponent } from './fipy-module-card/fipy-module-card.component';
import { CardsByCategoryPipe } from './pipe/cards-by-category.pipe';
import { DesignSystemModule } from '@dvag/design-system-angular';
import { FipyAppFooterComponent } from '../layout/fipy-app-footer/fipy-app-footer.component';
import { FipyAppHeaderComponent } from '../layout/fipy-app-header/fipy-app-header.component';
import { FipyStartPageMobileComponent } from './fipy-start-page-mobile/fipy-start-page-mobile.component';

@NgModule({
    declarations: [StartseiteComponent, FipyAppContentComponent, FipyStartPageComponent, FipyModulesListComponent, FipyModulesPyramidComponent, FipyModuleCardsComponent, FipyModuleCardComponent, CardsByCategoryPipe, FipyAppFooterComponent, FipyAppHeaderComponent, FipyStartPageMobileComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    exports: [
        FipyAppFooterComponent,
        FipyAppHeaderComponent
    ],
    imports: [
        CommonModule,
        DesignSystemModule
    ]
})
export class StartseiteModule {
}

