import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { CaCrashComponent } from './ca-crash/ca-crash.component';
import { CostAverageCalculationsService } from './cost-average-calculations.service';
import { animate, state, style, transition, trigger } from '@angular/animations';

const animationTime = '0.3s';

@Component({
    selector: 'app-cost-average',
    animations: [
        trigger('returnRateAnim', [
            state(
                'hide',
                style({
                    opacity: 0
                })
            ),
            state(
                'show',
                style({
                    opacity: 1
                })
            ),
            transition('hide => show', [animate(animationTime)]),
            transition('show => hide', [animate(animationTime)])
        ]),
        trigger('sparplanAnim', [
            state(
                'hide',
                style({
                    opacity: 0
                })
            ),
            state(
                'show',
                style({
                    opacity: 1
                })
            ),
            transition('hide => show', [animate(animationTime)]),
            transition('show => hide', [animate(animationTime)])
        ]),
        trigger('sliderAnim', [
            state(
                'shrink',
                style({
                    width: '0%'
                })
            ),
            state(
                'grow',
                style({
                    width: '100%'
                })
            ),
            transition('shrink => grow', [animate(animationTime)]),
            transition('grow => shrink', [animate(animationTime)])
        ])
    ],
    templateUrl: './cost-average.component.html',
    styleUrls: ['./cost-average.component.scss']
})
export class CostAverageComponent implements OnInit, OnDestroy, AfterViewInit {
    step = 0;

    returnRate = 0;
    showReturnRate = false;
    isNextButtonVisible = false;
    isBackButtonVisible = false;
    showSparplan = false;
    isQuestionVisible = true;
    displayModal = false;
    displayCrash = false;
    isToSee = false;
    images: string[];
    actualReturnRate$: Subscription;

    selectedindex = 0;

    zinsSliderCurrent = 0;
    renditeSliderCurrent = 0;

    sparplanVisible = false;

    @ViewChild('renditeBar', { static: false }) renditeBar: ElementRef;
    @ViewChild(CaCrashComponent) caCrash: CaCrashComponent;
    @ViewChild('modalsky', { static: false }) modalsky: ElementRef<HTMLDxModalElement>;
    constructor(public calculationService: CostAverageCalculationsService, private changeDetector: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.initImage();
    }

    ngOnDestroy() {
        if (this.actualReturnRate$) {
            this.actualReturnRate$.unsubscribe();
        }
    }

    ngAfterViewInit(): void {
        this.changeDetector.detectChanges();
    }

    initImage() {
        this.images = [
            'assets/png/cost-average/1.png',
            'assets/png/cost-average/2.png',
            'assets/png/cost-average/3.png',
            'assets/png/cost-average/4.png',
            'assets/png/cost-average/5.png',
            'assets/png/cost-average/6.png',
            'assets/png/cost-average/7.png',
            'assets/png/cost-average/8.png',
            'assets/png/cost-average/9.png',
            'assets/png/cost-average/10.png',
            'assets/png/cost-average/11.png',
            'assets/png/cost-average/12.png',
            'assets/png/cost-average/13.png',
            'assets/png/cost-average/14.png',
            'assets/png/cost-average/15.png',
            'assets/png/cost-average/16.png',
            'assets/png/cost-average/17.png',
        ];
    }

    showRenditeSlider() {
        this.showReturnRate = this.zinsSliderCurrent > 0;
    }

    showButton() {
        this.isNextButtonVisible = this.renditeSliderCurrent > 0;
    }

    public determineZinsSliderState() {
        if (!this.showSparplan) {
            return 'p-slider-zins-override';
        } else {
            return 'p-slider-zins-hide-override';
        }
    }

    public determineRenditeSliderState() {
        if (!this.showSparplan) {
            return 'p-slider-rendite-override';
        } else {
            return 'p-slider-rendite-hide-override';
        }
    }

    goBack() {
        this.returnRate = 0;
        this.showSparplan = false;
        this.isToSee = false;
        this.showReturnRate = false;
        this.sparplanVisible = false;
        this.isNextButtonVisible = false;
        this.isQuestionVisible = true;
        this.isBackButtonVisible = false;
        this.zinsSliderCurrent = 0;
        this.renditeSliderCurrent = 0;
        this.changeDetector.detectChanges();
    }

    openExample() {
        this.displayModal = true;
    }

    openCrashSzenario() {
        this.displayCrash = true;
        const crashSzenarioModal = this.modalsky as any;
        crashSzenarioModal.el.shadowRoot.querySelector('.modal-element').style.width = '80vw';
    }

    prevImage() {
        if (this.selectedindex > 0) {
            this.selectedindex -= 1;
        }
    }

    nextImage() {
        if (this.selectedindex < this.images.length - 1) {
            this.selectedindex += 1;
        }
    }

    handleInterestChange(value: number) {
        this.zinsSliderCurrent = value;
        this.showRenditeSlider();
    }

    handleRenditeChange(currentValue: number) {
        this.returnRate = currentValue;
        this.showButton();
    }
}
