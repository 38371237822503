// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const env = {
    environment: 'entwicklung',
    production: false,
    login: {
        ssoLogin: '/vbPortalWebApp/jsp/public/externalLogon/ssoAuth.jsp',
        cbUser: '/vbPortalWebApp/jsp/public/beipackzettel/cb.jsp'
    },

    isProd(): boolean {
        return this.environment === 'produktion';
    },

    getLoginUrl() {
        return `${this.getMeineDvagHost()}${this.login.ssoLogin}`;
    },

    getUserDataUrl() {
        return `${this.getMeineDvagHost()}${this.login.cbUser}`;
    },

    getMeineDvagHost() {
        return 'https://abnahme.meine.dvag';
    },

    keycloak: {
        enabled: true,
        realm: 'zob',
        clientId: 'app-web-vb-apps',
        baseUrl: 'https://entwicklung.auth.dvag/auth'
    },
    backend: {
        // set in app.module.ts
        baseUrl: 'https://vb-apps.entwicklung.dvag/rest'
    },
    bkk: {
        apiKeyName: 'X-apiomat-apikey',
        apiKeyValue: '8983871111234538937',
        apiSystemName: 'X-apiomat-system',
        apiSystemValue: 'LIVE',
        baseUrl: 'https://apiomatbm.bkk-linde.de/',
        apiVersion: '1.0.0'
    },
    appInsights: {
        connectionString: 'InstrumentationKey=da6d4c1d-18d7-46a7-8cd0-c10fe3001713;IngestionEndpoint=https://westeurope-1.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/;ApplicationId=b110a7b6-8c3c-4e41-b954-1550fc49e3cc'
    }
};

export const environment = env;

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error'; // Included with Angular CLI.
