import { Component, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { KassenzettelService } from '../kassenzettel.service';
import { FitbonusModel, FitBonusPlusModel, PersonModel, FitbonusService } from '../fitbonus.service';
import { GesundheitsplanerService } from '../../services/gesundheitsplaner.service';
import { Gesundheitsdaten } from '../../gesundheitsdaten.model';
import { KassenzettelResponse } from '../../../../backend-models/KassenzettelResponse';

@Component({
    selector: 'app-kassenzettel-auswahl',
    templateUrl: './kassenzettel-auswahl.component.html',
    styleUrls: ['./kassenzettel-auswahl.component.scss']
})
export class KassenzettelAuswahlComponent implements OnInit {
    gesundheitsdaten: Gesundheitsdaten;
    kassenzetteldaten: KassenzettelResponse;

    istFrau: boolean;
    tarif: 'KTBU' | 'EKTZ';

    basisplanList: SelectItem[] = [];
    fitbonus: FitbonusModel;
    vitalityList: SelectItem[] = [];
    fitbonusplus: FitBonusPlusModel = {
        vitalitysilber: false,
        vitalitygold: false,
        vitalityplatin: false,
        nichtraucher: false,
        sportaktivitaten: false,
        bmi: false,
        jugenduntersuchung: false,
        zusatzversicherung: false,
        impfungen: false,
        checkup35: false,
        zahnvorsorge: false,
        betrieblichegesundheitsforderung: false,
        eigenfinanziertevorsorge: false,
        pravention: false,
        sportclub: false,
        vitalityStatus: 'KEIN MITGLIED',

        reset() {
            for (const key in this) {
                if (key !== 'reset' && key !== 'vitalityStatus') {
                    this[key] = false;
                }
            }
        }
    };
    person: PersonModel;
    categories: {};
    refounds: {};
    allowedActivitiesRefundDividedBy12 = 0;
    optionalActivitiesRefundTotal = 0;

    showFitbonus = false;
    showEKTZ = true;
    showKTBU = false;
    showModal = false;

    constructor(
        private fitbonusService: FitbonusService,
        private gesundheitsplanerService: GesundheitsplanerService,
        private kassenzettel: KassenzettelService
    ) {
    }

    openInfoOverlay() {
        this.showModal = true;
    }

    bonusVerdoppelnChanged() {
        this.gesundheitsdaten.bonusVerdoppeln = !this.gesundheitsdaten.bonusVerdoppeln;
        this.calculateKassenzettel();
    }

    sponsorGesuchtChanged() {
        this.gesundheitsdaten.sponsorGesucht = !this.gesundheitsdaten.sponsorGesucht;
        if (!this.gesundheitsdaten.sponsorGesucht) {
            this.showFitbonus = false;
            this.fitbonus.reset();
            this.fitbonusplus.reset();
            this.fitbonusplus.vitalityStatus = 'KEIN MITGLIED';
            this.fitBonusChanged();
            this.gesundheitsdaten.bonusVerdoppeln = false;
            this.allowedActivitiesRefundDividedBy12 = 0;
            this.optionalActivitiesRefundTotal = 0;
            this.setAllowedActivitiesRefund();
        } else {
            this.getFitBonusPlus({ ...this.person, active: this.fitbonusplus.sportaktivitaten });
        }
    }

    basisplanChanged(value: any) {
        this.gesundheitsdaten.basisPlan = value;
        this.calculateKassenzettel();
    }

    ambulantChanged() {
        this.gesundheitsdaten.hatPlanAmbulant = !this.gesundheitsdaten.hatPlanAmbulant;
        this.calculateKassenzettel();
    }

    stationaerChanged() {
        this.gesundheitsdaten.hatPlanStationaer = !this.gesundheitsdaten.hatPlanStationaer;
        this.calculateKassenzettel();
    }

    ektzChanged() {
        this.gesundheitsdaten.hatektz = !this.gesundheitsdaten.hatektz;
        this.calculateKassenzettel();
    }

    ktbuChanged() {
        this.gesundheitsdaten.hatktbu = !this.gesundheitsdaten.hatktbu;
        this.calculateKassenzettel();
    }

    vitalityStatusChanged(status) {
        this.fitbonusplus.reset();
        this.optionalActivitiesRefundTotal = 0;
        this.getFitBonusPlus({ ...this.person, vitality: status.toLowerCase() });
    }

    sportClubChanged(val: boolean) {
        this.fitbonusplus.sportaktivitaten = val;
        this.optionalActivitiesRefundTotal = 0;
        this.getFitBonusPlus({ ...this.person, active: this.fitbonusplus.sportaktivitaten });
    }

    fitBonusChanged(event = null, name = null) {
        this.gesundheitsdaten.vitalitysilber = this.fitbonusplus.vitalitysilber;
        this.gesundheitsdaten.vitalitygold = this.fitbonusplus.vitalitygold;
        this.gesundheitsdaten.vitalityplatin = this.fitbonusplus.vitalityplatin;
        this.gesundheitsdaten.nichtraucher = this.fitbonusplus.nichtraucher;
        this.gesundheitsdaten.sportaktivitaten = this.fitbonusplus.sportaktivitaten;
        this.gesundheitsdaten.bmi = this.fitbonusplus.bmi;
        this.gesundheitsdaten.impfungen = this.fitbonusplus.impfungen;
        this.gesundheitsdaten.checkup35 = this.fitbonusplus.checkup35;
        this.gesundheitsdaten.zahnvorsorge = this.fitbonusplus.zahnvorsorge;
        this.gesundheitsdaten.betrieblichegesundheitsforderung = this.fitbonusplus.betrieblichegesundheitsforderung;
        this.gesundheitsdaten.eigenfinanziertevorsorge = this.fitbonusplus.eigenfinanziertevorsorge;
        this.gesundheitsdaten.pravention = this.fitbonusplus.pravention;
        this.gesundheitsdaten.zusatzversicherung = this.fitbonusplus.zusatzversicherung;
        this.calculateKassenzettel(event?.target.checked, name);
        this.setOptionalActivitiesRefund();
    }

    private setAllowedActivitiesRefund() {
        return localStorage.setItem('allowedActivitiesRefundDividedBy12', this.allowedActivitiesRefundDividedBy12.toString());
    }

    private setOptionalActivitiesRefund() {
        return localStorage.setItem('optionalActivitiesRefundDividedBy12', (this.optionalActivitiesRefundTotal).toString());
    }

    private fillBasisPlanDropDown() {
        if (this.basisplanList.length > 0) {
            return;
        }
        if (this.gesundheitsdaten.alter > 15) {
            if (this.gesundheitsdaten.istInAusbildung === true) {
                this.basisplanList = [
                    { label: 'Basisschutz Plus (APlan1)', value: 'PLAN1' },
                    { label: 'Basisschutz Plus (APlan2)', value: 'PLAN2' },
                    { label: 'Basisschutz Plus (APlan3)', value: 'PLAN3' },
                    { label: 'Mein Zahnschutz (APlanZ1)', value: 'PLANZ1' },
                    { label: 'Mein Zahnschutz (APlanZ2)', value: 'PLANZ2' },
                    { label: 'Mein Zahnschutz (APlanZ3)', value: 'PLANZ3' },
                    { label: 'Keine Auswahl', value: 'EMPTY' }
                ];
            } else if (this.gesundheitsdaten.mitVspNachlass === true) {
                this.basisplanList = [
                    { label: 'Basisschutz Plus (PlanV1)', value: 'PLAN1' },
                    { label: 'Basisschutz Plus (PlanV2)', value: 'PLAN2' },
                    { label: 'Basisschutz Plus (PlanV3)', value: 'PLAN3' },
                    { label: 'Mein Zahnschutz (PlanZ1)', value: 'PLANZ1' },
                    { label: 'Mein Zahnschutz (PlanZ2)', value: 'PLANZ2' },
                    { label: 'Mein Zahnschutz (PlanZ3)', value: 'PLANZ3' },
                    { label: 'Keine Auswahl', value: 'EMPTY' }
                ];
            } else {
                this.basisplanList = [
                    { label: 'Basisschutz Plus (Plan1)', value: 'PLAN1' },
                    { label: 'Basisschutz Plus (Plan2)', value: 'PLAN2' },
                    { label: 'Basisschutz Plus (Plan3)', value: 'PLAN3' },
                    { label: 'Mein Zahnschutz (PlanZ1)', value: 'PLANZ1' },
                    { label: 'Mein Zahnschutz (PlanZ2)', value: 'PLANZ2' },
                    { label: 'Mein Zahnschutz (PlanZ3)', value: 'PLANZ3' },
                    { label: 'Keine Auswahl', value: 'EMPTY' }
                ];
            }
            if (this.gesundheitsdaten.mitVspNachlass === true && this.gesundheitsdaten.istInAusbildung === true) {
                this.basisplanList = [
                    { label: 'Basisschutz Plus (APlanV1)', value: 'PLAN1' },
                    { label: 'Basisschutz Plus (APlanV2)', value: 'PLAN2' },
                    { label: 'Basisschutz Plus (APlanV3)', value: 'PLAN3' },
                    { label: 'Mein Zahnschutz (APlanZ1)', value: 'PLANZ1' },
                    { label: 'Mein Zahnschutz (APlanZ2)', value: 'PLANZ2' },
                    { label: 'Mein Zahnschutz (APlanZ3)', value: 'PLANZ3' },
                    { label: 'Keine Auswahl', value: 'EMPTY' }
                ];
            }
            this.showEKTZ = this.tarif === 'EKTZ';
            this.showKTBU = this.tarif === 'KTBU';
        } else {
            this.basisplanList = [
                { label: 'Plan Junior', value: 'PLANJ' },
                { label: 'Keine Auswahl', value: 'EMPTY' }
            ];
            this.showEKTZ = false;
        }
    }

    private setDefaultValues() {
        if (this.gesundheitsdaten.hatVaspVorteil && this.gesundheitsdaten.berufsunfaehigkeitsRente) {
            if (this.gesundheitsdaten.ktbu === undefined) {
                this.gesundheitsdaten.ktbu = true;
                this.gesundheitsdaten.ektz = undefined;
            }
        } else {
            if (this.gesundheitsdaten.ektz === undefined) {
                this.gesundheitsdaten.ektz = true;
                this.gesundheitsdaten.ktbu = undefined;
            }
        }
        this.istFrau = this.gesundheitsdaten.anrede.toLowerCase() === 'frau';
        this.person = {
            age: this.gesundheitsdaten.alter.toString(),
            gender: this.getGender(this.gesundheitsdaten.anrede)
        };
        this.fitbonus = this.fitbonusService.getFitbonus();
    }

    // interesting
    private getGender(salutation) {
        switch (salutation.toLowerCase()) {
            case 'frau':
                return 'male';
            case 'herr':
                return 'female';
            default:
                return;
        }
    }

    private fillVitalityDropDown() {
        if (this.vitalityList.length > 0) {
            return;
        }
        if (this.gesundheitsdaten.alter >= 18) {
            this.vitalityList = [
                { label: 'Kein Mitglied', value: 'KEIN MITGLIED' },
                { label: 'Silber', value: 'SILBER' },
                { label: 'Gold', value: 'GOLD' },
                { label: 'Platin', value: 'PLATIN' }
            ];
        }
    }

    private getFitBonusPlus(person) {
        if (this.gesundheitsdaten.alter < 18) {
            person = { ...this.person, active: true };
        }
        this.categories = {};
        this.refounds = {};
        return this.fitbonusService.getFitBonusPlus(person)
            .then((plans) => {
                this.allowedActivitiesRefundDividedBy12 = plans.estimatedRefund[0].allowedActivitiesRefundDividedBy12;
                plans.allowedActivites.forEach(elem => {
                    const el = { ...elem, isSelected: true, isDisabled: true };
                    if (this.categories[el.category]) {
                        this.categories[el.category].push(el);
                    } else {
                        this.categories[el.category] = [el];
                    }
                });
                plans.optionalActivites.forEach(elem => {
                    const el = { ...elem, isSelected: false, isDisabled: false };
                    this.refounds[el.name] = el.refund / 12;
                    if (this.categories[el.category]) {
                        this.categories[el.category].push(el);
                    } else {
                        this.categories[el.category] = [el];
                    }
                });
                Object.keys(this.categories).forEach(key => {
                    this.categories[key].forEach(activity => {
                        const formattedName = this.formatName(activity.name);
                        this.fitbonusplus[formattedName] = activity.isSelected;
                        this.gesundheitsdaten[formattedName] = activity.isSelected;
                    });
                });
            })
            .then(() => this.fitBonusChanged())
            .then(() => this.setAllowedActivitiesRefund());
    }


    public formatName(name) {
        return name.toLowerCase().replace(/[-\s]/g, '').normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    }

    private calculateKassenzettel(checked = null, name = null) {
        if (checked !== null && name !== null) {
            if (checked) {
                this.optionalActivitiesRefundTotal += (this.refounds[name] || 0);
            } else {
                this.optionalActivitiesRefundTotal -= (this.refounds[name] || 0);
            }
        }
        this.kassenzettel
            .calculate(this.gesundheitsdaten)
            .then(daten => {
                this.kassenzetteldaten = daten;
            })
            .catch(e => console.error(e));
    }

    private getTarif(): 'KTBU' | 'EKTZ' {
        return this.gesundheitsdaten.hatVaspVorteil && this.gesundheitsdaten.berufsunfaehigkeitsRente ? 'KTBU' : 'EKTZ';
    }

    private removeOptionalActivitiesRefund() {
        return localStorage.removeItem('optionalActivitiesRefundDividedBy12');
    }

    private removeAllowedActivitiesRefund() {
        return localStorage.removeItem('allowedActivitiesRefundDividedBy12');
    }

    ngOnInit(): void {
        this.removeAllowedActivitiesRefund();
        this.removeOptionalActivitiesRefund();
        this.gesundheitsdaten = this.gesundheitsplanerService.gesundheitsdaten;
        this.tarif = this.getTarif();
        this.fillBasisPlanDropDown();
        this.fillVitalityDropDown();
        this.setDefaultValues(); // needs to be after fillBasisPlanDropDown()
        this.calculateKassenzettel();
        if (this.gesundheitsdaten.sponsorGesucht) {
            this.getFitBonusPlus({ ...this.person, active: this.fitbonusplus.sportaktivitaten });
        }
    }
}
