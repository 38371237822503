<dx-grid mq1="12/*" mq3="6-6/12">
    <dx-number-input
        label="Wie viel möchten Sie monatlich sparen?"
        [min]="0"
        [limitlength]="7"
        (valueChange)="setMonthlySaving($event.detail)"
        [precision]="0"
        profile="currency-euro"
    ></dx-number-input>
    <dx-number-input
        label="Wie viel möchten Sie einmalig anlegen?"
        [min]="0"
        [limitlength]="7"
        (valueChange)="setOneTimeSaving($event.detail)"
        [precision]="0"
        profile="currency-euro"
    ></dx-number-input>
    <dx-slider
        [min]="sliderMin"
        [max]="sliderMax"
        [value]="0"
        [label]="'Sparbuchzins'"
        [step]="0.1"
        [knoblabel]="'$1 %'"
        (valueChange)="setInterest($event.detail)"
    ></dx-slider>
</dx-grid>
