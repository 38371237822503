import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WunschbetragComponent } from './wunschbetrag.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SliderModule } from 'primeng/slider';
import { CardModule } from 'primeng/card';
import { BrowserModule } from '@angular/platform-browser';
import { SharedModule } from '../../shared/shared.module';
import { DesignSystemModule } from '@dvag/design-system-angular';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SliderModule,
        CardModule,
        BrowserModule,
        SharedModule,
        DesignSystemModule
    ],
    declarations: [WunschbetragComponent]
})
export class WunschbetragModule {}
