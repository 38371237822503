<div class="crash-content">
    <div class="crash-wrapper" #crashWrapper>
        <p-chart #caCrash type="line" [data]="chartData" [options]="chartOptions" width="60vw" height="40vh"></p-chart>

        <div class="slider-wrapper" *ngIf="showInterface">
            <p-slider
                [ngStyle]="{ visibility: sliderOpacity.visibility }"
                id="slider-1"
                class="crash-slider"
                [style]="{ height: '100%' }"
                orientation="vertical"
                [(ngModel)]="customerGuessStockData[11]"
                [min]="0"
                [max]="sliderMax"
                (onChange)="handleSlide()"
            >
            </p-slider>
        </div>
    </div>

    <div class="text-wrapper" *ngIf="showInterface">
        <div *ngIf="hideValueBtn">
           <dx-text type="ps">Wie hoch muss der Kurs steigen, damit die Summe der Einzahlungen dem Verkaufserlös entspricht? </dx-text>
            <dx-spacer size="32v"></dx-spacer>
            <dx-text type="ps">Ziehen Sie die Markierung auf den entsprechenden Wert.</dx-text>
            <dx-button type="primary-m" class="calc-btn" (click)="calcValue()" label="Wert berechnen">
            </dx-button>
        </div>

        <div *ngIf="!hideValueBtn">
            <dx-text type="ps">Rendite, die bei diesem Kurs erzielt werden würde: {{ renditeValue | number: '1.0-0':'de-DE' }} %</dx-text>
            <dx-spacer size="32v"></dx-spacer>
            <dx-text type="ps">Kurswert, der erreicht werden muss: 0,79 €</dx-text>
        </div>
    </div>
</div>
