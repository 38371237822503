import { BrowserModule } from '@angular/platform-browser';
import {
    APP_INITIALIZER,
    ApplicationRef,
    CUSTOM_ELEMENTS_SCHEMA,
    DoBootstrap,
    LOCALE_ID,
    NgModule
} from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BuModule } from './beratungsmodule/bu/bu.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { DaxModule } from './beratungsmodule/dax/dax.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { WunschbetragModule } from './beratungsmodule/wunschbetrag/wunschbetrag.module';
import { CardModule } from 'primeng/card';
import { CostAverageModule } from './beratungsmodule/cost-average/cost-average.module';
import { SparplanModule } from './beratungsmodule/sparplan/sparplan.module';
import { SparfoerderungModule } from './beratungsmodule/sparfoerderung/sparfoerderung.module';
import {
    PersoenlicheVermoegenssicherungModule
} from './beratungsmodule/persoenliche-vermoegenssicherung/persoenliche-vermoegenssicherung.module';
import {
    VersicherteAktivitaetenModule
} from './beratungsmodule/versicherte-aktivitaeten/versicherte-aktivitaeten.module';
import { VersicherteZeitModule } from './beratungsmodule/versicherte-zeit/versicherte-zeit.module';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ImpressumComponent } from './menu/impressum/impressum.component';
import { DatenschutzComponent } from './menu/datenschutz/datenschutz.component';
import { NutzungsbedingungComponent } from './menu/nutzungsbedingung/nutzungsbedingung.component';
import { SharedModule } from './shared/shared.module';
import { GesundheitsplanerModule } from './beratungsmodule/gesundheitsplaner/gesundheitsplaner.module';
import { AppInitService } from './app-init.service';
import { KeycloakService } from './shared/services/keycloak.service';
import { httpInterceptorProviders } from './shared/interceptor/http-interceptors';
import { KundenbonusComponent } from './beratungsmodule/kundenbonus/kundenbonus.component';
import { StartseiteModule } from './startseite/startseite.module';
import { DesignSystemModule } from '@dvag/design-system-angular';
import { KuboItemsComponent } from './beratungsmodule/kundenbonus/kubo-items/kubo-items.component';
import {
    KuboItemBarChartComponent
} from './beratungsmodule/kundenbonus/kubo-item-bar-chart/kubo-item-bar-chart.component';
import { KuboCircleComponent } from './beratungsmodule/kundenbonus/kubo-circle/kubo-circle.component';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { InvestmentfondsModule } from './beratungsmodule/investmentfonds/investmentfonds.module';
import { CardFooterComponent } from './layout/card-footer/card-footer.component';
import { AppInsightsService } from './shared/services/app-insights.service';

Chart.register(ChartDataLabels);

registerLocaleData(localeDe, 'de-DE');

export function initApp(initService: AppInitService) {
    return () => initService.init();
}

const keycloakService = new KeycloakService();

@NgModule({ declarations: [
        AppComponent,
        ImpressumComponent,
        DatenschutzComponent,
        NutzungsbedingungComponent,
        KundenbonusComponent,
        KuboItemsComponent,
        KuboItemBarChartComponent,
        KuboCircleComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA], imports: [AppRoutingModule,
        BrowserModule,
        BrowserAnimationsModule,
        BuModule,
        DaxModule,
        DesignSystemModule,
        CardModule,
        CostAverageModule,
        DaxModule,
        PersoenlicheVermoegenssicherungModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        StartseiteModule,
        SparfoerderungModule,
        SparplanModule,
        VersicherteAktivitaetenModule,
        WunschbetragModule,
        VersicherteZeitModule,
        SharedModule,
        GesundheitsplanerModule,
        InvestmentfondsModule,
        CardFooterComponent], providers: [
        httpInterceptorProviders,
        {
            provide: APP_INITIALIZER,
            useFactory: initApp,
            multi: true,
            deps: [AppInitService]
        },
        {
            provide: LOCALE_ID,
            useValue: 'de-DE'
        },
        {
            provide: KeycloakService,
            useValue: keycloakService
        },
        httpInterceptorProviders,
        AppInsightsService,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule implements DoBootstrap {
    ngDoBootstrap(appRef: ApplicationRef) {
        keycloakService.initKeycloak('login-required').then(() => {
            appRef.bootstrap(AppComponent);
        });
    }
}
