import { Component } from '@angular/core';

@Component({
    selector: 'app-fipy-start-page',
    templateUrl: './fipy-start-page.component.html',
    styleUrls: ['./fipy-start-page.component.scss']
})
export class FipyStartPageComponent {
    showList = false;

    toggle() {
        this.showList = !this.showList;
    }
}
