<dx-text class="page__heading" type="h2">Versicherte Zeit</dx-text>

<dx-card>
    <dx-card-header label="{{ state?.header }}"></dx-card-header>
    <dx-card-content>
        <div class="container">
            <app-timetable [type]="state.step"></app-timetable>
            <div class="divider"></div>
            <div class="text-wrapper">
                <dx-text [type]="'h5'">{{ state?.description?.listHeader }}</dx-text>
                <ul class="list-item">
                    <li *ngFor="let listItem of state?.description?.list">
                       <dx-text type="ps"> {{ listItem }}</dx-text>
                    </li>
                </ul>
            </div>
        </div>
        <app-card-footer [showPrev]="state.step !== 'gesetzlich'" (prevPage)="prevPage()" [showNext]="state.step === 'gesetzlich'" (nextPage)="nextPage()"></app-card-footer>
    </dx-card-content>
</dx-card>
