import { Injectable } from '@angular/core';
import { KuboItem, KuboItemModel } from '../interface';

@Injectable({
    providedIn: 'root'
})
export class KundenbonusService {
    _kuboItems: KuboItemModel[] = [
        {
            show: true,
            canToggle: true,
            value: KuboItem.Haftpflicht,
            additionalText: 'OPTIMAL',
            activated: false,
        },
        {
            show: true,
            canToggle: true,
            value: KuboItem.Hausrat,
            additionalText: 'OPTIMAL',
            activated: false,
        },
        {
            show: true,
            canToggle: false,
            value: KuboItem.HausratXXL,
            alternativeTitle: 'Hausrat',
            additionalText: 'OPTIMAL XXL',
            activated: false,
        },
        {
            show: true,
            canToggle: true,
            value: KuboItem.Glas,
            activated: false,
        },
        {
            show: true,
            canToggle: true,
            value: KuboItem.Unfall,
            activated: false,
        },
        {
            show: true,
            canToggle: true,
            value: KuboItem.Reise,
            activated: false,
        },
        {
            show: true,
            canToggle: true,
            value: KuboItem.Wohngebaeude,
            additionalText: 'OPTIMAL',
            activated: false,
        },
        {
            show: false,
            canToggle: false,
            value: KuboItem.KFZ,
            additionalText: 'OPTIMAL',
            activated: false,
            maxRabatt: 10,
        },
        {
            show: false,
            canToggle: false,
            value: KuboItem.Rechtsschutz,
            activated: false,
        },
        {
            show: false,
            canToggle: false,
            value: KuboItem.Gesundheit,
            activated: false,
            maxRabatt: 0,
        },
    ];

    kuboItems() {
        return this._kuboItems;
    }

    clamp(min, value, max) {
        return Math.max(min, Math.min(value, max));
    }

    toggle(item: string, currentItems: KuboItemModel[]): KuboItemModel[] {
        const items = [...currentItems];
        const index = items.findIndex(it => it.value === item);

        if (index >= 0) {
            items[index].activated = ( items[index].activated as any).target.checked;
        }
        return items;
    }

    calculate(currentItems: KuboItemModel[]): { items: KuboItemModel[], percent: number, canGoNext: boolean } {
        const items = [...currentItems];

        let activatedBoxes = items.filter(it => it.activated);

        // Check for Hausrat XXL
        const hausratXxl = [
            KuboItem.Haftpflicht,
            KuboItem.Hausrat,
            KuboItem.Glas,
            KuboItem.Unfall,
        ];

        const hausratXxlEnabled = hausratXxl.every(value => activatedBoxes.find(it => value === it.value));
        const index = items.findIndex(it => it.value === KuboItem.HausratXXL);

        if (index >= 0) {
            items[index].canToggle = hausratXxlEnabled;
            if (!hausratXxlEnabled) {
                items[index].activated = false;
                activatedBoxes = items.filter(it => it.activated);
            }
        }

        // Check for vspBonus
        const vspBonusArray = [
            KuboItem.Haftpflicht,
            KuboItem.Hausrat,
            KuboItem.Glas,
            KuboItem.Unfall,
        ];
        const vspBonusEnabled = vspBonusArray.every(value => activatedBoxes.find(it => value === it.value));


        const countedBoxes = activatedBoxes.length;
        const reduce = [
            KuboItem.HausratXXL,
            KuboItem.Reise,
        ]
        const hauratUndReiseEnabled = reduce.every(value => activatedBoxes.find(it => value === it.value));
        const reduceRabatt = hauratUndReiseEnabled ? 2 : 1;
        const percent = activatedBoxes.length < 3 ? 0 : this.clamp(0, (countedBoxes - reduceRabatt) * 5, 25);

        return {
            items: items.map(it => {
                const calculatePercent = this.clamp(0, percent, it.maxRabatt != null ? it.maxRabatt : percent);
                let vspBonus = 0;
                if (vspBonusEnabled) {
                    if (it.value === KuboItem.Gesundheit) {
                        vspBonus = 5;
                    } else if (it.value === KuboItem.Rechtsschutz) {
                        vspBonus = 5;
                    } else if (it.value === KuboItem.KFZ) {
                        vspBonus = 10;
                    }
                }
                return {
                    ...it,
                    vspBonus,
                    calculatePercent,
                }
            }),
            percent,
            canGoNext: activatedBoxes.length >= 3
        };
    }
}
