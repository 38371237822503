import { Injectable } from '@angular/core';
import { FipyCard, FipyCardSource, FipyModuleCategory, ModuleGroup } from '../interface';
import { environment } from '../../../environments/environment';


export enum AppEnvironment {
    Compeople = 'compeople',
    Lokal = 'lokal',
    Entwicklung = 'entwicklung',
    Abnahme = 'abnahme',
    Produktion = 'produktion'
}


@Injectable({
    providedIn: 'root'
})
export class StartseiteService {

    cards(): FipyCard[] {
        return [
            // Privater Vermögensaufbau
            {
                hiddenByRole: ['DVAG_WIEN'],
                image: 'assets/png/cards/geiger.png',
                title: 'Geiger Gold',
                tags: 'Geiger Gold Goldsparplan',
                source: FipyCardSource.NICHT_MEHR_VERFUEGBAR,
                link: 'openmodule/Geiger_Goldsparplan',
                maintained: true,
                deactivated: true,
                categories: [
                    {
                        prio: 0,
                        category: FipyModuleCategory.VERMOEGENSAUFBAU,
                    },
                    {
                        prio: 0,
                        category: FipyModuleCategory.RUECKLAGEN,
                    },
                ],
            },
            {
                hiddenByRole: ['DVAG_WIEN'],
                image: 'assets/png/cards/CostAverage.png',
                title: 'Cost-Average-Effekt',
                tags: 'Cost-Average-Effekt',
                source: FipyCardSource.WEB,
                link: 'https://vb-apps.dvag/#/beratungsmodule/cost-average',
                maintained: true,
                deactivated: false,
                categories: [
                    {
                        prio: 1,
                        category: FipyModuleCategory.VERMOEGENSAUFBAU,
                    },
                    {
                        prio: 2,
                        category: FipyModuleCategory.RUECKLAGEN,
                    },
                ],
            },
            {
                hiddenByRole: ['DVAG_WIEN'],
                image: 'assets/png/cards/DAX-Anlage.png',
                title: 'DAX-Anlage',
                tags: 'DAX-Anlage',
                source: FipyCardSource.WEB,
                link: 'https://vb-apps.dvag/#/beratungsmodule/dax',
                maintained: true,
                deactivated: false,
                categories: [
                    {
                        prio: 2,
                        category: FipyModuleCategory.VERMOEGENSAUFBAU,
                    },
                    {
                        prio: 3,
                        category: FipyModuleCategory.RUECKLAGEN,
                    },
                ],
            },
            {
                hiddenByRole: ['DVAG_WIEN'],
                image: 'assets/png/cards/Sparplan.png',
                title: 'Ihr Sparplan',
                tags: 'Ihr Sparplan',
                source: FipyCardSource.WEB,
                link: 'https://vb-apps.dvag/#/beratungsmodule/sparplan',
                maintained: true,
                deactivated: false,
                categories: [
                    {
                        prio: 3,
                        category: FipyModuleCategory.VERMOEGENSAUFBAU,
                    },
                    {
                        prio: 4,
                        category: FipyModuleCategory.RUECKLAGEN,
                    },
                ],
            },
            {
                hiddenByRole: ['DVAG_WIEN'],
                image: 'assets/png/cards/StaatlicheSparfoerderung.png',
                title: 'Staatliche Sparförderung',
                tags: 'Staatliche Sparförderung',
                source: FipyCardSource.WEB,
                link: 'https://vb-apps.dvag/#/beratungsmodule/sparfoerderung',
                maintained: true,
                deactivated: false,
                categories: [
                    {
                        prio: 4,
                        category: FipyModuleCategory.VERMOEGENSAUFBAU,
                    },
                    {
                        prio: 7,
                        category: FipyModuleCategory.RUECKLAGEN,
                    },
                ],
            },
            // Altersvorsorge
            {
                hiddenByRole: ['DVAG_WIEN'],
                image: 'assets/png/cards/Basisrente.png',
                title: 'BasisRente',
                tags: 'BasisRente',
                source: FipyCardSource.VP_DIGITAL_BERATUNGSWELT,
                link: 'openmodule/Expertenplaner_Basisrente',
                maintained: false,
                deactivated: true,
                categories: [
                    {
                        prio: 0,
                        category: FipyModuleCategory.ALTERSVERSORGUNG,
                    },
                ],
            },
            {
                hiddenByRole: ['DVAG_WIEN'],
                image: 'assets/png/cards/Rentenplaner.png',
                title: 'Rentenplaner',
                tags: 'Rentenplaner',
                source: FipyCardSource.BALD_IN_VP_DIGITAL,
                link: 'openmodule/Expertenplaner_Rente',
                maintained: true,
                deactivated: true,
                categories: [
                    {
                        prio: 1,
                        category: FipyModuleCategory.ALTERSVERSORGUNG,
                    },
                ],
            },
            {
                hiddenByRole: ['DVAG_WIEN'],
                image: 'assets/png/cards/Bestejahreplaner.png',
                title: 'Beste Jahre-Planer',
                tags: 'Beste Jahre-Planer',
                source: FipyCardSource.NICHT_MEHR_VERFUEGBAR,
                link: 'openmodule/ExpertenPlaner_BAV',
                maintained: false,
                deactivated: true,
                categories: [
                    {
                        prio: 2,
                        category: FipyModuleCategory.ALTERSVERSORGUNG,
                    },
                ],
            },
            {
                hiddenByRole: ['DVAG_WIEN'],
                image: 'assets/png/cards/langfristig-steuern-sparen.png',
                title: 'Langfristig Steuern sparen',
                tags: 'Langfristig Steuern sparen',
                source: FipyCardSource.NICHT_MEHR_VERFUEGBAR,
                link: 'openmodule/5_LangfristigSteuernSparen',
                maintained: false,
                deactivated: true,
                categories: [
                    {
                        prio: 3,
                        category: FipyModuleCategory.ALTERSVERSORGUNG,
                    },
                ],
            },
            {
                hiddenByRole: ['DVAG_WIEN'],
                image: 'assets/png/cards/Geldentwertung-durch-inflation.png',
                title: 'Geldentwertung durch Inflation',
                tags: 'Geldentwertung durch Inflation',
                source: FipyCardSource.NICHT_MEHR_VERFUEGBAR,
                link: 'openmodule/5_GeldentwertungDurchInflation',
                maintained: false,
                deactivated: true,
                categories: [
                    {
                        prio: 4,
                        category: FipyModuleCategory.ALTERSVERSORGUNG,
                    },
                ],
            },

            // Wohneigentum
            {
                hiddenByRole: ['DVAG_WIEN'],
                image: 'assets/png/cards/Immobilienplaner.png',
                title: 'Immobilienplaner',
                tags: 'Immobilienplaner',
                source: FipyCardSource.BALD_IN_VP_DIGITAL,
                link: 'openmodule/Expertenplaner_Immobilien',
                maintained: true,
                deactivated: true,
                categories: [
                    {
                        prio: 0,
                        category: FipyModuleCategory.WOHNEIGENTUM,
                    },
                ],
            },
            {
                hiddenByRole: ['DVAG_WIEN'],
                image: 'assets/png/cards/schoener-wohnen.png',
                title: 'Schöner Wohnen',
                tags: 'Schöner Wohnen',
                source: FipyCardSource.NICHT_MEHR_VERFUEGBAR,
                link: 'openmodule/5_SchoenerWohnen',
                maintained: false,
                deactivated: true,
                categories: [
                    {
                        prio: 1,
                        category: FipyModuleCategory.WOHNEIGENTUM,
                    },
                ],
            },
            {
                hiddenByRole: ['DVAG_WIEN'],
                image: 'assets/png/cards/Bausparen.png',
                title: 'Bausparen',
                tags: 'Bausparen',
                source: FipyCardSource.BALD_IN_VP_DIGITAL,
                link: 'openmodule/5_Bausparen',
                maintained: false,
                deactivated: true,
                categories: [
                    {
                        prio: 2,
                        category: FipyModuleCategory.WOHNEIGENTUM,
                    },
                ],
            },
            {
                hiddenByRole: ['DVAG_WIEN'],
                image: 'assets/png/cards/StaatlicheSparfoerderung.png',
                title: 'Staatliche Sparförderung',
                tags: 'Staatliche Sparförderung',
                source: FipyCardSource.WEB,
                link: 'https://vb-apps.dvag/#/beratungsmodule/sparfoerderung',
                maintained: true,
                deactivated: false,
                categories: [
                    {
                        prio: 3,
                        category: FipyModuleCategory.WOHNEIGENTUM,
                    },
                ],
            },


            // Vermögensabsicherung
            {
                hiddenByRole: ['DVAG_WIEN'],
                image: 'assets/png/cards/PersoenlicheVermoegenssicherung.png',
                title: 'Persönliche Vermögenssicherung',
                tags: 'Persönliche Vermögenssicherung',
                source: FipyCardSource.WEB,
                link: 'https://vb-apps.dvag/#/beratungsmodule/persoenliche-vermoegenssicherung',
                maintained: true,
                deactivated: false,
                categories: [
                    {
                        prio: 0,
                        category: FipyModuleCategory.VERMOEGENSABSICHERUNG,
                    },
                ],
            },
            {
                hiddenByRole: ['DVAG_WIEN'],
                image: 'assets/png/cards/Kundenbonus.png',
                title: 'KUNDENBONUS',
                tags: 'KUNDENBONUS',
                source: FipyCardSource.WEB,
                link: 'https://vb-apps.dvag/#/beratungsmodule/kundenbonus',
                maintained: true,
                deactivated: false,
                categories: [
                    {
                        prio: 1,
                        category: FipyModuleCategory.VERMOEGENSABSICHERUNG,
                    },
                ],
            },

            // Gesundheits- und Risikoversorgung
            {
                hiddenByRole: ['DVAG_WIEN'],
                image: 'assets/png/cards/Vermoegenswert-Arbeitskraft-Auto.png',
                title: 'Vermögenswert Arbeitskraft - Auto',
                tags: 'Vermögenswert Arbeitskraft - Auto',
                source: FipyCardSource.VP_DIGITAL_BERATUNGSWELT,
                link: 'openmodule/5_VermoegenswertArbeitskraftAuto',
                maintained: false,
                deactivated: true,
                categories: [
                    {
                        prio: 0,
                        category: FipyModuleCategory.GESUNDHEITSVORSORGE,
                    },
                ],
            },

            {
                hiddenByRole: ['DVAG_WIEN'],
                image: 'assets/png/cards/BU.png',
                title: 'Ursachen für Berufsunfähigkeit',
                tags: 'Ursachen für Berufsunfähigkeit',
                source: FipyCardSource.WEB,
                link: 'https://vb-apps.dvag/#/beratungsmodule/bu',
                maintained: true,
                deactivated: false,
                categories: [
                    {
                        prio: 1,
                        category: FipyModuleCategory.GESUNDHEITSVORSORGE,
                    },
                ],
            },
            {
                hiddenByRole: ['DVAG_WIEN'],
                image: 'assets/png/cards/Lueckenrechner.png',
                title: 'Lückenrechner',
                tags: 'Lückenrechner',
                source: FipyCardSource.VP_DIGITAL_BERATUNGSWELT,
                link: 'openmodule/5_Lueckenrechner',
                maintained: false,
                deactivated: true,
                categories: [
                    {
                        prio: 2,
                        category: FipyModuleCategory.GESUNDHEITSVORSORGE,
                    },
                ],
            },

            {
                hiddenByRole: ['DVAG_WIEN'],
                image: 'assets/png/cards/Wert.png',
                title: 'Wert',
                tags: 'Wert',
                source: FipyCardSource.NICHT_MEHR_VERFUEGBAR,
                link: 'openmodule/5_Wert',
                maintained: false,
                deactivated: true,
                categories: [
                    {
                        prio: 3,
                        category: FipyModuleCategory.GESUNDHEITSVORSORGE,
                    },
                ],
            },

            {
                hiddenByRole: ['DVAG_WIEN'],
                image: 'assets/png/cards/MeinGesundheitsplan.png',
                title: 'Mein Gesundheitsplan',
                tags: 'Mein Gesundheitsplan',
                source: FipyCardSource.WEB,
                link: 'https://vb-apps.dvag/#/beratungsmodule/gesundheitsplaner',
                maintained: true,
                deactivated: false,
                categories: [
                    {
                        prio: 4,
                        category: FipyModuleCategory.GESUNDHEITSVORSORGE,
                    },
                ],
            },

            {
                hiddenByRole: ['DVAG_WIEN'],
                image: 'assets/png/cards/Pflegevorsorge.png',
                title: 'Pflegevorsorge',
                tags: 'Pflegevorsorge',
                source: FipyCardSource.NICHT_MEHR_VERFUEGBAR,
                link: 'openmodule/5_PflegePlus',
                maintained: false,
                deactivated: true,
                categories: [
                    {
                        prio: 5,
                        category: FipyModuleCategory.GESUNDHEITSVORSORGE,
                    },
                ],
            },

            {
                hiddenByRole: ['DVAG_WIEN'],
                image: 'assets/png/cards/VersicherteZeit.png',
                title: 'Versicherte Zeit',
                tags: 'Versicherte Zeit',
                source: FipyCardSource.WEB,
                link: 'https://vb-apps.dvag/#/beratungsmodule/versicherte-zeit',
                maintained: true,
                deactivated: false,
                categories: [
                    {
                        prio: 6,
                        category: FipyModuleCategory.GESUNDHEITSVORSORGE,
                    },
                ],
            },
            {
                hiddenByRole: ['DVAG_WIEN'],
                image: 'assets/png/cards/VersicherteAktivitaet.png',
                title: 'Versicherte Aktivitäten',
                tags: 'Versicherte Aktivitäten',
                source: FipyCardSource.WEB,
                link: 'https://vb-apps.dvag/#/beratungsmodule/versicherte-aktivitaeten',
                maintained: true,
                deactivated: false,
                categories: [
                    {
                        prio: 7,
                        category: FipyModuleCategory.GESUNDHEITSVORSORGE,
                    },
                ],
            },

            // Rücklagen
            {
                hiddenByRole: ['DVAG_WIEN'],
                image: 'assets/png/cards/privat-kredit-smart.png',
                title: 'Privatkredit Smart',
                tags: 'Privatkredit Smart',
                source: FipyCardSource.BALD_IN_VP_DIGITAL,
                link: 'openmodule/Expertenplaner_PrivatkreditSmart',
                maintained: true,
                deactivated: true,
                categories: [
                    {
                        prio: 1,
                        category: FipyModuleCategory.RUECKLAGEN,
                    },
                ],
            },
            {
                hiddenByRole: ['DVAG_WIEN'],
                image: 'assets/png/cards/Wunschbetrag.png',
                title: 'Wunschbetrag',
                tags: 'Wunschbetrag',
                source: FipyCardSource.WEB,
                link: 'https://vb-apps.dvag/#/beratungsmodule/wunschbetrag',
                maintained: true,
                deactivated: false,
                categories: [
                    {
                        prio: 5,
                        category: FipyModuleCategory.RUECKLAGEN,
                    },
                ],
            },
            {
                hiddenByRole: ['DVAG_WIEN'],
                image: 'assets/png/cards/Funktionsweise-Investmentfonts.png',
                title: 'Funktionsweise Investmentfonds',
                tags: 'Funktionsweise Investmentfonds',
                source: FipyCardSource.WEB,
                link: 'https://vb-apps.dvag/#/beratungsmodule/funktionsweise-investmentfonds',
                maintained: true,
                deactivated: false,
                categories: [
                    {
                        prio: 6,
                        category: FipyModuleCategory.RUECKLAGEN,
                    },
                ],
            },
        ];
    }

    groups(): ModuleGroup[] {
        return [
            {
                moduleCategory: FipyModuleCategory.VERMOEGENSAUFBAU,
                label: 'Privater Vermögensaufbau',
                image: 'assets/png/modul/vermoegensaufbau.png',
            },
            {
                moduleCategory: FipyModuleCategory.ALTERSVERSORGUNG,
                label: 'Altersversorgung',
                image: 'assets/png/modul/altersversorgung.png',
            },
            {
                moduleCategory: FipyModuleCategory.WOHNEIGENTUM,
                label: 'Wohneigentum',
                image: 'assets/png/modul/wohneigentum.png',
            },
            {
                moduleCategory: FipyModuleCategory.VERMOEGENSABSICHERUNG,
                label: 'Vermögensabsicherung',
                image: 'assets/png/modul/vermoegensabsicherung.png',
            },
            {
                moduleCategory: FipyModuleCategory.GESUNDHEITSVORSORGE,
                label: 'Gesundheits- und Risikovorsorge',
                image: 'assets/png/modul/gesundheitsvorsorge.png',
            },
            {
                moduleCategory: FipyModuleCategory.RUECKLAGEN,
                label: 'Rücklagen',
                image: 'assets/png/modul/ruecklagen.png',
            },
        ];
    }

    getModule(category: FipyModuleCategory) {
        return this.groups().find(it => it.moduleCategory === category)
    }

    getVbAppsUrl() {
        console.log(environment.environment)
        switch(environment.environment) {
            case AppEnvironment.Produktion:
                return 'https://vb-apps.dvag/';
            case AppEnvironment.Abnahme:
                return 'https://vb-apps.abnahme.dvag/';
            case AppEnvironment.Lokal:
                return 'http://localhost:4200/';
            case AppEnvironment.Entwicklung:
            case AppEnvironment.Compeople:
                return 'https://vb-apps.entwicklung.dvag/';
            default:
                return 'https://vb-apps.dvag/';
        }
    }

    searchCards(value: string, category?: FipyModuleCategory): FipyCard[] {
        const searchStrings = value.toLocaleLowerCase().split(' ').filter(it => it.length > 2);
        if (searchStrings.length === 0) {
            return [];
        }
        const cards = category ? this.cardsByCategory(category) : this.cards();
        return cards.filter(it => {
            return searchStrings.every(search => it.tags.toLocaleLowerCase().includes(search));
        });
    }

    cardsByCategory(category: FipyModuleCategory): FipyCard[] {
        return this.cards().filter(it => {
            return it.categories.some(cat => cat.category === category);
        }).map(it => {
            return {
                ...it,
                prio: it.categories.find(cat => cat.category === category).prio,
            };
        }).sort((a, b) => {
            if (a.prio < b.prio) {
                return -1;
            } else if (a.prio > b.prio) {
                return 1;
            }
            return 0;
        });
    }
}

