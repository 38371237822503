import { Component } from '@angular/core';
import { KuboItemModel } from './interface';
import { KundenbonusService } from './service/kundenbonus.service';

@Component({
    selector: 'app-kundenbonus',
    templateUrl: './kundenbonus.component.html',
    styleUrls: ['./kundenbonus.component.scss']
})
export class KundenbonusComponent {
    activeSlide = 1;
    items: KuboItemModel[];

    percent = 0;
    currentPercent = 0;
    canGoNext = false;

    private inAnimation = false;

    constructor(private service: KundenbonusService) {
        this.items = this.service.kuboItems();
    }

    goToPage(slide: number) {
        if (this.activeSlide !== slide) {
            this.activeSlide = slide;
        }
    }

    itemsChanged(details) {
        const detail: KuboItemModel[] = details;
        const calculator = this.service.calculate(detail)
        this.items = calculator.items;
        this.currentPercent = calculator.percent;
        this.canGoNext = calculator.canGoNext

        this.animatePercent();
    }

    animatePercent(force = false) {
        if (this.inAnimation && !force) {
            return;
        }
        this.inAnimation = true;
        setTimeout(() => {
            if (this.percent === this.currentPercent) {
                this.inAnimation = false;
                return;
            }
            if (this.currentPercent > this.percent) {
                this.percent += 1;
            } else {
                this.percent -= 1;
            }
            this.animatePercent(true);
        }, 50)
    }

}
