import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { CostAverageComponent } from './cost-average.component';
import { CaChartComponent } from './ca-chart/ca-chart.component';

import { ChartModule } from 'primeng/chart';
import { SliderModule } from 'primeng/slider';
import { CaCrashComponent } from './ca-crash/ca-crash.component';
import { SharedModule } from '../../shared/shared.module';
import { DesignSystemModule } from '@dvag/design-system-angular';

@NgModule({
    declarations: [CostAverageComponent, CaChartComponent, CaCrashComponent],
    imports: [CommonModule, ChartModule, SliderModule, FormsModule, SharedModule, DesignSystemModule]
})
export class CostAverageModule {}
