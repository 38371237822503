import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { GesundheitsplanerService } from '../services/gesundheitsplaner.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Observable, retry } from 'rxjs';
import { take } from 'rxjs/operators';
import { Gesundheitsdaten } from '../gesundheitsdaten.model';
import { ComboboxSearchController } from '@dvag/design-system';

@Component({
    selector: 'app-personenangaben',
    templateUrl: './personenangaben.component.html',
    styleUrls: ['./personenangaben.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PersonenangabenComponent implements OnInit {
    showNextBtn = false;
    showPrevBtn = true;


    /** Variables for the dateOfBirth input  */
    minDate: Date;
    maxDate: Date;


    /** Variables for insurance search and autocomplete */
    insuranceResults$: Observable<string[]>;

    /** checkbox-values */
    between21And39 = false;
    gesundheitsdaten: Gesundheitsdaten;
    versicherungen: string[];

    showAlert = false;

    constructor(private gesundheitsplanerService: GesundheitsplanerService, private router: Router, private route: ActivatedRoute) {


    }

    ngOnInit(): void {
        this.fetchAngabenFromService();
        this.setDateOfBirth();
        this.gesundheitsplanerService.getVersicherungen().pipe(take(1), retry(5)).subscribe({
            next: versicherungen => {this.versicherungen = versicherungen;},
            error: _ => {this.showAlert = true;}
        });
    }


    setDateOfBirth(date: string = '') {
        if (date) {
            this.gesundheitsdaten.geburtsDatum = new Date(date);
        }
        this.gesundheitsdaten.alter = moment().diff(this.gesundheitsdaten.geburtsDatum, 'year');
        this.between21And39 = this.gesundheitsdaten.alter > 14 && this.gesundheitsdaten.alter < 40;
    }


    showNextButton() {
        return !!(
            this.gesundheitsdaten.anrede &&
            this.gesundheitsdaten.geburtsDatum &&
            this.gesundheitsdaten.aktuelleKrankenkasse &&
            ((this.gesundheitsdaten.monatlichesBruttoEinkommen >= 0 && this.gesundheitsdaten.monatlichesNettoEinkommen >= 0) ||
                this.gesundheitsdaten.hatKeinEigenesEinkommen === true)
        );
    }

    nextPage() {
        this.saveAngabenInService();
        this.gesundheitsdaten.alter = moment().diff(this.gesundheitsdaten.geburtsDatum, 'year');
        if (this.gesundheitsdaten.alter > 14) {
            this.router.navigate(['/beratungsmodule/zahnverlust'], { relativeTo: this.route });
        } else {
            this.router.navigate(['/beratungsmodule/kinderauswahl'], { relativeTo: this.route });
        }
    }

    prevPage() {
        this.saveAngabenInService();
        this.router.navigate(['/beratungsmodule/gesundheitsplaner'], { relativeTo: this.route });
    }

    private fetchAngabenFromService() {
        console.log(this.gesundheitsplanerService.gesundheitsdaten);
        this.gesundheitsdaten = this.gesundheitsplanerService.gesundheitsdaten;
        this.gesundheitsdaten.anzahlFehlendeZaehne = this.gesundheitsdaten.anzahlFehlendeZaehne ?? 0;
        this.gesundheitsdaten.anzahlWurzelBehandelteZaehne = this.gesundheitsdaten.anzahlWurzelBehandelteZaehne ?? 0;
    }

    private saveAngabenInService() {
        if (this.gesundheitsdaten.hatKeinEigenesEinkommen) {
            this.gesundheitsdaten.monatlichesNettoEinkommen = 0;
            this.gesundheitsdaten.monatlichesBruttoEinkommen = 0;
        }
        this.gesundheitsplanerService.gesundheitsdaten.anrede = this.gesundheitsdaten.anrede;
        this.gesundheitsplanerService.gesundheitsdaten.geburtsDatum = this.gesundheitsdaten.geburtsDatum;
        this.gesundheitsplanerService.gesundheitsdaten.monatlichesBruttoEinkommen = this.gesundheitsdaten.monatlichesBruttoEinkommen;
        this.gesundheitsplanerService.gesundheitsdaten.monatlichesNettoEinkommen = this.gesundheitsdaten.monatlichesNettoEinkommen;
        this.gesundheitsplanerService.gesundheitsdaten.aktuelleKrankenkasse = this.gesundheitsdaten.aktuelleKrankenkasse;
        this.gesundheitsplanerService.gesundheitsdaten.anzahlFehlendeZaehne = this.gesundheitsdaten.anzahlFehlendeZaehne;
        this.gesundheitsplanerService.gesundheitsdaten.anzahlWurzelBehandelteZaehne = this.gesundheitsdaten.anzahlWurzelBehandelteZaehne;
        this.gesundheitsplanerService.gesundheitsdaten.berufsunfaehigkeitsRente = this.gesundheitsdaten.berufsunfaehigkeitsRente;
        this.gesundheitsplanerService.gesundheitsdaten.hatKeinEigenesEinkommen = this.gesundheitsdaten.hatKeinEigenesEinkommen;
        this.gesundheitsplanerService.gesundheitsdaten.hatKinderUnter16Jahren = this.gesundheitsdaten.hatKinderUnter16Jahren;
        this.gesundheitsplanerService.gesundheitsdaten.hatVaspVorteil = this.gesundheitsdaten.hatVaspVorteil;
        this.gesundheitsplanerService.gesundheitsdaten.istSehHilfeVorhanden = this.gesundheitsdaten.istSehHilfeVorhanden;
        this.gesundheitsplanerService.gesundheitsdaten.mitVspNachlass = this.gesundheitsdaten.mitVspNachlass;
        this.gesundheitsplanerService.gesundheitsdaten.istInAusbildung = this.gesundheitsdaten.istInAusbildung;
    }

    /** get the list of insurances from service */
    search(event: CustomEvent) {

        if(!this.versicherungen || this.versicherungen.length < 1) {
            return;
        }

        const provider: ComboboxSearchController = event.detail;
        if (provider.value.length < 2) {
            provider.provideValues([]);
            return;
        }
        const options = this.versicherungen.filter(versicherung => versicherung.toLowerCase().indexOf(provider.value.toLowerCase()) > -1).map((versicherung) =>
            ({ value: versicherung, label: versicherung }));
        provider.provideValues([{ label: null, options }]);
    }

    toggleBU(checked: boolean) {
        this.gesundheitsdaten.berufsunfaehigkeitsRente = checked;
        if(this.gesundheitsdaten.berufsunfaehigkeitsRente) {
            this.gesundheitsdaten.hatVaspVorteil = true;
        }
    }
}
