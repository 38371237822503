<div>
    @for (it of items; track it.value) {
        <dx-checkbox *ngIf="it.show"
            [id]="it.value"
            [disabled]="!it.canToggle || !canChange"
            [label]="it.value"
            [(checked)]="it.activated"
            (checkedChange)="update(it)"
        ></dx-checkbox>
    }
</div>
