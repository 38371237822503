import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { KeycloakService } from '../services/keycloak.service';
import { environment } from '../../../environments/environment';
import { mergeMap } from 'rxjs/operators';

@Injectable()
export class KeycloakBearerInterceptor implements HttpInterceptor {
    constructor(private keycloak: KeycloakService) {}

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const keycloak = this.keycloak.keycloakInstance;

        if (!req.url.startsWith(environment.backend.baseUrl) || !keycloak.authenticated) {
            return next.handle(req);
        }

        return this.keycloak.updateToken().pipe(
            mergeMap(token => {
                return next.handle(req.clone({ setHeaders: { Authorization: 'Bearer ' + token } }));
            })
        );
    }
}
