<div>
    <dx-modal (modalClosed)="closeSearchModal()" [visible]="showSearchModal" id="module-suche" [height]="'content'"
               label="{{currentModule?.label}}">
        <div class="search-content" slot="content">
            <div class="search-content" slot="content">
                <dx-text-input id="search-text-input" (valueChange)="search($event.detail)" label=""
                               placeholder="Suchbegriff eingeben..."
                               [value]="searchInput"></dx-text-input>
                <app-fipy-module-cards [cards]="searchCards" [searchText]="searchText"
                                       [insideModal]="true"></app-fipy-module-cards>
            </div>
        </div>
    </dx-modal>

        <div id="single-module" class="fullscreen-modal" *ngIf="showModulesModal">
            <div class="container">
                <div class="header">
                    <dx-button type="text" (click)="closeModal()" icon="chevron-links"></dx-button>
                    <dx-button type="text" icon="lupe" (click)="startSearch()"></dx-button>
                </div>
                <div class="content">
                    <app-fipy-module-cards [cards]="cards"></app-fipy-module-cards>
                </div>
            </div>
        </div>

    <div class="content">
        <dx-text type='h5' class="page__heading">Willkommen zur Allfinanzberatung</dx-text>
        <dx-card *ngFor="let modul of groups">
            <dx-card-image *ngIf="modul.image" [url]="modul.image"></dx-card-image>
            <div class="dummy-image" *ngIf="!modul.image"></div>
            <dx-card-content>
                <div class="title">
                    <dx-text type="h5">{{modul.label}}</dx-text>
                </div>

                <dx-button aria-label="{{'Button Select '+modul.label}}" (click)="showModal(modul)" stretch="true" type="primary-s" [label]="'Modul auswählen'" slot="footer">
                </dx-button>
            </dx-card-content>
        </dx-card>

        <div class="leistungsradar">
            <dx-card>
                <dx-card-image url="assets/png/cards/Leistungsradar.png"></dx-card-image>
                <dx-card-content>
                    <div class="title">
                        <dx-text type="h5">Leistungsradar</dx-text>
                    </div>
                    <dx-button (click)="leistungsradarButton()" stretch="true" type="primary-s" [label]="'Zum Leistungsradar'" slot="footer"></dx-button>
                </dx-card-content>
            </dx-card>
        </div>
    </div>
</div>
