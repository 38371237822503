<dx-modal (modalClosed)="nutzungsbedingungenModalVisible = false" [visible]="nutzungsbedingungenModalVisible"
          id="module-Nutzungsbedingungen"
          [height]="'content'"
          label="Nutzungsbedingungen">
    <div class="content" slot="content">
        <dx-text type="ps">
            Stand: 10/2019
        </dx-text>
        <dx-text type="h3">Nutzungsbedingungen</dx-text>
        <dx-text type="ps">
            für sämtliche - auch zukünftigen - IT-Produkte und IT-Dienste (nachfolgend als "Dienste" bezeichnet), wie
            z.B. Online-System, sämtliche
            Apps zur Vertriebsunterstützung, E-Mail-Postfach oder Internet-Präsenzen, alle zur ausschließlich
            geschäftlichen Nutzung der
        </dx-text>
        <ul>
            <li>
                <dx-text type="ps">
                    Deutsche Vermögensberatung AG DVAG, Wilhelm-Leuschner-Straße 24, 60329 Frankfurt/Main
                </dx-text>
            </li>
            <li>
                <dx-text type="ps">
                    Allfinanz Deutsche Vermögensberatung AG, Wilhelm-Leuschner-Straße 24, 60329 Frankfurt/Main
                </dx-text>
            </li>
            <li>
                <dx-text type="ps">
                    Allfinanz Aktiengesellschaft DVAG, Windmühlstraße 14, 60329 Frankfurt/MainDeutsche Vermögensberatung
                    Bank Aktiengesellschaft,
                    Rotenturmstraße 16-18, 1010 Wien
                </dx-text>
            </li>
        </ul>
        <dx-text type="ps">(im Folgenden "die Gesellschaften" genannt)</dx-text>

        <dx-text type="h4">1. Anwendungs- und Geltungsbereich dieser Nutzungsbedingungen</dx-text>

        <dx-text type="ps">
            1.1. Diese Nutzungsbedingungen gelten für die Nutzung aller Dienste der Gesellschaften durch
            Vermögensberater. Sie regeln die Nutzung
            der von den Gesellschaften während des bestehenden Vertragsverhältnisses den Vermögensberatern
            bereitgestellten Dienste und ergänzen
            insoweit den Vermögensberater-Vertrag und die ggf. geschlossenen Software-Lizenzverträge.
        </dx-text>
        <dx-text type="ps">1.2. Die Regelungen des Vermögensberater-Vertrages und der Software-Lizenzverträge gelten
            unverändert
            fort.
        </dx-text>

        <dx-text type="h4">2. Beschränkung der Nutzung auf vertragliche Zwecke, Verbot privater Nutzung</dx-text>

        <dx-text type="ps">
            Sämtliche von den Gesellschaften angebotenen Dienste dürfen nur zu geschäftlichen Zwecken im Rahmen des
            Vermögensberater-Vertrages und
            bei bestehendem Vermögensberater-Vertragsverhältnis genutzt werden. Eine private Nutzung der Dienste ist
            untersagt.
        </dx-text>

        <dx-text type="h4">3. Leistungsumfang</dx-text>
        <dx-text type="ps">
            3.1. Die Auswahl, Art und Umfang der Dienste können von dem jeweils gewählten Software-Lizenzvertrag und
            gegebenenfalls zusätzlichen
            Bedingungen oder Voraussetzungen, wie z.B. entsprechender weiterer Authentifizierungen abhängig sein.
        </dx-text>
        <dx-text type="ps">
            3.2. Der Vermögensberater erhält das widerrufliche Recht, Drittbenutzern, die für ihn im Rahmen seiner
            Vermögensberateraufgaben tätig
            sind, die Nutzung des DVAG Online-Systems einzuräumen. Für diese Drittbenutzer ist eine eigene
            Benutzerkennung anzulegen und eine
            personalisierte Aktivierungsnummer auszuhändigen. Die personalisierte Aktivierungsnummer für den
            Drittbenutzer kann in der
            Zugangsverwaltung des jeweiligen Mitbenutzers generiert werden. Im Mitbenutzerbereich der Zugangsverwaltung
            hat der Vermögensberater vor
            Einrichtung der Mitbenutzung die schriftlichen Nutzungsbedingungen herunterzuladen und von dem Drittbenutzer
            unterzeichnen zu lassen.
            Erst danach teilt der Vermögensberater dem Drittbenutzer die personalisierte Aktivierungsnummer mit. Der
            Vermögensberater bewahrt die
            unterschriebenen Nutzungsbedingungen auf und übergibt diese auf Verlangen an die Gesellschaften.
        </dx-text>

        <dx-text type="h4">4. Pflichten und Obliegenheiten des Vermögensberaters</dx-text>
        <dx-text type="ps">
            4.1. Der Vermögensberater ist insbesondere verpflichtet:
        </dx-text>
        <dx-text type="ps">
            a) Benutzerkennungen, Passwörter oder sonstige sicherheits- und zugangsrelevante Daten gemäß den
            spezifischen Voraussetzungen
            entsprechend einzusetzen, Dritten nicht zugänglich zu machen und vor dem Zugriff durch Dritte geschützt
            aufzubewahren. Der
            Vermögensberater ist verpflichtet, den Zugriff auf Dienste bzw. die Nutzung durch Dritte mittels geeigneter,
            dem Stand der Technik
            entsprechender technischer und organisatorischer Maßnahmen zu verhindern;
        </dx-text>
        <dx-text type="ps">
            b) das Passwort unverzüglich zu ändern bzw. es von den Gesellschaften ändern lassen, Zugangssperrungen
            selbst zu veranlassen und/oder
            über die Hotline der Gesellschaften zu veranlassen, wenn Anhaltspunkte dafür bestehen, dass Dritte von
            Passwörtern oder den Daten
            Kenntnis erlangt haben können oder diese Gefahr besteht;
        </dx-text>
        <dx-text type="ps">
            c) die in den Diensten zur Verfügung gestellten Informationen und Daten vertraulich zu behandeln,
            insbesondere diese Informationen und
            Daten nicht an unbefugte Dritte zu übermitteln, zu vervielfältigen oder zugänglich zu machen. Die
            vertraulichen Informationen sind durch
            angemessene Geheimhaltungsmaßnahmen gegen den unbefugten Zugriff durch Dritte zu sichern. Dies beinhaltet
            auch dem aktuellen Stand der
            Technik angepasste technische Sicherheitsmaßnahmen und die Verpflichtung der Mitarbeiter auf die
            Vertraulichkeit;
        </dx-text>
        <dx-text type="ps">
            d) die gesetzlichen Bestimmungen einzuhalten, wie z. B. die jeweils gültigen Datenschutzbestimmungen,
            Nutzungs-, Urheber-, gewerbliche
            Schutzrechte, das Gesetz gegen den unlauteren Wettbewerb (UWG) und das Gesetz zum Schutz von
            Geschäftsgeheimnissen (GeschGehG);
        </dx-text>
        <dx-text type="ps">
            e) die Software weder zu dekompilieren, rückassemblieren, noch auf andere Weise in allgemein lesbare Form
            umzuwandeln, sowie Software
            oder Teile der Software sowie hieraus abgeleitete Produkte zu ändern, anzupassen, zu übersetzen, zu
            vermieten, zu verleasen, zu
            verleihen, weiterzugeben oder herzustellen;
        </dx-text>
        <dx-text type="ps">
            f) keine Inhalte in Dienste einzustellen oder auf solche Informationen hinzuweisen oder Verbindungen zu
            solchen Seiten bereitzustellen
            (z. B. durch einen Link), die rechts- oder sittenwidrige Inhalte enthalten oder das Ansehen der
            Gesellschaften schädigen können.
            Zuwiderhandlungen stellen einen massiven Verstoß dar;
        </dx-text>
        <dx-text type="ps">
            g) gespeicherte Daten frei von schädlichem und gefährlichem Code (Viren, Trojanern, Spyware, Würmern,
            Malware etc.) zu halten;
        </dx-text>
        <dx-text type="ps">
            h) keine Handlungen auszuführen, die die Dienste, Netze und Server in ihrer Funktionsweise oder Stabilität
            schädigen oder überlasten
            könnten.
        </dx-text>
        <dx-text type="ps">
            4.2. Verstößt ein Vermögensberater oder ein von ihm autorisierter Dritter (Drittbenutzer) gegen die hier
            festgelegten Pflichten und
            Obliegenheiten, sind die Gesellschaften berechtigt, das gesamte Vertragsverhältnis mit dem Vermögensberater
            und diese
            Nutzungsbedingungen fristlos zu kündigen und/oder den Zugang zu den Diensten jederzeit ohne vorherige
            Ankündigung zu sperren und die
            betreffenden Inhalte zu löschen.
        </dx-text>
        <dx-text type="ps">
            4.3. Ferner ist der Vermögensberater verpflichtet, den entstandenen oder noch entstehenden Schaden zu
            ersetzen sowie die Gesellschaften
            von sämtlichen Schadensersatz-, Aufwendungsersatz- oder sonstigen Ansprüchen von anderen Vermögensberatern
            oder Dritten freizustellen,
            die durch den Verstoß verursacht wurden.
        </dx-text>
        <dx-text type="ps">
            4.4. Neben der gemäß Ziffer 4.2 bestehenden Kündigungsmöglichkeit und der Schadensersatzverpflichtung, hat
            der Vermögensberater für
            jeden Verstoß gegen die in dieser Vorschrift festgelegten Pflichten und Obliegenheiten unter Ausschluss des
            Fortsetzungszusammenhangs an
            seine jeweilige Gesellschaft eine Vertragsstrafe in Höhe von Euro 6.000 zu zahlen.
        </dx-text>
        <dx-text type="ps">
            4.5. Der Vermögensberater haftet für Verstöße von Drittbenutzern gegen diese Nutzungsbedingungen wie für
            eigenes Verschulden.
        </dx-text>

        <dx-text type="h4">5. Verantwortlichkeit für Inhalte / Rechte an Daten und Inhalten</dx-text>
        <dx-text type="ps">
            5.1. Sofern die Gesellschaften neben eigenen Inhalten gegebenenfalls auch fremde Inhalte zur Nutzung
            bereitstellen oder darauf
            verweisen, machen die Gesellschaften sich diese Inhalte in keiner Weise zu Eigen, noch übernehmen die
            Gesellschaften für die
            Rechtmäßigkeit, Richtigkeit, Aktualität oder Vollständigkeit dieser Inhalte die Verantwortung.
        </dx-text>
        <dx-text type="ps">
            5.2. Daten, welche Vermögensberater als Nutzer der Dienste eingeben, speichern oder bearbeiten gelten als
            Daten der jeweiligen
            Gesellschaft. Der Vermögensberater räumt damit der Gesellschaft das unwiderrufliche, umfassende und
            ausschließliche Nutzungsrecht in
            zeitlicher und räumlicher Hinsicht sowie für alle bekannten Nutzungsarten ein.
        </dx-text>
        <dx-text type="ps">
            5.3. Sofern ein Dienst die Möglichkeit anbietet, eigene Inhalte einzustellen, insbesondere Bilder, Beiträge
            und Kommentare
            („Nutzergenerierter Inhalt“), ist der Vermögensberater für seine Inhalte selbst verantwortlich. Er
            versichert, dass er selbst Urheber
            der Inhalte ist und über die notwendigen Nutzungsrechte verfügt. Nutzergenerierter Inhalt kann von der
            jeweils verantwortlichen
            Gesellschaft jederzeit ohne Rückfragen ganz oder teilweise gelöscht werden.
        </dx-text>

        <dx-text type="h4">6. Datenschutzhinweise</dx-text>
        <dx-text type="ps">
            In Diensten der Gesellschaften werden personenbezogene Daten verarbeitet. Weitere Informationen können den
            "Datenschutzhinweise
            Online-Dienste" entnommen werden. Diese sind in den jeweiligen Diensten abrufbar.
        </dx-text>
        <dx-text type="h4">7. Bestimmungen für die Datenverarbeitung für den Vermögensberater</dx-text>
        <dx-text type="ps">
            7.1. Die Bestimmungen in diesem Abschnitt gelten ausschließlich für solche Verarbeitungstätigkeiten mit
            personenbezogenen Daten, welche
            die Gesellschaften für den Vermögensberater als Verantwortlichen erbringen. Die Gesellschaften einerseits
            und der Vermögensberater
            andererseits sind gemeinsam Verantwortliche im Sinne von Art. 26 DS-GVO. Zu den damit zusammenhängenden
            Rechten und Pflichten werden in
            Ergänzung der Datenschutzhinweise folgende Regelungen vereinbart:
        </dx-text>
        <dx-text type="ps">
            7.2. Die Nutzung der personenbezogenen Daten erfolgt sowohl durch die Gesellschaften als auch durch den
            Vermögensberater nach Maßgabe
            dieser Nutzungsbedingungen und der Datenschutzhinweise.
        </dx-text>
        <dx-text type="ps">
            7.3. Sollten personenbezogene Daten durch die Gesellschaften für den Vermögensberater verarbeitet werden, so
            ist der Vermögensberater
            für die Einhaltung der gesetzlichen Bestimmungen der Datenschutzgesetze, insbesondere der Zulässigkeit der
            jeweiligen Datenverarbeitung,
            verantwortlich. Die Gesellschaften können hierfür auch auf weitere Auftragsverarbeiter zurückgreifen. Der
            Vermögensberater ist auch für
            die Einhaltung der Pflichten zur Löschung verantwortlich.
        </dx-text>
        <dx-text type="ps">
            7.4. Sowohl die Gesellschaften als auch der Vermögensberater werden jeweils selbst die sie treffenden
            Pflichten zur Information der
            Betroffenen gem. Art. 13 und 14 DS-GVO einschließlich der Information über die wesentlichen Inhalte dieser
            Vereinbarung nach Art. 26
            DS-GVO erfüllen. Das Gleiche gilt bei Wahrnehmung der sonstigen Rechte durch einen Betroffenen gem. Art. 15
            DS-GVO. Der Vermögensberater
            hat die Gesellschaften unverzüglich und vollständig zu informieren, wenn ein Betroffener derartige Rechte
            geltend macht.
        </dx-text>
        <dx-text type="ps">
            7.5. Der Vermögensberater hat die Gesellschaften unverzüglich und vollständig zu informieren, wenn er im
            Hinblick auf die
            Datenverarbeitung Fehler oder Unregelmäßigkeiten feststellt. Der Vermögensberater ist für die Erfüllung der
            gesetzlichen Pflichten bei
            Datenschutzverletzungen verantwortlich soweit sie in seinem Einwirkungsbereich geschehen. Er informiert die
            Gesellschaften unverzüglich
            und vollständig. Meldungen an Aufsichtsbehörden stimmt der Vermögensberater vorher mit den Gesellschaften
            ab.
        </dx-text>
        <dx-text type="ps">
            7.6. Die Gesellschaften treffen in ihrem Verantwortungsbereich geeignete technische und organisatorische
            Maßnahmen zum Schutz der für
            den Vermögensberater verarbeiteten Daten gemäß Art. 32 DSGVO. Hierzu gehört auch die Verpflichtung von
            Mitarbeitern auf Vertraulichkeit.
        </dx-text>

        <dx-text type="h4">8. Gewährleistung und Haftung</dx-text>
        <dx-text type="ps">
            8.1. Die Gesellschaften gewährleisten keine ständige Verfügbarkeit von Diensten und können diese nach freiem
            Ermessen ganz oder
            teilweise einstellen, vorübergehend aussetzen oder ändern.
        </dx-text>
        <dx-text type="ps">
            8.2. Die Gesellschaften haften nicht für Verlust, Beschädigung oder Missbrauch von in Diensten gespeicherten
            Daten des Vermögensberaters
            oder Schäden infolge von Verzögerungen oder Leistungshindernissen. Ferner haften die Gesellschaften nicht
            für Verletzungen von
            Aufbewahrungspflichten durch den Vermögensberater aufgrund einer unrichtigen oder unvollständigen
            Archivierung von Dokumenten in
            Diensten der Gesellschaften.
        </dx-text>
        <dx-text type="ps">
            8.3. Die Gesellschaften haften - gleich aus welchem Rechtsgrund - nur für Schäden, die vorsätzlich oder grob
            fahrlässig verursacht
            wurden oder die darauf beruhen, dass die Gesellschaften gegenüber dem Vermögensberater einen Mangel an einem
            Dienst arglistig
            verschwiegen haben.
        </dx-text>
        <dx-text type="ps">
            8.4. Im Falle eines Schadens, der auf einem grob fahrlässigen Verschulden einfacher Erfüllungsgehilfen der
            Gesellschaften beruht, ist
            die Haftung der Gesellschaften der Höhe nach auf den typischerweise vorhersehbaren Schaden beschränkt.
        </dx-text>
        <dx-text type="ps">
            8.5. Eine weitergehende Haftung der Gesellschaften ist ausgeschlossen, es sei denn es besteht eine
            gesetzliche Haftungs- oder
            Mithaftungsverpflichtung.
        </dx-text>

        <dx-text type="h4">9. Änderungsvorbehalt</dx-text>
        <dx-text type="ps">
            Die Gesellschaften behalten sich eine jederzeitige Anpassung oder Änderung der Nutzungsbedingungen vor und
            werden die jeweiligen Dienste
            nur mit den jeweils neuen Nutzungsbedingungen zu Verfügung stellen. Sollte der Nutzer hierzu kein
            Einverständnis erklären, so ist eine
            uneingeschränkte Weiternutzung der Dienste nicht möglich.
        </dx-text>

        <dx-text type="h4">10. Sonstiges</dx-text>
        <dx-text type="ps">
            10.1. Sollte eine Bestimmung dieser Vereinbarung ganz oder teilweise unwirksam sein oder ihre
            Rechtswirksamkeit später verlieren, soll
            hierdurch die Gültigkeit der übrigen Bestimmungen nicht berührt werden.
        </dx-text>
        <dx-text type="ps">
            10.2. Anstelle der unwirksamen oder undurchführbaren Bestimmung werden die Parteien eine wirksame und
            durchführbare Bestimmung
            vereinbaren, die dem Zweck der unwirksamen Regelung am nächsten kommt. Im Falle einer Lücke gilt dies
            entsprechend.
        </dx-text>
        <dx-text type="ps">
            10.3. Es gilt deutsches Recht. Als Gerichtsstand wird Frankfurt am Main vereinbart.
        </dx-text>
        <dx-text type="h4">11. Dienstspezifische Nutzungsbedingungen</dx-text>
        <dx-text type="ps">
            Die Nutzungsbedingungen der Ziffern 1 bis 10 gelten im Allgemeinen für alle Dienste der Gesellschaften. Die
            folgenden Bedingungen gelten
            zusätzlich für die nachfolgenden Dienste:
        </dx-text>

        <dx-text type="h5">a. Elektronische Kundenakte (DVAG-Cloud) / Online-Speicherplatz nur in Premium-Lizenz
        </dx-text>
        <dx-text type="ps">
            1. Die DVAG-Cloud ergänzt das DVAG Online-System für Inhaber der Premium-Lizenz um einen Onlinespeicher und
            um Funktionen und
            Funktionalitäten zum Speichern, Abrufen, Verwalten und Einsehen von Daten und ist nur in Verbindung mit dem
            DVAG Online-System oder
            sonstigen gegebenenfalls von den Gesellschaften bereitgestellter Software (z. B. Apps) gestattet.
        </dx-text>
        <dx-text type="ps">
            2. Details zu Umfang und Speicherkapazität bzw. Speichererweiterung regelt der Software-Lizenzvertrag
            Premium-Lizenz.
        </dx-text>
        <dx-text type="ps">
            3. Die betreuende Gesellschaft ist zur optimalen Kundenbetreuung befugt, vom Vermögensberater eingestellte
            Daten einzusehen und zu
            verarbeiten.
        </dx-text>
        <dx-text type="ps">
            4. Im Rahmen des Strukturzugriffs können die Betreuer des Vermögensberaters auf die von dem Vermögensberater
            in der DVAG-Cloud den
            Kundenakten zugeordneten geschäftlichen Daten zugreifen. Bei Kundenübertragungen werden die den Kundenakten
            zugeordneten Daten ganz oder
            teilweise, vorübergehend oder dauerhaft an die betreuenden Vermögensberater übergeben. Die Daten, die vom
            Vermögensberater in der
            DVAG-Cloud in den Bereich "Eigene Dateien" abgelegt wurden, sind hiervon nicht betroffen.
        </dx-text>
        <dx-text type="ps">
            5. Die im Bereich „Eigene Dateien“ abgelegten Daten, werden mit Ende der Premium-Lizenz, spätestens jedoch
            mit Beendigung des
            Vermögensberater-Vertrages, gelöscht.
        </dx-text>

        <dx-text type="h5">b. E-Mail-Postfach</dx-text>
        <dx-text type="ps">
            Aufgrund der zunehmenden Verbreitung von sog. Spam-E-Mails setzen die Gesellschaften technische
            E-Mail-Filterprogramme ein. Diese
            Filterprogramme gewährleisten insbesondere die IT-Sicherheit, indem schadhafte E-Mails und E-Mail-Anhänge
            ausgesondert werden. Des
            Weiteren werden sog. Spam-E-Mails und E-Mails, die rechts- oder sittenwidrige Inhalte enthalten oder das
            Ansehen der Gesellschaft
            schädigen können, automatisiert gelöscht. Durch Einwilligung in die Nutzungsbedingungen erklären Sie sich
            mit dem Einsatz derartiger
            E-Mail-Filterprogramme einverstanden.
        </dx-text>

        <dx-text type="h5">c. Apps und DVAG-Store</dx-text>
        <dx-text type="ps">
            1. Die von der Gesellschaft bereitgestellten Apps („DVAG-Apps“) darf der Vermögensberater nur auf
            Mobilgeräten installieren, die der
            Vermögensberater durch geeignete, dem Stand der Technik entsprechende technische und organisatorische
            Maßnahmen vor unbefugtem Zugriff
            geschützt hat.
        </dx-text>
        <dx-text type="ps">
            2. Der DVAG-Store bietet Vermögensberatern die Möglichkeit, auf die DVAG-Apps zuzugreifen und diese im
            Rahmen dieser Nutzungsbedingungen
            zu nutzen. Neben den DVAG-Apps können auch ausgewählte Apps von Drittparteien abgerufen werden. Für von
            Drittparteien angebotene Apps
            übernehmen die Gesellschaften keinerlei Gewährleistung, oder Support. Ziffer 5.1 gilt entsprechend.
        </dx-text>
        <dx-text type="ps">
            3. Apps von Drittparteien stehen nur so lange im DVAG-Store zur Verfügung, wie die Gesellschaften berechtigt
            sind, diese App zur
            Verfügung zu stellen oder, wenn es sich um DVAG-Apps handelt, die Gesellschaften diese anbieten möchten. Die
            Gesellschaften behalten
            sich daher das Recht vor, Apps jederzeit und ohne Nennung von Gründen, aus dem DVAG-Store zu entfernen oder
            deren Verfügbarkeit
            auszusetzen.
        </dx-text>

        <dx-text type="h5">d. Fernwartung</dx-text>
        <dx-text type="ps">
            1. Die Gesellschaften bieten für einzelne Dienste IT-Supportdienstleistungen („IT-Support“) an. Der
            IT-Support kann telefonisch oder
            auch in Form einer Fernwartung erfolgen. Für die Fernwartung gelten zusätzlich die folgenden Bestimmungen.
        </dx-text>
        <dx-text type="ps">
            2. Die Fernwartung beschränkt sich ausschließlich auf die von den Gesellschaften bereitgestellten Dienste
            und auf die
        </dx-text>
        <dx-text type="ps">
            Unterstützung und Hilfestellung bei der Installation und Konfiguration von Diensten,Unterstützung bei
            Problemen mit Diensten,Suche nach
            Fehlern in Diensten.
        </dx-text>
        <dx-text type="ps">
            3. Die Fernwartung erfolgt unter der Verwendung geeigneter Fernwartungssoftware, die ausschließlich von der
            Gesellschaft im VB-Intranet
            bereitgestellt wird. Über die Fernwartungssoftware ermöglicht der Vermögensberater es dem jeweiligen
            Supportmitarbeiter, nach vorheriger
            Freigabe durch den Vermögensberater, sich auf den Computer aufzuschalten, auf diesen zuzugreifen, den
            Computer fernzusteuern und / oder
            Daten zu ändern. Der Vermögensberater kann alle vom Supportmitarbeiter durchführten Aktionen am Bildschirm
            verfolgen und jederzeit
            eingreifen oder den Zugriff abbrechen.
        </dx-text>
        <dx-text type="ps">
            4. Der Vermögensberater gestattet es den Gesellschaften, den Ablauf der Fernwartung zu protokollieren und
            aufzuzeichnen. Zweck der
            Protokollierung und Aufzeichnung ist es, bei Reklamationen Streitfragen zu klären. Die Gesellschaften werden
            die Aufzeichnungen
            zugriffsgeschützt für 30 Tage speichern und anschließend löschen. Mit Annahme dieser Nutzungsbedingungen
            sowie mit Aufbau der der
            Fernwartungsverbindung stimmt der Vermögensberater der Speicherung der Daten zu.
        </dx-text>
        <dx-text type="ps">
            5. Der Vermögensberater ist insbesondere verpflichtet:
        </dx-text>
        <dx-text type="ps">
            a) Vor Beginn der Fernwartung eine Sicherungskopie für alle von ihm benötigten Daten zu erstellen.
        </dx-text>
        <dx-text type="ps">
            b) Alle Programme und Dateien zu beenden bzw. zu schließen, deren Inhalte für den Supportmitarbeiter nicht
            sichtbar sein sollen.
        </dx-text>
        <dx-text type="ps">
            c) Fehler und Anwendungsprobleme sind so genau wie möglich zu beschreiben.
        </dx-text>
        <dx-text type="ps">
            6. Die Fernwartung erfolgt, sofern auf personenbezogene Daten zugegriffen werden kann, im Rahmen von Art. 28
            Datenschutz-Grundverordnung
            (DSGVO). Der Vermögensberater ist grundsätzlich selbst für die datenschutzrechtliche Zulässigkeit der
            Fernwartung und für den Schutz von
            personenbezogenen Daten verantwortlich. Weisungsberechtigter im Rahmen der Fernwartung ist der
            Vermögensberater. Der Aufbau der
            Verbindung für die Fernwartung unterliegt der Kontrolle des Vermögensberaters. Der Datentransfer erfolgt
            verschlüsselt.
        </dx-text>

        <dx-text type="ps">
            7. Sämtliche Supportmitarbeiter der Gesellschaften sind auf die Einhaltung datenschutzrechtlicher Vorgaben
            und auf Vertraulichkeit
            verpflichtet.
        </dx-text>
        <dx-text type="ps">
            8. Die Gesellschaft wird sämtliche personenbezogenen Daten, die bei der Fernwartung übermittelt werden, nach
            Beendigung der Fernwartung
            unverzüglich zu löschen. Ausgenommen sind die unter Ziff. 4. beschriebene Protokollierung und Aufzeichnung
            der Fernwartung.
        </dx-text>
    </div>
</dx-modal>
<div class="footer__content">
    <dx-text class="footer__content__copyright">© {{ year }} Deutsche Vermögensberatung</dx-text>
    <div class="footer__content__links">
        <dx-button label="Nutzungsbedingungen" type="text" (click)="showNutzungsbedingungen()"></dx-button>
        <dx-button label="Datenschutz" type="text" href="https://www.dvag.de/dvag/datenschutz.html" target="_blank"
                   iconposition="right" icon="link-extern"></dx-button>
        <dx-button label="Impressum" type="text" href="https://www.dvag.de/dvag/impressum.html" target="_blank"
                   iconposition="right" icon="link-extern"></dx-button>
    </div>
</div>
