import { SelectItem } from 'primeng/api';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { SparplanCalculationsService } from './sparplan-calculations.service';

@Component({
    selector: 'app-sparplan',
    templateUrl: './sparplan.component.html',
    styleUrls: ['./sparplan.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SparplanComponent implements OnInit, AfterViewInit {
    public years: SelectItem[];
    @ViewChild('percentContainer') public percentContainer;
    @ViewChild('interest') public interestSlider;
    @ViewChild('yearSlider') public yearSlider: HTMLDxSliderElement;
    @ViewChild('yearContainer') public yearContainer;
    @ViewChild('outputStackContainer') public outputStackContainer;

    // rendite slider
    public renditeString = '';
    public renditeSliderMin = 2;
    public renditeSliderMax = 12;
    public renditeSliderCurrent = 6;
    // year slider
    public yearSliderMin = 2024;
    public yearSliderMax = 2034;
    public yearSliderCurrent = 2024;

    endkapital = 0;
    einzahlungen = 0;
    entgangenerGewinn = 0;

    public endkapitalSubtitle = 'Endkapital';
    public einzahlungenSubtitle = 'Einzahlungen';
    public entgangenerGewinnSubtitle = 'Entgangener Gewinn';

    public selectedSpardauer;
    public selectedSparbetrag;

    private overallPayments = 0;

    // stacks
    private maxRoi = 0;
    private maxStackSize = 55;

    counterArrayGreenStack = [];
    counterArrayBlueStack = [];
    counterArrayRedStack = [];

    constructor(private sparplanCalculationsService: SparplanCalculationsService, private cdRef: ChangeDetectorRef) {
        this.years = [];
        for (let i = 1; i <= 50; i++) {
            this.years.push({ label: i + ' ' + 'Jahre', value: i });
        }
        this.endkapital = 0;
        this.einzahlungen = 0;
        this.entgangenerGewinn = 0;
    }

    public ngOnInit(): void {
        this.renditeString = this.renditeSliderCurrent.toFixed(1).toString();
        this.configureControls();
    }

    private configureControls() {
        const year = new Date().getFullYear();
        this.yearSliderMin = year;
        this.yearSliderMax = year + 10;
        this.yearSliderCurrent = year;
    }

    private dataChanged() {
        this.updateMaxRoi();
        this.shorteningChanged();
        this.cdRef.detectChanges();
    }

    private updateMaxRoi() {
        this.maxRoi = this.sparplanCalculationsService.calculateROIForYears(
            this.selectedSpardauer,
            this.renditeSliderMax,
            this.selectedSparbetrag
        );
    }

    ngAfterViewInit(): void {
        this.dataChanged();
        this.yearSlider.knoblabel = this.yearSliderCurrent.toString();
    }


    ////////////////////////
    // RENDITE (green stack) functions
    ////////////////////////

    public updateRenditeSlider(value: number) {
        this.renditeSliderCurrent = value;
        this.renditeChanged();
    }

    private renditeChanged() {
        // calculation
        const rendite = this.renditeSliderCurrent;
        const shortening = this.yearSliderCurrent - this.yearSliderMin;

        const years = this.selectedSpardauer;
        const savingAmount = this.selectedSparbetrag;

        const yearsWithShortening = years - shortening;
        let roi = this.sparplanCalculationsService.calculateROIForYears(yearsWithShortening, rendite, savingAmount);
        roi = this.sparplanCalculationsService.roundNumberAfter7thDigit(roi);
        this.endkapital = roi;

        this.updateMaxRoi();

        this.counterArrayGreenStack = new Array(this.calculateStackSizeFromAmount(roi));

        this.updateRedStack();
    }

    ////////////////////////
    // SPARBEGINN (blue stack) functions
    ////////////////////////

    updateYearSlider(value: number) {
        this.yearSliderCurrent = value;
        this.shorteningChanged();
        this.yearSlider.knoblabel = value.toString();
    }

    private shorteningChanged() {
        const shortening = this.yearSliderCurrent - this.yearSliderMin;
        this.overallPayments = this.sparplanCalculationsService.calculateOverallPayments(
            this.selectedSpardauer,
            this.selectedSparbetrag,
            shortening
        );

        this.overallPayments = this.sparplanCalculationsService.roundNumberAfter7thDigit(this.overallPayments);
        this.einzahlungen = this.overallPayments;

        this.updateMaxRoi();

        const stackSize = this.calculateStackSizeFromAmount(this.overallPayments);
        this.counterArrayBlueStack = new Array(stackSize);
        this.renditeChanged();
    }

    ////////////////////////
    // ENTGANGENER GEWINN (red stack) functions
    ////////////////////////

    private updateRedStack() {
        const paymentsWithoutShortening = this.sparplanCalculationsService.calculateOverallPayments(
            this.selectedSpardauer,
            this.selectedSparbetrag,
            0
        );

        const shortening = this.yearSliderCurrent - this.yearSliderMin;
        const yearsWithShortening = this.selectedSpardauer - shortening;

        const maxRoi = this.sparplanCalculationsService.calculateROIForYears(
            this.selectedSpardauer,
            this.renditeSliderCurrent,
            this.selectedSparbetrag
        );
        const roiWithShortening = this.sparplanCalculationsService.calculateROIForYears(
            yearsWithShortening,
            this.renditeSliderCurrent,
            this.selectedSparbetrag
        );

        let delta = maxRoi - paymentsWithoutShortening - (roiWithShortening - this.overallPayments);

        if (delta < 0) {
            delta = 0;
        }


        this.entgangenerGewinn = this.sparplanCalculationsService.roundNumberAfter7thDigit(delta);

        this.updateMaxRoi();

        this.counterArrayRedStack = new Array(this.calculateStackSizeFromAmount(delta));
    }

    private calculateStackSizeFromAmount(amount: number): number {
        if (amount <= 0 || this.maxRoi <= 0) {
            return 0;
        }
        let stackSize = (amount * this.maxStackSize) / this.maxRoi;
        stackSize = Math.round(stackSize);
        if (stackSize === 1) {
            stackSize = 2;
        }
        return stackSize;
    }

    // Calculates the stack 'shadow' positions (position of the outlines of the money bills).
    stackPositionAtIndex(index: number) {


        const posY = index * -54;
        return {
            bottom: posY + 'px'
        };
    }

    // Calculates the top position of the stack (where the actual bill has to appear).
    // If stack is empty the opacity is set to zero.
    topOfStackPosition(array: Array<number>) {

        if (array.length > 0) {
            const posY = array.length * -54;
            return {
                bottom: posY + 'px',
                opacity: 1
            };
        } else {
            return {
                opacity: 0
            };
        }
    }

    setSpardauer(spardauer: any) {
        if (!spardauer) {
            return;
        }
        this.selectedSpardauer = spardauer;
        this.resetYearsSlider();

        this.yearSliderMax = this.yearSliderMin + +this.selectedSpardauer;
    }

    private resetYearsSlider() {
        this.yearSliderCurrent = this.yearSliderMin;
        this.dataChanged();
    }

    setSparbetrag(sparbetrag: number) {
        if (!sparbetrag) {
            return;
        }
        this.selectedSparbetrag = sparbetrag;
        this.resetYearsSlider();
    }
}
