export class Gesundheitsdaten {
    aktuelleKrankenkasse?: string;
    anrede?: string;
    anzahlFehlendeZaehne?: number;
    alter?: number;
    anzahlWurzelBehandelteZaehne?: number;
    basisPlan?: BasisEnum;
    bonusVerdoppeln?: boolean;
    ektz?: boolean;
    ktbu?: boolean;
    geburtsDatum?: Date;
    gewuenschterTagesSatz?: number;
    hatGesundheitsBewusstesVerhaltenFitBonus?: boolean;
    hatKinderUnter16Jahren?: boolean;
    hatPflichtAktivitaetenFitBonus?: boolean;
    hatPlanAmbulant?: boolean;
    hatPlanStationaer?: boolean;
    hatSicherheitsPlan?: boolean;
    hatVaspVorteil?: boolean;
    hatWahlAktivitaetenKinderFitBonus?: boolean;
    hatWahlAktivitaetenSchwangerenFitBonus?: boolean;
    istInAusbildung?: boolean;
    istSehHilfeVorhanden?: boolean;
    mitVspNachlass?: boolean;
    monatlichesBruttoEinkommen?: number;
    monatlichesNettoEinkommen?: number;
    sponsorGesucht?: boolean;
    berufsunfaehigkeitsRente?: boolean;
    vitalityStatus?: VitalityEnum;
    sportclub?: boolean;
    vitalitysilber?: boolean;
    vitalitygold?: boolean;
    vitalityplatin?: boolean;
    nichtraucher?: boolean;
    sportaktivitaten?: boolean;
    bmi?: boolean;
    jugenduntersuchung?: boolean;
    zusatzversicherung?: boolean;
    impfungen?: boolean;
    checkup35?: boolean;
    pravention?: boolean;
    zahnvorsorge?: boolean;
    betrieblichegesundheitsforderung?: boolean;
    eigenfinanziertevorsorge?: boolean;

    private keinEigenesEinkommen?: boolean;

    public set hatKeinEigenesEinkommen(value: boolean) {
        this.keinEigenesEinkommen = value;
        if (value) {
            this.monatlichesBruttoEinkommen = 0;
            this.monatlichesNettoEinkommen = 0;
        }
    }

    public get hatKeinEigenesEinkommen(): boolean {
        return this.keinEigenesEinkommen;
    }

    public set hatektz(value: boolean) {
        this.ektz = value;
        if (this.ektz === true) {
            this.ktbu = false;
        }
    }

    public get hatektz(): boolean {
        return this.ektz;
    }

    public set hatktbu(value: boolean) {
        this.ktbu = value;
        if (this.ktbu === true) {
            this.ektz = false;
        }
    }

    public get hatktbu(): boolean {
        return this.ktbu;
    }
}

export enum VitalityEnum {
    SILBER = 'SILBER',
    GOLD = 'GOLD',
    PLATIN = 'PLATIN',
    KEIN_MITGLIED = 'KEIN MITGLIED',
}

export enum BasisEnum {
    BASISSCHUTZ1 = 'PLAN1',
    BASISSCHUTZ2 = 'PLAN2',
    BASISSCHUTZ3 = 'PLAN3',
    ZAHNSCHUTZ1 = 'PLANZ1',
    ZAHNSCHUTZ2 = 'PLANZ2',
    ZAHNSCHUTZ3 = 'PLANZ3',
    JUNIOR = 'PLANJ',
    EMPTY = ''
}
