import { Component, Input, OnChanges } from '@angular/core';
import { KuboItemModel } from '../interface';

@Component({
    selector: 'app-kubo-item-bar-chart',
    templateUrl: './kubo-item-bar-chart.component.html',
    styleUrls: ['./kubo-item-bar-chart.component.scss']
})
export class KuboItemBarChartComponent implements OnChanges {
    @Input() item: KuboItemModel;

    show: boolean;
    classCalculate: {};
    vspCalculate: {};

    ngOnChanges() {
        setTimeout(() => {
            this.show = true;
            this.vspCalculate = {show: this.show, ['percent-'+ this.item.vspBonus]: true}
            this.classCalculate = {show: this.show, ['percent-'+ this.item.calculatePercent]: true}
        }, 1000)
    }
}
