<dx-text class="page__heading" type="h2">Sparplan</dx-text>
<dx-card>
    <dx-card-header
        [label]="'Früher an später denken!'"></dx-card-header>
    <dx-card-content>
        <dx-grid mq1="12/*" mq3="6-6" mq4="8-4">
            <div class="output-container">
                <div #outputStackContainer class="output-stack-container">
                    <div class="output-stack">
                        <div class="bill" *ngIf="counterArrayGreenStack.length === 0; else moneyStack1"
                             [ngStyle]="stackPositionAtIndex(0)">
                            <img src="assets/svg/sparplan/ScheinGrey.svg" alt="" />
                        </div>
                        <ng-template #moneyStack1>
                            <div class="bill" *ngFor="let item of counterArrayGreenStack; let i = index"
                                 [ngStyle]="stackPositionAtIndex(i)">
                                <img src="assets/svg/sparplan/StapleSingleGreen.svg" alt="" />
                            </div>
                        </ng-template>
                        <div class="bill" [ngStyle]="topOfStackPosition(counterArrayGreenStack)">
                            <img src="assets/svg/sparplan/ScheinGreen.svg" alt="" />
                        </div>
                    </div>
                    <div class="output-stack">
                        <div class="bill" *ngIf="counterArrayBlueStack.length === 0; else moneyStack2"
                             [ngStyle]="stackPositionAtIndex(0)">
                            <img src="assets/svg/sparplan/ScheinGrey.svg" alt="" />
                        </div>
                        <ng-template #moneyStack2>
                            <div class="bill" *ngFor="let item of counterArrayBlueStack; let i = index"
                                 [ngStyle]="stackPositionAtIndex(i)">
                                <img src="assets/svg/sparplan/StapleSingleBlue.svg" alt="" />
                            </div>
                        </ng-template>
                        <div class="bill" [ngStyle]="topOfStackPosition(counterArrayBlueStack)">
                            <img src="assets/svg/sparplan/ScheinBlue.svg" alt="" />
                        </div>
                    </div>
                    <div class="output-stack">
                        <div class="bill" *ngIf="counterArrayRedStack.length === 0; else moneyStack3"
                             [ngStyle]="stackPositionAtIndex(0)">
                            <img src="assets/svg/sparplan/ScheinGrey.svg" alt="" />
                        </div>
                        <ng-template #moneyStack3>
                            <div class="bill" *ngFor="let item of counterArrayRedStack; let i = index"
                                 [ngStyle]="stackPositionAtIndex(i)">
                                <img src="assets/svg/sparplan/StapleSingleRed.svg" alt="" />
                            </div>
                        </ng-template>
                        <div class="bill" [ngStyle]="topOfStackPosition(counterArrayRedStack)">
                            <img src="assets/svg/sparplan/ScheinRed.svg" alt="" />
                        </div>
                    </div>
                </div>


                <div class="output-bottom-container">
                    <div class="output-bottom-bubble-container">
                        <div class="endkapital-container">
                            <dx-text type="ps-bold"
                                     class="bubble-text">{{ endkapital | currency: '':'':'1.0-0':'de_DE' }} €
                            </dx-text>
                        </div>
                        <div class="einzahlungen-container">
                            <dx-text type="ps-bold"
                                     class="bubble-text">{{ einzahlungen | currency: '':'':'1.0-0':'de_DE' }} €
                            </dx-text>
                        </div>
                        <div class="minus-gewinn-container">
                            <dx-text type="ps-bold"
                                     class="bubble-text">{{ entgangenerGewinn | currency: '':'':'1.0-0':'de_DE' }} €
                            </dx-text>
                        </div>
                    </div>
                    <div class="output-bottom-subtitle-container">
                        <dx-text type="ps-bold" class="subtitle-text">{{ endkapitalSubtitle }}</dx-text>
                        <dx-text type="ps-bold" class="subtitle-text">{{ einzahlungenSubtitle }}</dx-text>
                        <dx-text type="ps-bold" class="subtitle-text">{{ entgangenerGewinnSubtitle }}</dx-text>
                    </div>
                </div>
            </div>

            <div class="angaben-container">
                <dx-text type="h4">Ihre Angaben</dx-text>
                <dx-number-input
                    [id]="'sparbetrag'"
                    label="Sparbetrag pro Monat"
                    (valueChange)="setSparbetrag($event.detail)"
                    [limitlength]="6"
                    [min]="0"
                    [precision]="0"
                    profile="currency-euro"
                    placeholder="Betrag eingeben"
                ></dx-number-input>

                <dx-dropdown label="Spardauer" (valueChange)="setSpardauer($event.detail)"
                             [id]="'spardauer'"
                             placeholder="Spardauer auswählen">
                    @for (year of years; track year) {
                        <dx-dropdown-option label="{{year.value}}&nbsp;{{(year.value===1) ? 'Jahr' : 'Jahre'}}"
                                            value="{{year.value}}"></dx-dropdown-option>
                    }
                </dx-dropdown>
                <dx-slider
                    [id]="'rendite'"
                    [min]="renditeSliderMin"
                    [max]="renditeSliderMax"
                    [value]="renditeSliderCurrent"
                    [label]="'Angenommene Rendite p.a.'"
                    [step]="0.5"
                    [knoblabel]="'$1 %'"
                    (valueChange)="updateRenditeSlider($event.detail)"
                ></dx-slider>
                <dx-slider
                    #yearSlider
                    [id]="'sparbeginn'"
                    [min]="yearSliderMin"
                    [max]="yearSliderMax"
                    [value]="yearSliderCurrent"
                    [showminmaxlabel]="true"
                    [label]="'Sparbeginn'"
                    (valueChange)="updateYearSlider($event.detail)"
                    [step]="1"
                ></dx-slider>
            </div>
        </dx-grid>
    </dx-card-content>
</dx-card>
