import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-versicherte-zeit',
    templateUrl: './versicherte-zeit.component.html',
    styleUrls: ['./versicherte-zeit.component.scss']
})
export class VersicherteZeitComponent implements OnInit {
    state: VersicherteZeitState;
    showNextBtn = true;
    showPrevBtn = false;

    ngOnInit(): void {
        this.setState('gesetzlich');
    }

    nextPage() {
        this.setState('privat');
        this.showPrevBtn = true;
        this.showNextBtn = false;
    }

    prevPage() {
        this.setState('gesetzlich');
        this.showPrevBtn = false;
        this.showNextBtn = true;
    }

    private setState(newState: 'gesetzlich' | 'privat') {
        const state = new VersicherteZeitState();
        state.step = newState;
        if (newState === 'gesetzlich') {
            state.header = 'Wann sind Sie unfallversichert?';
            state.description.listHeader = 'Gesetzliche Unfallversicherung';
            state.description.list = [
                'Von 168 Wochenstunden sind nur ca. 40 Stunden versichert.',
                'Leistungsbezug erst ab 20 % Invalidität möglich.',
                'Leistung richtet sich nicht nach Ihren individuellen Bedürfnissen.',
                'Kinder sind nur im Kindergarten, in der Schule sowie auf dem direkten Weg dorthin und zurück versichert.',
                'Hausfrauen sind überhaupt nicht versichert.'
            ];
        } else if (newState === 'privat') {
            state.header = 'Absicherung rund um die Uhr?';
            state.description.listHeader = 'Private Unfallversicherung';
            state.description.list = [
                'Deckung des erhöhten Kapitalbedarfs, um den Lebensstandard zu halten.',
                `Absicherung von einmaligen Ausgaben für behindertengerechtes Wohnen oder
                für ein bedarfsgerechtes Kraftfahrzeug durch Kapitalauszahlungen aus der Invaliditätssumme.`,
                'Absicherung von Aufwendungen für kosmetische Operationen oder Krankenhausaufenthalte.',
                'Rund um die Uhr, weltweit.'
            ];
        }
        this.state = state;
    }
}

export class VersicherteZeitState {
    step: 'gesetzlich' | 'privat';
    header: string;
    description: {
        listHeader: string;
        list: string[];
    };

    constructor() {
        this.header = '';
        this.description = {
            list: [],
            listHeader: ''
        };
    }
}
