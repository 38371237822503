import { Component } from '@angular/core';
import { FipyCard, FipyModuleCategory, ModuleGroup } from '../interface';
import { StartseiteService } from '../service/startseite.service';

@Component({
    selector: 'app-fipy-modules-pyramid',
    templateUrl: './fipy-modules-pyramid.component.html',
    styleUrls: ['./fipy-modules-pyramid.component.scss']
})
export class FipyModulesPyramidComponent {
    showModulesModal = false;
    modalTitle = '';
    cards: FipyCard[] = [];
    moduleCategory: FipyModuleCategory;
    modul: ModuleGroup;

    constructor(private startseiteService: StartseiteService) {
        this.cards = this.startseiteService.cards();
    }

    showModal(modul: ModuleGroup) {
        this.showModulesModal = true;
        this.modalTitle = modul.label;
        this.moduleCategory = modul.moduleCategory;
    }

    closeModal() {
        this.showModulesModal = false;
    }

    open(category: FipyModuleCategory) {
        const modul = this.startseiteService.getModule(category)
        return this.showModal(modul)
    }
}
