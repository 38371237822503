<dx-text class="page__heading" type="h2">DAX-Anlage</dx-text>

    <dx-card [hidden]="step !== 0">
        <dx-card-header label="Investieren Sie in Ihre Wünsche"></dx-card-header>
        <dx-card-content>
            <app-savings-input (pageReady)="enableNextButton($event)"></app-savings-input>
            <app-card-footer [showPrev]="false" [disableNext]="!nextButtonEnabled" (nextPage)="step=1"></app-card-footer>
        </dx-card-content>
    </dx-card>

    <dx-card [hidden]="step !== 1">
        <dx-card-header label="Wie erfolgreich war eine Anlage im DAX?"></dx-card-header>
        <dx-card-content>
            <app-dax-saving></app-dax-saving>
            <app-card-footer [showNext]="false" [disableNext]="!nextButtonEnabled" (prevPage)="step=0"></app-card-footer>
        </dx-card-content>
    </dx-card>
