import {
    AfterViewInit,
    Component,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
    QueryList,
    ViewChild,
    ViewChildren,
    ViewEncapsulation
} from '@angular/core';
import { CostAverageCalculationsService } from '../cost-average-calculations.service';
import { auditTime, map } from 'rxjs/operators';
import { Slider } from 'primeng/slider';
import { Subscription } from 'rxjs';
import { animate, group, query, stagger, style, transition, trigger } from '@angular/animations';

const animationTime = '300ms';
const staggerTime = '400ms';

@Component({
    selector: 'app-ca-chart',
    animations: [
        trigger('chartAnim', [
            transition('void => *', [
                group([
                    query('.chart-slider', style({opacity: 0}), {optional: true}),
                    query('.chart-slider', stagger(staggerTime, [animate(animationTime, style({opacity: 1}))]), {
                        optional: true
                    }),
                    query('.axis-x-value', style({opacity: 0}), {optional: true}),
                    query('.axis-x-value', stagger(staggerTime, [animate(animationTime, style({opacity: 1}))]), {
                        optional: true
                    })
                ])
            ])
        ])
    ],
    templateUrl: './ca-chart.component.html',
    styleUrls: ['./ca-chart.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CaChartComponent implements OnInit, AfterViewInit, OnDestroy {
    @HostBinding('@chartAnim')
    @Input()
    sliderOpacity = {visibility: 'hidden'};
    stockData: number[];
    chartData;
    chartOptions;
    maxStockValue = 200;

    slider$: Subscription[];

    sharesAmount: number[] = [];

    @ViewChild('caChart') caChart;
    @ViewChildren(Slider) allSlider: QueryList<Slider>;

    constructor(public calculationService: CostAverageCalculationsService) {
    }

    async ngOnInit() {
        this.initChart();
        this.sharesAmount = this.calculationService.calculateAllShares(this.stockData);
        this.calculationService.calculateRealRendite(this.stockData);
    }

    ngOnDestroy() {
        this.slider$.forEach(slider => slider.unsubscribe());
    }

    ngAfterViewInit(): void {
        this.slider$ = this.allSlider.map(slider => {
            return slider.onChange
                .pipe(
                    map(value => this.animateChartOnSlide()),
                    auditTime(50)
                )
                .subscribe(() => this.handleSlide());
        });
    }

    private initChart() {
        this.stockData = this.calculationService.getData();
        this.maxStockValue = Math.max(...this.stockData);
        this.chartOptions = {
            animation: {
                duration: 0
            },
            scales: {
                y: {
                    beginAtZero: true,
                    ticks: {
                        stepSize: 50,
                        max: this.maxStockValue,
                        callback(value, index, values) {
                            return value + '  ';
                        }
                    }
                },
                x: {
                    grid: {
                        drawOnChartArea: false
                    }
                }
            },
            elements: {
                point: {
                    radius: 0
                },
                line: {
                    tension: 0,
                    borderWidth: 1
                }
            },
            plugins: {
                legend: {
                    display: false
                }
            }
        };

        this.chartData = {
            labels: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
            datasets: [
                {
                    label: 'Dax Kurs',
                    data: this.stockData,
                    borderColor: '#3B94A3',
                    fill: false,
                    datalabels: {
                        display: false
                    }
                }
            ]
        };
    }

    handleSlide() {
        this.sharesAmount = this.calculationService.calculateAllShares(this.stockData);
        this.calculationService.calculateRealRendite(this.stockData);
    }

    animateChartOnSlide() {
        this.caChart.refresh();
    }
}
