<dx-modal (modalClosed)="closeSearchModal()" [visible]="showSearchModal" id="module-suche" [height]="'content'"
          label="Suche">
    <div class="search-content" slot="content">
        <dx-text-input id="search-text-input" (valueChange)="search($event.detail)" label=""
                       placeholder="Suchbegriff eingeben..."
                       [value]="searchInput"></dx-text-input>
        <app-fipy-module-cards [cards]="searchCards" [searchText]="searchText"
                               [insideModal]="true"></app-fipy-module-cards>
    </div>
</dx-modal>
<dx-header class="header__bar" slot="header" hidemainnavigation="true" appname="Web-Portal" (navigateHome)="window.location.replace(window.location.origin)">
    @if (isFrontPage) {
        <div class="header__bar__search" slot="menu">
            <dx-button type="text" icon="lupe" (click)="startSearch()"></dx-button>
        </div>
    }
    <dx-header-user [name]="vbName" (signOut)="signOut()" slot="menu"></dx-header-user>
</dx-header>
