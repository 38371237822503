<dx-text class="page__heading" type="h2">Mein Gesundheitsplan</dx-text>
<dx-card>
    <dx-card-header label="Gesundheitskosten planbar machen"></dx-card-header>
    <dx-card-content>
        <dx-grid mq1="4-8/12" mq3="2-4-6">

            <div #tarifContainer class="tarifbezeichnung-container">
                <div
                    id="tarif-card"
                    class="tarif-card"
                    *ngFor="let tarif of tarifPlansToShow"
                    (click)="selectTarif(tarif)"
                    [ngClass]="{
                    tarifauswahl: tarif.rank === selectedTarif.rank,
                    hideColor: getHideDisabledColorClass(tarif.name)
                }"
                >
                    <div class="tarifbezeichunng" id="tarifbezeichnung"
                         [ngClass]="{ splitText: getSplitTextClass(tarif.name) }">
                        <p
                            [ngClass]="{
                            disabledBasisplan: getHideDisabledColorClass(tarif.name)
                        }"
                            id="{{ tarif.name }}"
                        >
                            {{ tarif.name }}
                        </p>
                    </div>
                    <div
                        class="tarif-farbe"
                        [ngClass]="{
                        'dunkelblau-80': tarif.colorGroup === 'basis',
                        'petrol-60': tarif.colorGroup === 'zahnschutz',
                        gelb: tarif.colorGroup === 'ambulant',
                        'gruen-60': tarif.colorGroup === 'station'
                    }"
                    ></div>
                </div>
            </div>
            <app-detailsinfo-rechnenbeispiel
                *ngIf="selectedTarif"
                [selectedTarif]="selectedTarif"
                (addAndDeletePlan)="addAndDeleteTarifPlan(selectedTarif.group)"
                [buttonLabel]="getButtonLabel()"
                [isMehrAlsVierZaehne]="checkIfVierOderMehrZaehne()"
            ></app-detailsinfo-rechnenbeispiel>
            <div class="planzusammenstellung-container">
                <dx-text type="ps">{{ getCorrectHeadline }}</dx-text>
                <div class="new-circle">
                    <div
                        id="basis"
                        class="left-half"
                        (click)="checkIfBasicPlanCanBeAdded('basis')"
                        [ngClass]="{ disableLefthalf: !checkIfVierOderMehrZaehne() }"
                    >
                        <div class="fill" [class.basis]="selectedName && selectedName.includes('Basis')">
                            {{ selectedName }}
                        </div>
                        <div class="fill" [class.zahn]="selectedName && selectedName.includes('Zahn')">
                            {{ selectedName }}
                        </div>
                        <div class="fill" [class.junior]="selectedName && selectedName.includes('Junior')">
                            {{ selectedName }}
                        </div>
                    </div>
                    <div class="right-half">
                        <div id="ambulant" #ambulant class="top" (click)="addAndDeleteTarifPlan(ambulant.id)">
                            <div class="fill" [class.ambulant]="gesundheitsdaten?.hatPlanAmbulant">Plan Ambulant
                            </div>
                        </div>
                        <div id="stationaer" #stationaer class="bottom"
                             (click)="addAndDeleteTarifPlan(stationaer.id)">
                            <div class="fill" [class.stationaer]="gesundheitsdaten?.hatPlanStationaer">Plan
                                Stationär
                            </div>
                        </div>
                    </div>
                </div>
                <p class="plan-hinzufuegen-text">{{ text }}</p>
                <div class="overlay-container-aligned">
                    <dx-button type="secondary-m" *ngIf="isAgeBelow49" [label]="'Ihr VIP Ticket'"
                               (click)="openVIPTicketInfo()"></dx-button>
                    <dx-button type="secondary-m" *ngIf="gesundheitsdaten?.hatPlanAmbulant" [label]="'Telemedizin'"
                               (click)="openTelemedizin()"></dx-button>
                    <dx-button type="secondary-m" *ngIf="this.showPlanforAdult" [label]="'Beispiel Zahnersatz'"
                               (click)="openZahnersatz()"></dx-button>
                </div>
            </div>
        </dx-grid>
        <app-card-footer [disablePrev]="!showPrevBtn" (prevPage)="prevPage()" [disableNext]="!isAnythingSelected()"
                         (nextPage)="nextPage()"></app-card-footer>

    </dx-card-content>
</dx-card>


<dx-modal [visible]="isVIPTicketModalVisible" [height]="'content'" [width]="'xl'"
          (modalClosed)="isVIPTicketModalVisible = false"
          [label]="'Ihr VIP-Ticket - auch morgen noch flexibel für höheren Gesundheitsschutz'">
    <img class="vip-ticket-overlay-image" slot="content" alt=""
         src="assets/png/gesundheitsplaner/Overlay_VIP_ticket.png" />
</dx-modal>
<dx-modal [visible]="isTelemedizinModalVisible" [label]="'Die Telemedizin-App'" [width]="'xl'" height="content"
          (modalClosed)="isTelemedizinModalVisible = false">
    <img class="telemedizin-overlay-image" slot="content" alt=""
         src="assets/png/gesundheitsplaner/Overlay_Telemedizin.png" />
</dx-modal>

<dx-modal [visible]="isZahnersatzModalVisible" [label]="'Beispiel Zahnersatz'" [width]="'xl'" height="content"
          (modalClosed)="isZahnersatzModalVisible = false">
    <app-modal-zahnersatz slot="content"></app-modal-zahnersatz>
</dx-modal>
