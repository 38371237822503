import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SavingsCalculationsService } from '../savings-calculations.service';
import { DaxDataService } from '../dax-data.service';
import { ReturnRates } from '../return-rates.model';

@Component({
    selector: 'app-dax-chart',
    templateUrl: './dax-chart.component.html',
    styleUrls: ['./dax-chart.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DaxChartComponent implements OnInit {
    chartData;
    chartOptions;

    startYear = 1995;
    endYear = 2020; // actually end year + 1 for correct time frames
    yearRange = [this.startYear, this.endYear];
    private prevYearRange = [...this.yearRange];

    returnRates: ReturnRates;

    private calculationSemaphore = 0;
    private calcultionCallsSkipped = 0;

    constructor(private savingsCalculationsService: SavingsCalculationsService, private daxData: DaxDataService) {}

    ngOnInit(): void {
        this.initChart();
        this.savingsCalculationsService.returnRatesReplaySubject.subscribe(returnRates => {
            this.returnRates = returnRates;
        });
    }

    private generateLabels() {
        return this.daxData.getAllDaxData().map((data, index) => {
            if (index === 0 || (index + 1) % 12 === 1) {
                return this.getYearFromDateString(data[0]);
            } else {
                return '';
            }
        });
    }

    private getYearFromDateString(dateString: string): number {
        const dateSplit = dateString.split('-');
        return parseInt(dateSplit[0], 10);
    }

    calculateSavings() {
        this.enforceMinRange();

        /* Semaphore protected calculation of new values.
           This method is called by slider movement, so many many calls might be flooding in.
           To protect against overload we protect the actual calculation routine with a simple semaphore variable and
           a delay. And only when for a tiny moment no new call is made we actually trigger the calculation. Or
           when we skipped a certain amount of calls. This last bit takes care of making the slider seem to update
           with every movement.
        */

        this.calculationSemaphore++;
        this.delay(10).then(() => {
            this.calculationSemaphore--;
            if (this.calculationSemaphore === 0 || this.calcultionCallsSkipped >= 10) {
                this.daxData.setYearRange(this.yearRange);
                this.savingsCalculationsService.calculateSavingsAndReturns();
                this.calcultionCallsSkipped = 0;
            } else {
                this.calcultionCallsSkipped++;
            }
        });
    }

    async delay(ms: number) {
        await new Promise((resolve: any) => setTimeout(() => resolve(), ms)).then(() => {});
    }

    enforceMinRange() {
        if (this.isSlidingFromLeft()) {
            if (this.yearRange[0] === this.yearRange[1]) {
                this.yearRange[0] -= 1;
            } else {
                this.prevYearRange = [...this.yearRange];
            }
        } else {
            if (this.yearRange[0] === this.yearRange[1]) {
                this.yearRange[1] += 1;
            }
            this.prevYearRange = [...this.yearRange];
        }
    }

    private isSlidingFromLeft() {
        return this.yearRange[0] !== this.prevYearRange[0];
    }

    private initChart() {
        this.chartOptions = {
            scales: {
                y: {
                    beginAtZero: true,
                    ticks: {

                    }
                },
                x:  {
                    grid: {
                        drawOnChartArea: false
                    }
                }
            },
            elements: {
                point: {
                    radius: 0
                }
            },
            plugins: {
                legend: {
                    display: false
                }
            }
        };
        this.chartData = {
            labels: this.generateLabels(),
            datasets: [
                {
                    label: 'Dax Kurs',
                    data: this.daxData.getAllDaxData().map(data => data[1]),
                    borderColor: '#3B94A3',
                    fill: false,
                    datalabels: {
                        display: false
                    }
                }
            ]
        };
    }
}
