export interface ModuleGroup {
    moduleCategory: FipyModuleCategory
    label: string
    image?: string
}


export interface FipyCard {
    hiddenByRole: string[],
    image: string;
    title: string;
    tags: string;
    source: FipyCardSource;
    link: string;
    categories: FipyCardCategory[];
    prio?: number;
    maintained: boolean;
    deactivated: boolean;
}

export interface FipyCardCategory {
    prio: number;
    category: FipyModuleCategory;
}

export enum FipyCardSource {
    UNKNOWN,
    WEB,
    APPPORTAL,
    NICHT_MEHR_VERFUEGBAR,
    VP_DIGITAL_BERATUNGSWELT,
    BALD_IN_VP_DIGITAL,
}

export enum FipyModuleCategory {
    UNKNOWN,
    VERMOEGENSAUFBAU,
    ALTERSVERSORGUNG,
    WOHNEIGENTUM,
    VERMOEGENSABSICHERUNG,
    GESUNDHEITSVORSORGE,
    RUECKLAGEN,
}

export enum AppView {
    MOBILE,
    DESKTOP,
}
