import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import 'chartjs-plugin-datalabels';

@Component({
    selector: 'app-versicherte-aktivitaeten',
    templateUrl: './versicherte-aktivitaeten.component.html',
    styleUrls: ['./versicherte-aktivitaeten.component.scss']
})
export class VersicherteAktivitaetenComponent implements OnInit {
    currentState: State;
    prevStates: State[] = [];
    showNext = false;
    showPrev = false;
    headlineIndex = 0;
    HEADLINES = [
        'Welche Aktivitäten gehören zu Ihrem Leben?',
        'Wo schützt Sie die gesetzliche Unfallversicherung? Was meinen Sie?',
        'Nur in Ihren beruflichen Aktivitäten sind Sie gesetzlich unfallversichert!',
        'Die meisten Unfälle passieren im Haushalt!'
    ];

    // chart:
    data: any;
    options: any;

    constructor(private changeDetectorRef: ChangeDetectorRef) {
        this.initChartData();
    }

    ngOnInit(): void {
        this.currentState = State.init();
        this.headlineIndex = 0;
    }

    toggleSelection(activity: ActivityView) {
        if (this.currentState.progress !== ProgressState.Results) {
            activity.selected = !activity.selected;
            if (
                (this.currentState.progress === ProgressState.YourActivity &&
                    this.currentState.activities.find(e => e.selected === true)) ||
                this.currentState.progress === ProgressState.InsuredActivity
            ) {
                this.showNext = true;
            } else {
                this.showNext = false;
            }
        }
    }

    nextView() {
        this.prevStates.push({ ...this.currentState });
        if (this.currentState.progress === ProgressState.Results) {
            this.currentState = State.nextState(ProgressState.Chart, null);
        } else if (this.currentState.progress === ProgressState.InsuredActivity) {
            this.currentState = State.nextState(
                ProgressState.Results,
                this.mapToActivityState(new Map<string, boolean>(this.currentState.activities.map(act => [act.id, act.selected])))
            );
        } else if (this.currentState.progress === ProgressState.YourActivity) {
            const activities = this.mapToActivityState(
                new Map<string, boolean>(this.currentState.activities.filter(act => act.selected).map(act => [act.id, false]))
            );
            this.currentState = State.nextState(ProgressState.InsuredActivity, activities);
        }

        if (this.currentState.progress === ProgressState.Chart) {
            this.showNext = false;
        }
        this.headlineIndex += 1;
        this.showPrev = true;
    }

    prevView() {
        this.currentState = this.prevStates.pop();
        if (this.currentState.progress === ProgressState.InsuredActivity) {
            this.currentState.activities = this.currentState.activities.map(act => {
                act.selected = false;
                return act;
            });
        }
        if (this.currentState.progress === ProgressState.YourActivity) {
            this.showPrev = false;
        }
        this.headlineIndex -= 1;
        this.showNext = true;
    }

    private mapToActivityState(map: Map<string, boolean>): ActivitiesState {
        const obj = {};
        map.forEach((v, k) => (obj[k] = v));
        return obj as ActivitiesState;
    }

    private initChartData() {
        this.data = {
            labels: ['Freizeit', 'Verkehr', 'Sonstiges', 'Haushalt', 'Arbeit'],
            datasets: [
                {
                    data: [23, 18, 5, 30, 24],
                    backgroundColor: ['#336783', '#668DA2', '#CCD9E0', '#99B3C1', '#004165'],
                    hoverBackgroundColor: ['#336783', '#668DA2', '#CCD9E0', '#99B3C1', '#004165'],
                    datalabels: {
                        // to NOT use datalabels: display: false
                        display: true,
                        color: '#4C4C4C',
                        labels: {
                            title: {
                                font(context) {
                                    const width = context.chart.width;
                                    return { size: Math.min(width / 44, 14) };
                                }
                            }
                        },
                        anchor: 'end',
                        align(context) {
                            const index = context.dataIndex;
                            if (index === 1 && context.chart.width < 710) {
                                return 'bottom';
                            }
                            if (index === 2) {
                                return '5';
                            } else if (index === 4) {
                                return 'left';
                            } else {
                                return 'end';
                            }
                        },
                        offset(context) {
                            const index = context.dataIndex;
                            if (index === 2) {
                                return 20;
                            } else {
                                return 4;
                            }
                        },
                        clamp: true,
                        formatter(value, context) {
                            const index = context.dataIndex;
                            const label = ['Freizeit', 'Verkehr', 'Sonstiges', 'Haushalt', 'Arbeit'][index];
                            if (label === 'Arbeit') {
                                return `GESETZLICH\nVERSICHERT\n${label}\n${value} %`;
                            } else {
                                return `${label}\n${value} %`;
                            }
                        }
                    }
                }
            ]
        };

        this.options = {
            tooltips: {
                enable: false,
                callbacks: {
                    label(tooltipItem, data) {
                        const index = tooltipItem.index;
                        return `${data.labels[index]}: ${data.datasets[0].data[index]} %`;
                    }
                }
            },
            plugins: {
                legend: {
                    display: false
                }
            }
        };
    }
}

export enum ProgressState {
    YourActivity,
    InsuredActivity,
    Results,
    Chart
}

export interface ActivityView {
    id: string;
    title: string;
    icon: string;
    selected: boolean;
    show: boolean;
    insured: boolean;
}

export class ActivityViewFactory {
    static create(activity: string, selected: boolean) {
        switch (activity) {
            case 'garden':
                return this.createActivityView(
                    activity,
                    'Gartenarbeit',
                    'assets/svg/versicherte-aktivitaeten/Icon Garten.svg',
                    selected,
                    false
                );
            case 'goingOut':
                return this.createActivityView(
                    activity,
                    'Ausgehen',
                    'assets/svg/versicherte-aktivitaeten/Icon Ausgehen.svg',
                    selected,
                    false
                );
            case 'shopping':
                return this.createActivityView(
                    activity,
                    'Einkaufen',
                    'assets/svg/versicherte-aktivitaeten/Icon Einkaufen.svg',
                    selected,
                    false
                );
            case 'housekeeping':
                return this.createActivityView(
                    activity,
                    'Hausarbeiten',
                    'assets/svg/versicherte-aktivitaeten/Icon Hausarbeiten.svg',
                    selected,
                    false
                );
            case 'traffic':
                return this.createActivityView(
                    activity,
                    'Straßenverkehr',
                    'assets/svg/versicherte-aktivitaeten/Icon Straßenverkehr.svg',
                    selected,
                    false
                );
            case 'commute':
                return this.createActivityView(
                    activity,
                    'Arbeitsweg',
                    'assets/svg/versicherte-aktivitaeten/Icon Arbeitsweg.svg',
                    selected,
                    true
                );
            case 'sports':
                return this.createActivityView(activity, 'Sport', 'assets/svg/versicherte-aktivitaeten/Icon Sport.svg', selected, false);
            case 'workplace':
                return this.createActivityView(
                    activity,
                    'Arbeitsplatz',
                    'assets/svg/versicherte-aktivitaeten/Icon_Arbeitsplatz.svg',
                    selected,
                    true
                );
            case 'repairing':
                return this.createActivityView(
                    activity,
                    'Reparaturen',
                    'assets/svg/versicherte-aktivitaeten/Icon Reparaturen.svg',
                    selected,
                    false
                );
            case 'vacation':
                return this.createActivityView(activity, 'Urlaub', 'assets/svg/versicherte-aktivitaeten/Icon Urlaub.svg', selected, false);
        }
    }

    private static createActivityView(
        id: string,
        title: string,
        icon: string,
        selected: boolean,
        insured: boolean,
        show: boolean = true
    ): ActivityView {
        return {
            icon,
            id,
            insured,
            selected,
            show,
            title
        } as ActivityView;
    }
}

export class ActivitiesState {
    garden?: boolean;
    goingOut?: boolean;
    shopping?: boolean;
    housekeeping?: boolean;
    traffic?: boolean;
    commute?: boolean;
    sports?: boolean;
    workplace?: boolean;
    repairing?: boolean;
    vacation?: boolean;

    constructor(activiesSelected: boolean[]) {
        this.garden = activiesSelected[0];
        this.goingOut = activiesSelected[1];
        this.shopping = activiesSelected[2];
        this.housekeeping = activiesSelected[3];
        this.traffic = activiesSelected[4];
        this.commute = activiesSelected[5];
        this.sports = activiesSelected[6];
        this.workplace = activiesSelected[7];
        this.repairing = activiesSelected[8];
        this.vacation = activiesSelected[9];
    }
}

export class State {
    public progress: ProgressState;
    public activities: ActivityView[];

    constructor(progress: ProgressState, activitiesState: ActivitiesState | boolean[]) {
        this.progress = progress;
        if (activitiesState) {
            if (Array.isArray(activitiesState)) {
                activitiesState = new ActivitiesState(activitiesState);
            }
            this.activities = Object.entries(activitiesState).map(activity => {
                return ActivityViewFactory.create(activity[0], activity[1]);
            });
        }
    }

    static init(): State {
        return new State(ProgressState.YourActivity, new Array(10).fill(false));
    }

    static nextState(newProgress: ProgressState, newActivitesState: ActivitiesState): State {
        return new State(newProgress, newActivitesState);
    }
}
