import { Component } from '@angular/core';
import { StartseiteService } from '../service/startseite.service';
import { FipyCard, ModuleGroup } from '../interface';

@Component({
    selector: 'app-fipy-modules-list',
    templateUrl: './fipy-modules-list.component.html'
})
export class FipyModulesListComponent {
    groups: ModuleGroup[];
    cards: FipyCard[];

    constructor(private startseiteService: StartseiteService) {
        this.groups = this.startseiteService.groups();
        this.cards = this.startseiteService.cards();
    }
}
