<p-slider
    class="bar-slider"
    [ngClass]="{positive: isPositive}"
    [(ngModel)]="currentValue"
    orientation="vertical"
    (onChange)="update()"
    [style]="{ 'background-color': getBackgroundColor() }"
    [step]="5"
    [max]="100"
    [disabled]="!isInteractiveMode"
></p-slider>
