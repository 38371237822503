import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DesignSystemModule } from '@dvag/design-system-angular';

@Component({
  selector: 'app-card-footer',
  standalone: true,
    imports: [
        DesignSystemModule
    ],
  templateUrl: './card-footer.component.html',
  styleUrl: './card-footer.component.scss'
})
export class CardFooterComponent {

    @Input() showPrev = true;
    @Input() showNext = true;
    @Input() disablePrev = false;
    @Input() disableNext = false;
    @Output() prevPage = new EventEmitter<never>;
    @Output() nextPage = new EventEmitter<never>;

    prev() {
        this.prevPage.emit();
    }

    next() {
        this.nextPage.emit();
    }

}
