<dx-text type='h2' class="page__heading">Willkommen zur Allfinanzberatung</dx-text>
<dx-card>
    <dx-card-header label="Welches Modul möchten Sie aufrufen?">
        <div class="actions">

            <dx-button label="Zum Leistungsradar" href="https://vb-tools.generali.de/leistungsradarweb"
                       target="_blank" iconposition="right" icon="link-extern" type="text"></dx-button>
            <dx-button type="text" [icon]="showList ? 'Beratungspyramide':'Raster'" (click)="toggle()"
                       title="Ansicht umschalten"></dx-button>
        </div>
    </dx-card-header>
    <dx-card-content>
        @if (showList) {
            <app-fipy-modules-list></app-fipy-modules-list>
        } @else {
            <app-fipy-modules-pyramid></app-fipy-modules-pyramid>
        }
    </dx-card-content>
</dx-card>
