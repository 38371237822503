import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import Keycloak, { KeycloakProfile } from 'keycloak-js';
import { environment } from '../../../environments/environment';
import { User } from '../models/User';

@Injectable({
    providedIn: 'root'
})
export class KeycloakService {
    private readonly REDIRECT_URI = window.location.href;
    public keycloakInstance: Keycloak;
    currentProfile: KeycloakProfile;

    constructor() {
        this.keycloakInstance = new Keycloak({ ...environment.keycloak, url: environment.keycloak.baseUrl });
    }

    initKeycloak(onLoadCheck?: 'login-required' | 'check-sso'): Promise<KeycloakResponse> {
        const initobj: Keycloak.KeycloakInitOptions = {
            onLoad: onLoadCheck,
            checkLoginIframe: false,
            checkLoginIframeInterval: 3,
            redirectUri: this.REDIRECT_URI
        };
        const onLoadObj = onLoadCheck ? initobj : null;
        const initPromise = this.keycloakInstance.init(onLoadObj);
        return initPromise
            .then(async (loggedInResult: boolean) => {
                console.log('KeycloakApiService initKeycloak() returns observer.next with loggedInResult', loggedInResult);
                await this.keycloakInstance.loadUserProfile();
                return {
                    loggedIn: loggedInResult,
                    idmId: this.keycloakInstance.subject
                } as KeycloakResponse;
            })
            .catch(errorData => {
                console.log('KeycloakApiService initKeycloak() returns observer.error with errorData', errorData);
                return {
                    loggedIn: false,
                    idmId: this.keycloakInstance.subject
                } as KeycloakResponse;
            });
    }

    updateToken(): Observable<string> {
        const updateToken = this.keycloakInstance.updateToken(20);
        return new Observable((observer: Observer<string>) => {
            updateToken.then(_ => {
                observer.next(this.keycloakInstance.token);
                observer.complete();
            });
            updateToken.catch(data => {
                observer.error(data);
                observer.complete();
            });
        });
    }

    isAuthenticated(): boolean {
        return this.keycloakInstance.authenticated;
    }

    getUserData(): User {
        return this.mapKeycloakProfileToUserData(this.keycloakInstance.profile);
    }

    logout(): Observable<boolean> {
        const logout = this.keycloakInstance.logout({ redirectUri: this.REDIRECT_URI });
        return new Observable((observer: Observer<boolean>) => {
            logout.then(data => console.log(data));
            logout.catch(data => observer.error(data));
        });
    }

    private mapKeycloakProfileToUserData(kcProfile: KeycloakProfile): User {
        const user = new User();
        user.vorname = kcProfile.firstName;
        user.name = kcProfile.lastName;
        user.userid = kcProfile.username;
        user.email = kcProfile.email;
        return user;
    }

    async profile(): Promise<KeycloakProfile> {
        if (this.currentProfile) {
            return this.currentProfile;
        }
        try {
            const profile = await this.keycloakInstance.loadUserProfile();
            this.currentProfile = profile;
            return profile;
        } catch (e) {
            console.warn('PROFILE LOADING FAILED', e);
        }
        return null;
    }
}

export interface KeycloakResponse {
    loggedIn: boolean;
    idmId: string;
}
