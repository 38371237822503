import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { firstValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class FitbonusService {
    data: BkkResponse;

    fitbonus: FitbonusModel = {
        plichtaktivitaeten: {
            gesundheitsCheckup: false,
            zahnvorsorge: false,
            krebsfrueherkennung: false,
            impfstatus: false
        },
        gesundheitsbewusstesVerhalten: {
            bmi: false,
            nichtraucher: false,
            sportlicheAktivitaeten: false,
            primaerPraevention: false
        },
        wahlaktivitaeten1: {
            kindervorsorgeuntersuchung: false,
            zahnvorsorgeKinder: false
        },
        wahlaktivitaeten2: {
            schwangerenvorsorge: false
        },
        hasPflichtaktivitaeten() {
            return Object.values(this.plichtaktivitaeten).filter(item => item === true).length >= 2;
        },

        hasGesundheitsbewusstesVerhalten() {
            return Object.values(this.gesundheitsbewusstesVerhalten).filter(item => item === true).length >= 2;
        },

        hasWahlAktivitaet1() {
            return Object.values(this.wahlaktivitaeten1).filter(item => item === true).length >= 2;
        },

        hasWahlAktivitaet2() {
            return this.wahlaktivitaeten2.schwangerenvorsorge;
        },
        reset() {
            for (const key in this) {
                if (key in this) {
                    for (const attr in this[key]) {
                        if (attr in this[key]) {
                            this[key][attr] = false;
                        }
                    }
                }
            }
        }
    };

    constructor(private httpClient: HttpClient) {
    }

    getFitbonus() {
        return this.fitbonus;
    }

    getFitBonusPlus(person: PersonModel): Promise<BkkResponse> {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json')
            .set(`${environment.bkk.apiKeyName}`, `${environment.bkk.apiKeyValue}`)
            .set(`${environment.bkk.apiSystemName}`, `${environment.bkk.apiSystemValue}`);

        const requestData = this.mapPersonToRequestData(person);
        return firstValueFrom(this.httpClient
            .post(`${environment.bkk.baseUrl}yambas/rest/modules/FitbonusPlus/v/${environment.bkk.apiVersion}/Dvag/spec/get/estimatedRefund`, requestData, { headers })
        ).then(response => (this.data = response));
    }

    private mapPersonToRequestData(person: PersonModel) {
        const requestData = {
            active: person.active,
            age: person.age,
            gender: person.gender,
            vitality: person.vitality
        };
        return requestData;
    }
}

export interface PersonModel {
    active?: boolean;
    age: string;
    gender: string;
    vitality?: string;
}

export interface FitbonusModel {
    plichtaktivitaeten: {
        gesundheitsCheckup: boolean;
        zahnvorsorge: boolean;
        krebsfrueherkennung: boolean;
        impfstatus: boolean;
    };
    gesundheitsbewusstesVerhalten: {
        bmi: boolean;
        nichtraucher: boolean;
        sportlicheAktivitaeten: boolean;
        primaerPraevention: boolean;
    };
    wahlaktivitaeten1: {
        kindervorsorgeuntersuchung: boolean;
        zahnvorsorgeKinder: boolean;
    };
    wahlaktivitaeten2: {
        schwangerenvorsorge: boolean;
    };

    hasPflichtaktivitaeten();

    hasGesundheitsbewusstesVerhalten();

    hasWahlAktivitaet1();

    hasWahlAktivitaet2();

    reset();
}

export interface FitBonusPlusModel {
    vitalitysilber?: boolean;
    vitalitygold?: boolean;
    vitalityplatin?: boolean;
    nichtraucher?: boolean;
    sportaktivitaten?: boolean;
    bmi?: boolean;
    jugenduntersuchung?: boolean;
    zusatzversicherung?: boolean;
    impfungen?: boolean;
    checkup35?: boolean;
    zahnvorsorge?: boolean;
    betrieblichegesundheitsforderung?: boolean;
    eigenfinanziertevorsorge?: boolean;
    pravention?: boolean;
    sportclub?: boolean;
    vitalityStatus?: string;

    reset();
}

export interface BkkResponse {
    allowedActivites?: {
        category: string;
        description: string;
        name: string;
        refund: number;
    }[],
    estimatedRefund?: {
        allowedActivitiesRefundDividedBy12: number;
        totalRefundForAllowedActivities: number;
    }[],
    optionalActivites?: {
        category: string;
        description: string;
        name: string;
        refund: number;
    }[],
    status?: number;
}
