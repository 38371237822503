import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BuComponent } from './bu.component';
import { BuSelectPeopleComponent } from './bu-select-people/bu-select-people.component';
import { SharedModule } from '../../shared/shared.module';
import { DesignSystemModule } from '@dvag/design-system-angular';
import { CardFooterComponent } from '../../layout/card-footer/card-footer.component';

@NgModule({
    declarations: [BuComponent, BuSelectPeopleComponent],
    imports: [CommonModule, SharedModule, DesignSystemModule, CardFooterComponent],
    exports: [BuSelectPeopleComponent]
})
export class BuModule {}
