<div class="headline">
    <p>Datenschutzhinweise Online-Dienste</p>
</div>
<p>
    Diese Datenschutzhinweise gelten für die Nutzung des DVAG Online-Systems, des VB-Intranets, dem VB-Shop und VB-Apps (im Folgenden
    gemeinsam als Online-Dienste bezeichnet) der
</p>

<div class="bullets">
    <p>
        Deutsche Vermögensberatung AG
    </p>
    <p>
        Allfinanz Deutsche Vermögensberatung AG
    </p>
    <p>
        Allfinanz Aktiengesellschaft DVAG
    </p>
    <p>
        Deutsche Vermögensberatung Bank Aktiengesellschaft
    </p>
</div>

<p>durch Vermögensberater der jeweiligen Gesellschaft.</p>
<div class="headline">
    <p>1. Verantwortliche Stellen und Datenschutzbeauftragter</p>
</div>
<br />
<div class="headline">
    <p>1.1. Für Vermögensberater der Deutsche Vermögensberatung AG</p>
</div>
<br />
<p>
    Deutsche Vermögensberatung Aktiengesellschaft DVAG <br />
    Wilhelm-Leuschner-Straße 24 <br />
    60329 Frankfurt am Main <br />
    Tel. Nr.: 069 23 84-0 <br />
    E-Mail: <a rel="noopener" href="mailto:info@dvag.com">info&#64;dvag.com</a>
</p>
<br />
<div class="headline">
    <p>1.2. Für Vermögensberater der Allfinanz Deutsche Vermögensberatung AG</p>
</div>
<br />
<p>
    Allfinanz Deutsche Vermögensberatung AG <br />
    Wilhelm-Leuschner-Straße 24 <br />
    60329 Frankfurt am Main <br />
    Tel. Nr.: 069 23 84-0 <br />
    E-Mail: <a rel="noopener" href="mailto:info@dvag.com">info&#64;dvag.com</a>
</p>
<br />
<div class="headline">
    <p>1.3. Für Vermögensberater der Allfinanz Aktiengesellschaft DVAG</p>
</div>
<br />
<p>
    Allfinanz Aktiengesellschaft DVAG <br />
    Windmühlstraße 14 <br />
    60329 Frankfurt am Main <br />
    Tel. Nr.: 069 23 84-0 <br />
    E-Mail: <a rel="noopener" href="mailto:info@dvag.com">info&#64;dvag.com</a>
</p>
<br />
<div class="headline">
    <p>1.4. Für Vermögensberater der Deutsche Vermögensberatung Bank Aktiengesellschaft</p>
</div>
<br />
<p>
    Deutsche Vermögensberatung Bank Aktiengesellschaft <br />
    Rotenturmstraße 16-18 <br />
    1010 Wien <br />
    Tel.-Nr.: 0043 (1) 720 50 55 – 0 <br />
    E-Mail: <a rel="noopener" href="mailto:dvag-oesterreich@dvag.com"> dvag-oesterreich&#64;dvag.com</a>
</p>

<p>
    Den Datenschutzbeauftragten der jeweiligen Gesellschaft erreichen Sie unter der jeweiligen Anschrift, mit dem Zusatz
    „Datenschutzbeauftragter“ oder unter

    <a rel="noopener" href="mailto:datenschutz@dvag.com">
        datenschutz&#64;dvag.com.
    </a>
</p>
<div class="headline">
    <p>2. Allgemeines zur Datenverarbeitung und Ihren Rechten</p>
</div>
<br />
<div class="headline">
    <p>2.1. Maßgebliche Rechtsgrundlagen der Datenverarbeitung</p>
</div>
<br />
<p>
    Sofern die Rechtsgrundlage in diesen Datenschutzhinweisen nicht ausdrücklich genannt wird sind die folgenden Rechtsgrundlagen
    maßgeblich: Soweit wir für eine Datenverarbeitung Ihre Einwilligung einholen, ist Artikel 6 Abs. 1 lit. a und Art. 7 DSGVO
    Rechtsgrundlage für die Datenverarbeitung. Erfolgt die Verarbeitung der Daten zur Erfüllung unserer Leistungen und Durchführung
    vertraglicher Maßnahmen sowie Beantwortung von Anfragen so ist Art. 6 Abs. 1 lit. b DSGVO Rechtsgrundlage der Datenverarbeitung. Dient
    die Datenverarbeitung zur Erfüllung einer rechtlichen Verpflichtung ist Artikel 6 Abs. 1 lit. c DSGVO Rechtsgrundlage. Beispiele hierfür
    sind die Erfüllung von handelsrechtlichen Aufbewahrungsfristen oder die Erfüllung steuerlicher (Aufbewahrungs-)Pflichten. Ist die
    Verarbeitung personenbezogener Daten zur Wahrung eines berechtigten Interesses unseres Unternehmens oder eines Dritten erforderlich so
    dient uns Artikel 6 Abs. 1 lit. f DSGVO als Rechtsgrundlage. Berechtigte Interessen sind insbesondere die Gewährleistung der
    IT-Sicherheit und des IT-Betriebs, die Geltendmachung rechtlicher Ansprüche und Verteidigung in Rechtstreitigkeiten sowie die Erstellung
    von Benutzerstatistiken über die Nutzung der App.
</p>
<div class="headline">
    <p>2.2. Ihre Rechte</p>
</div>
<br />
<p>
    Sie haben das Recht
</p>
<div class="bullets">
    <p>
        auf Auskunft nach Art. 15 DSGVO,
    </p>
    <p>
        auf Berichtigung nach Art. 16 DSGVO,
    </p>
    <p>
        auf Löschung nach Art. 17 DSGVO,
    </p>
    <p>
        auf Einschränkung der Verarbeitung nach Art. 18 DSG-VO
    </p>
    <p>
        auf Datenübertragung nach Art. 20 DSGVO.
    </p>
</div>

<p>
    Für die Rechte auf Auskunft und auf Löschung gelten die Einschränkungen der §§ 34 und 35 BDSG. Darüber hinaus haben Sie ein
    Beschwerderecht nach Art. 77 DSGVO bei einer Datenschutzaufsichtsbehörde gem. § 19 BDSG.
</p>
<br />
<p>
    Eine uns erteilte Einwilligung in die Verarbeitung personenbezogener Daten können Sie jederzeit uns gegenüber mit Wirkung für die
    Zukunft widerrufen.
</p>
<br />
<div class="headline">
    <p>2.3. Dauer der Speicherung</p>
</div>
<br />
<p>
    Sofern in diesen Datenschutzhinweisen nicht anders angegeben, werden personenbezogene Daten nur solange gespeichert, wie es zur
    Erfüllung des jeweiligen Zwecks oder zur Erfüllung unserer vertraglichen oder gesetzlichen Pflichten erforderlich ist. Wir unterliegen
    verschiedenen Aufbewahrungs- und Dokumentationspflichten. Diese ergeben sich insbesondere aus dem Handelsgesetzbuch, der Abgabenordnung,
    dem Geldwäschegesetz und der Verordnung über die Finanzanlagenvermittlung sowie der Versicherungsvermittlungsverordnung. Die dort
    vorgegebenen Fristen können bis zu 10 Jahren betragen.
</p>
<br />
<div class="headline">
    <p>2.4. Übermittlung von personenbezogenen Daten</p>
</div>
<br />
<p>
    Falls wir personenbezogene Daten an andere Personen oder Unternehmen übermitteln, erfolgt dies nur auf Grundlage Irel="noopener" rel="noopener" href Einwilligung,
    einer gesetzlichen Erlaubnis, aufgrund einer rechtlichen Verpflichtung (z.B. an öffentliche Stellen und Institutionen wie
    Aufsichtsbehörden oder Finanzbehörden) oder auf Grundlage einer Vereinbarung zur Auftragsverarbeitung nach Art. 28 DSGVO. Weitere
    Kategorien von Empfängern können Sie diesen Datenschutzhinweisen entnehmen (siehe dazu Ziffer 3.).
</p>
<br />
<div class="headline">
    <p>2.5. Übermittlung von Daten in Drittstaaten</p>
</div>
<br />
<p>
    Eine Verarbeitung von personenbezogenen Daten außerhalb des Europäischen Wirtschaftsraum erfolgt nur, soweit in dem Drittland ein
    angemessenes Datenschutzniveau gemäß Art. 44 ff. DSGVO durch die EU-Kommission bestätigt wurde oder andere angemessene Garantien zum
    Schutz personenbezogener Daten vorhanden sind.
</p>
<br />
<div class="headline">
    <p>2.6. Automatisierte Einzelentscheidungen</p>
</div>
<br />
<p>
    Eine automatisierte Entscheidungsfindung setzen wir in unseren Online-Diensten nicht ein.
</p>
<br />
<div class="headline">
    <p>3. Weitere Informationen zur Datenverarbeitung</p>
</div>
<br />
<div class="headline">
    <p>3.1. Cookies</p>
</div>
<br />
<p>
    Das VB-Intranet, der VB-Shop und unsere Apps nutzen Cookies. Hierbei handelt es sich um kleine Datenpakete, die auf dem Endgerät des
    Kunden gespeichert werden. Neben so genannten Session-Cookies, die automatisch gelöscht werden, sobald Sie sich abmelden oder den
    Browser schließen, werden auch so genannte permanente Cookies verwendet, die einen wiederkehrenden Nutzer erkennen. Diese Cookies
    erlöschen automatisch nach einem festen Zeitraum.
</p>
<p>
    Es ist jederzeit möglich, dass Setzen eines Cookies durch entsprechende Einstellungen im Internetbrowser zu widersprechen. Bereits
    gesetzte Cookies können Sie jederzeit löschen. Bei einer Deaktivierung von Cookies können möglicherweise nicht alle Funktionen unserer
    Webseite im vollen Umfang genutzt werden. Rechtsgrundlage für das Setzen eines Cookies ist die Wahrung der vorstehend genannten
    berechtigten Interessen gemäß Art. 6 Abs. 1 lit. f DSGVO.
</p>
<br />
<div class="headline">
    <p>3.2. Erfassung allgemeiner Daten und Erstellung von Protokolldaten</p>
</div>
<br />
<p>
    Bei Aufruf bzw. Nutzung von Online-Diensten werden automatisch allgemeine Daten und Informationen erfasst und in einem Protokoll des
    Servers gespeichert. Folgende Daten können hierbei erhoben werden:
</p>

<div class="bullets">
    <p>
        Informationen zum Browsertyp und Version
    </p>
    <p>
        Informationen zum Betriebssystem des Nutzers
    </p>
    <p>
        Informationen zum Service-Provider des Nutzers
    </p>
    <p>
        Die Internet-Protokoll-Adresse (IP-Adresse) des Nutzers oder aufrufenden Systems
    </p>
    <p>
        Datum und Uhrzeit des Zugriffs
    </p>
    <p>
        Die Seite, über die Sie zu uns gekommen sind (Referrer URL)
    </p>
    <p>
        Webseiten, die vom System des Nutzers über unsere Webseite aufgerufen werden
    </p>
    <p>
        Bei Apps zusätzlich auch Geräte-ID (UDID), Nutzer-ID, Seriennummer, App-Version, Geräte-Version, Betriebssystem und Version, Modell,
        Status, ggf. letzter Login und Sperrstatus
    </p>
</div>
<p>
    Die Verarbeitung dieser Daten dient der Bereitstellung des jeweiligen Online-Dienstes, zur Gewährleistung der Funktionsfähigkeit unserer
    Informationstechnischen Systeme und der Optimierung unserer Webseite. Diese grundsätzlich anonym erhobenen Daten und Informationen
    werden durch uns statistisch ausgewertet und genutzt, um den Datenschutz und die Datensicherheit sicherzustellen und um Missbrauchs- und
    Betrugshandlungen aufzuklären. Die Daten der Logfiles werden dabei stets getrennt von anderen gegebenenfalls erfassten personenbezogenen
    Daten gespeichert und grundsätzlich nicht an Dritte weitergegeben. Die Löschung der Daten erfolgt automatisch nach jeweiligen
    Fristablauf. Die Frist kann bis zu einem Jahr betragen. Rechtsgrundlage für die Verarbeitung der Daten ist die Wahrung der vorstehend
    genannten berechtigten Interessen gemäß Art. 6 Abs. 1 lit. f DSGVO.
</p>
<br />
<div class="headline">
    <p>3.3. Erfüllung vertraglicher Leistungen</p>
</div>
<br />
<p>
    Um unseren vertraglichen Verpflichtungen aus den mit Ihnen geschlossenen Nutzungsverträgen für unsere Online-Dienste zu erfüllen oder
    Bestellungen im VB-Shop abzuwickeln, verarbeiten wir Bestands- und Strukturdaten (Name, Adresse, VB-Nummer, Direktion, Portraitbild),
    Kontaktdaten (E-Mail-Adresse, Telefonnummer), Vertragsdaten (Bestellungen, Zahlungsinformationen, Provisionsdaten, Daten zum
    eingereichten und abgerechneten Geschäft) sowie Authentifizierungs- und Autorisierungsdaten (z.B. Benutzername, Kennwort, PIN etc.)
    gemäß Art. 6 Abs. 1 lit. b DSGVO. Die Löschung der Daten erfolgt nach Ablauf der jeweiligen gesetzlichen Aufbewahrungspflichten. Diese
    können bis zu 14 Jahre betragen.
</p>
<p>
    Nutzen Sie Online-Dienste, speichern wir bestimmte Nutzeraktionen, Datum und Uhrzeit, sowie Ihre IP-Adresse zur Fehlerbehebung sowie zum
    Schutz vor Missbrauch, Betrug und unbefugter Nutzung auf Grundlage berechtigter Interessen nach Art. 6 Abs. 1 lit. f DSGVO. Eine
    Weitergabe dieser Daten an Dritte erfolgt grundsätzlich nicht. Ausgenommen ist die Wahrung rechtlicher Ansprüche der Gesellschaft oder
    wir sind zur Herausgabe nach Art. 6 Abs. 1 lit. c DSGVO gesetzlich verpflichtet. In diesen Fällen können Daten auch an öffentliche
    Stellen weitergegeben werden. Darüber hinaus können auch andere Vermögensberater zur Wahrung irel="noopener" rel="noopener" href berechtigten Interessen nach Art. 6
    Abs. 1 lit. f. DSGVO auf bestimmte personenbezogene Daten zugreifen, z.B. zur Wahrnehmung organisatorischer Aufgaben bzw. zur Erfüllung
    rechtlicher Verpflichtungen oder Erbringung von Nachweisen gegenüber öffentlichen Stellen im Rahmen von Art. 6 Abs. 1 lit. c DSGVO.
</p>
<br />
<div class="headline">
    <p>3.4. Kommentarfunktion / Beiträge</p>
</div>
<br />
<p>
    Einzelne Online-Dienste ermöglichen Ihnen Kommentare oder Beiträge zu erstellen (z.B. IT-Forum). Hinterlassen Sie einen Kommentar oder
    Beitrag, werden Ihre IP-Adresse, Ihr Name oder Pseudonym, Datum der Erstellung sowie der von Ihnen mitgeteilte Inhalt auf Grundlage
    berechtigter Interessen nach Art. 6 Abs. 1 lit. f DSGVO gespeichert. Zugriff auf Kommentare und Beiträge ist grundsätzlich allen Nutzern
    des jeweiligen Online-Dienstes möglich (z.B. andere Vermögensberater, Mitarbeiter Innendienst). Die Speicherung der IP-Adresse erfolgt
    zur Wahrung unserer rechtlichen Ansprüche bei widerrechtlichen Inhalten. Eine Löschung von Kommentaren oder Beiträgen ist nicht
    vorgesehen. Erstellte Kommentare oder Beiträge können Sie jedoch jederzeit löschen lassen.
</p>
<br />
<div class="headline">
    <p>3.5. Kontaktaufnahme / IT-Support und Fernwartung</p>
</div>
<br />
<p>
    Nehmen Sie per Telefon, E-Mail oder Kontaktformular o.ä. Kontakt mit uns auf, z.B. weil Sie für einen Online-Dienst technische Hilfe
    benötigen oder Fragen haben, so werden die von Ihnen uns übermittelten personenbezogenen Daten automatisch gespeichert (z.B. in einem
    Ticket-System). Die Speicherung und weitere Verarbeitung dieser Daten dient der Bearbeitung Irel="noopener" rel="noopener" href Kontaktanfrage und zur anschließenden
    Kontaktaufnahme mit Ihnen. Eine Weitergabe an Dritte erfolgt grundsätzlich nicht. Ausgenommen sind Dienstleister die auf Grundlage von
    Art. 28 DSGVO für uns tätig sind (Auftragsverarbeiter). Die von Ihnen übermittelten Daten werden nach Abschluss des Vorgangs gelöscht,
    sofern der Löschung keine vertraglichen oder gesetzlichen Aufbewahrungsfristen entgegenstehen. In diesem Fall werden die
    aufbewahrungspflichtigen Daten nach Fristablauf gelöscht. In bestimmten Fällen ist es darüber hinaus gegebenenfalls erforderlich, dass
    Mitarbeiter oder Auftragsverarbeiter auf Ihr IT-System mittels Fernwartung (remote) zugreifen können. Dies erfolgt nur nach Irel="noopener" rel="noopener" href
    ausdrücklichen Freigabe und somit mit Einwilligung im Sinne von Art. 6 Abs. 1 lit. a DSGVO. Sie haben jederzeit die Möglichkeit den
    Zugriff zu beenden und sind grundsätzlich selbst für die datenschutzrechtliche Zulässigkeit der Fernwartung und den damit verbundenen
    Schutz der bei Ihnen gespeicherten Daten verantwortlich. Die Gesellschaft wird sämtliche personenbezogenen Daten, die bei der
    Fernwartung übermittelt werden, nach Beendigung der Fernwartung unverzüglich zu löschen. Ausgenommen hiervon ist die Protokollierung und
    Aufzeichnung der Fernwartung. Zweck der Protokollierung und Aufzeichnung ist es, bei Reklamationen Streitfragen zu klären und erfolgt
    somit im Rahmen von Art. 6 Abs. 1 lit. c bzw. lit. f DSGVO. Die Gesellschaften werden die Aufzeichnungen zugriffsgeschützt für 30 Tage
    speichern und anschließend löschen.
</p>
<br />
<div class="headline">
    <p>3.6. Einsatz von Google Analytics</p>
</div>
<br />
<p>
    Unsere Online-Dienste nutzen das Analysetool Google Analytics, einen Webanalysedienst von Google Inc., 1600 Amphitheatre Pkwy, Mountain
    View, CA 94043-1351, USA. Webanalyse beinhaltet die Erhebung, Sammlung und Auswertung von Informationen über das Verhalten von Nutzern
    von Online-Diensten. Beispielsweise auf welche Funktionen Sie zugegriffen haben und wie lange eine Seite betrachtet wurde. Hierfür
    werden Cookies verwendet. Cookies sind Textdateien, welche über einen Internetbrowser auf einem Computersystem abgelegt und gespeichert
    werden. Die durch das Cookie erfassten Informationen werden an einen Server von Google Inc. in den USA übermittelt. Hierzu gehört, neben
    den Informationen zur Nutzung, auch Ihre IP-Adresse. Wir setzen Google Analytics jedoch mit dem Zusatz "AnonymizeIP" ein. Das bedeutet,
    dass Ihre IP-Adresse von Google gekürzt und anonymisiert wird, wenn Sie unsere Seite innerhalb von Mitgliedstaaten der Europäischen
    Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum aufrufen. Die übermittelte IP-Adresse wird
    auch nicht mit anderen Daten von Google zusammengeführt. Zweck der Datenverarbeitung ist die Auswertung der Nutzung. In unserem Auftrag
    erstellt Google hierüber für uns Online-Reports. Die hieraus gewonnenen Informationen nutzen wir zur Optimierung unseres Webauftritts.
    Rechtsgrundlage der Datenverarbeitung ist § 15 Abs. 3 TMG bzw. Art. 6 Abs. 1 lit. f DSGVO. Die berechtigten Interessen sind die
    vorstehend genannten Zwecke. Die geltenden Datenschutzbedingungen sowie Nutzungsbedingungen von Google Analytics finden Sie unter
    <a rel="noopener" href="https://marketingplatform.google.com/about/analytics/terms/de/" target="_blank">
        https://www.google.com/analytics/terms/de.html
    </a>
    und
    <a rel="noopener" href="https://policies.google.com/?hl=de" target="_blank">
        https://policies.google.com/?hl=de.
    </a>
</p>
<p>
    Sie können die Setzung von Cookies jederzeit mittels einer entsprechenden Einstellung im Internetbrowser verhindern und damit der
    Setzung von Cookies dauerhaft widersprechen. Zudem können die von Google bereits gesetzten Cookies jederzeit über einen Internetbrowser
    oder andere Softwareprogramme gelöscht werden. Ferner haben Sie die Möglichkeit, einer Erfassung der durch das Cookie erzeugten, auf
    eine Nutzung dieser Internetseite bezogenen Daten sowie der Verarbeitung dieser Daten durch Google zu widersprechen und eine solche zu
    verhindern. Hierzu müssen Sie ein Browser Add-On herunterladen und installieren. Den Download finden Sie hier:
    <a rel="noopener" href="https://tools.google.com/dlpage/gaoptout?hl=de" target="_blank">
        https://tools.google.com/dlpage/gaoptout?hl=de.
    </a>
    Das Add-On verhindert, dass Ihre Daten zukünftig erfasst und verarbeitet werden.
</p>
<br />
<div class="headline">
    <p>3.7. Verwendung von HockeyApp</p>
</div>
<br />
<p>
    Um Fehlerberichte bei einem Absturz einer App zu erhalten, nutzen wir in einzelnen Apps das Fehleranalysetool HockeyApp der Microsoft
    Corporation, One Microsoft Way, Redmond, WA 98052-6399 USA. Im Fall eines Absturzes wird automatisch ein Fehlerbericht erstellt. Der
    Fehlerbericht enthält Informationen über den Typ und die Version des Mobilgeräts und der App, die Zeit, zu der der Fehler aufgetreten
    ist, das verwendete Feature und den Status der Anwendung, als der Fehler aufgetreten ist, die Auslastung des Arbeitsspeichers und die
    Belegung des Speicherplatzes. Die übermittelten Daten lassen keinen Rückschluss auf Ihre Person zu. Diese Fehlerberichte sind für uns
    wichtig, um Fehler in der in einer App zu beheben. Die Verarbeitung von Daten erfolgt daher um berechtigte Interessen von uns oder von
    Dritten zu wahren (Art. 6 Abs. 1 lit. f DSGVO). Sie haben jederzeit die Möglichkeit, die Protokollierung der Fehlerberichte in den
    Einstellungen zu deaktivieren. Die Datenschutzbestimmungen können Sie hier einsehen:
    <a rel="noopener" href="https://privacy.microsoft.com/de-de/privacystatement" target="_blank">
        https://www.microsoft.com/de-de/privacystatement/OnlineServices/Default.aspx.
    </a>
</p>
<br />
<div class="headline">
    <p>4. Änderungen</p>
</div>
<br />
<p>
    Wir behalten uns die Änderung dieser Datenschutzerklärung für die Zukunft vor. Die jeweils aktuelle Version kann im jeweiligen
    Online-Dienst abgerufen werden.
</p>
