import { Component } from '@angular/core';
import { FipyCard, ModuleGroup } from '../interface';
import { StartseiteService } from '../service/startseite.service';

@Component({
    selector: 'app-fipy-start-page-mobile',
    templateUrl: './fipy-start-page-mobile.component.html',
    styleUrls: ['./fipy-start-page-mobile.component.scss']
})
export class FipyStartPageMobileComponent {
    showList = false;

    showModulesModal: boolean;
    currentModule: ModuleGroup;
    cards: FipyCard[] = [];
    groups: ModuleGroup[] = [];

    showSearchModal: boolean;
    searchCards: FipyCard[] = [];
    searchText: string;

    searchInput: string;
    private timer;

    constructor(private startseiteService: StartseiteService) {
        this.groups = this.startseiteService.groups();
        this.cards = this.startseiteService.cards();
    }

    showModal(modul: ModuleGroup) {
        this.showModulesModal = true;
        this.currentModule = modul;
        this.cards = this.startseiteService.cardsByCategory(modul.moduleCategory);
    }

    search(searchText: string) {
        if (this.timer) {
            clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
            this.searchText = searchText;
            this.searchCards = this.startseiteService.searchCards(searchText);
        }, 200);
    }

    startSearch() {

        const fakeInput = document.createElement('input')
        fakeInput.setAttribute('type', 'text')
        fakeInput.style.position = 'absolute'
        fakeInput.style.opacity = '0'
        fakeInput.style.height = '0'
        fakeInput.style.fontSize = '16px' // disable auto zoom

        // you may need to append to another element depending on the browser's auto
        // zoom/scroll behavior
        document.body.prepend(fakeInput)

        // focus so that subsequent async focus will work
        fakeInput.focus()

        this.showSearchModal = true;
        this.searchCards = [];
        this.searchText = '';
        this.searchInput = '';
        setTimeout(() => {
            fakeInput.remove()
        }, 500);
    }

    closeSearchModal() {
        this.showSearchModal = false;
        this.searchCards = [];
        this.searchText = '';
        this.searchInput = '';
    }

    closeModal() {
        this.showModulesModal = false;
    }

    leistungsradarButton() {
        window.open('https://vb-tools.generali.de/leistungsradarweb', '_blank', 'noopener');
    }
}
