import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GesundheitsplanerService } from '../services/gesundheitsplaner.service';
import { KassenzettelService } from './kassenzettel.service';
import { Gesundheitsdaten } from '../gesundheitsdaten.model';

@Component({
    selector: 'app-kassenzettel',
    templateUrl: './kassenzettel.component.html',
    styleUrls: ['./kassenzettel.component.scss']
})
export class KassenzettelComponent implements OnInit {
    showNextBtn = true;
    showPrevBtn = true;

    gesundheitsdaten: Gesundheitsdaten;

    beitragsbemessungsgrenze: number;

    versorgungsluecke: number;
    maxTagessatz: number;
    restVersorgungsluecke = 0;
    nettokrankengeld: number;
    bruttokrankengeld: number;
    tagessatz = 0;
    resultTagessatz = 0;
    krankengeldInProzent: number;
    tarif: string;
    isZusatzkomponentVisible = true;
    showVaspVorteilModal = false;
    showCommonModal = false;

    constructor(
        private router: Router,
        private gesundheitsplanerService: GesundheitsplanerService,
        private kassenzettel: KassenzettelService
    ) {}

    ngOnInit(): void {
        this.fetchData();
        this.berechnungBruttoKrankengeld();
        this.berechnungNettoKrankengeld();
        this.berechnungVersorgungsluecke();
        this.berechnungTagessatz();
        this.berechnungRestVersorgungsluecke();
        this.berechnungResultTagessatz();
        this.showZusatzkomponent();
        this.showCorrectText();
        this.setTagessetz(this.maxTagessatz);
    }

    nextPage() {
        this.router.navigate(['/beratungsmodule/zusammenfassung']);
    }

    prevPage() {
        this.router.navigate(['/beratungsmodule/plaene']);
    }

    getHeightOfEKTZ(value: number) {
        let ektz = (value / this.gesundheitsdaten.monatlichesNettoEinkommen) * 250;
        if (value > 0) {
            ektz += 50;
        }
        return ektz;
    }

    setTagessetz(tagessatz: number) {
        this.tagessatz = Math.min(this.maxTagessatz, tagessatz)
        this.gesundheitsdaten.gewuenschterTagesSatz = this.tagessatz;
        this.berechnungResultTagessatz();
        this.berechnungRestVersorgungsluecke();
        this.kassenzettel.calculate(this.gesundheitsdaten);
    }

    /**
     * If the value parameter passed equals 0, then the html property
     * is not displayed in the template and gets z-index of -1;
     * @param value;
     */
    checkVisibility(value) {
        if (value === 0) {
            return -1;
        }
    }
    openInfo() {
        if (this.gesundheitsdaten.hatVaspVorteil) {
            this.showVaspVorteilModal = true;
        } else {
            this.showCommonModal = true;
        }
    }
    private berechnungBruttoKrankengeld() {
        this.beitragsbemessungsgrenze = this.gesundheitsplanerService.retrieveBeitragsbemessungsgrenze();
        if (this.gesundheitsdaten.monatlichesBruttoEinkommen >= this.beitragsbemessungsgrenze) {
            const brutto1 = 0.9 * this.gesundheitsdaten.monatlichesNettoEinkommen;
            const brutto2 = 0.7 * this.beitragsbemessungsgrenze;
            this.bruttokrankengeld = brutto1 > brutto2 ? brutto2 : brutto1;
        } else {
            const brutto1 = 0.9 * this.gesundheitsdaten.monatlichesNettoEinkommen;
            const brutto2 = 0.7 * this.gesundheitsdaten.monatlichesBruttoEinkommen;
            this.bruttokrankengeld = brutto1 > brutto2 ? brutto2 : brutto1;
        }
    }

    private berechnungNettoKrankengeld() {
        this.nettokrankengeld = this.bruttokrankengeld * 0.8785;
        this.berechnungKrankengeldInProzent();
    }

    private berechnungVersorgungsluecke() {
        this.versorgungsluecke = this.gesundheitsdaten.monatlichesNettoEinkommen - this.nettokrankengeld;
    }

    private berechnungRestVersorgungsluecke() {
        this.restVersorgungsluecke = this.versorgungsluecke - this.tagessatz * 30;
        if (this.restVersorgungsluecke < 0) {
            this.resultTagessatz = this.resultTagessatz + this.restVersorgungsluecke;
        }
    }
    private berechnungTagessatz() {
        this.maxTagessatz = Math.round(this.versorgungsluecke / 30) + 1;
        if ((this.gesundheitsdaten.alter >= 15 && this.gesundheitsdaten.alter <= 20) || this.gesundheitsdaten.istInAusbildung) {
            this.maxTagessatz = 10;
        }
    }
    private berechnungResultTagessatz() {
        this.resultTagessatz = this.tagessatz * 30;
        console.log(this.resultTagessatz);
    }

    private berechnungKrankengeldInProzent() {
        this.krankengeldInProzent = Math.floor((this.nettokrankengeld / this.gesundheitsdaten.monatlichesNettoEinkommen) * 100);
    }

    private fetchData() {
        this.gesundheitsdaten = this.gesundheitsplanerService.gesundheitsdaten;
    }

    private showZusatzkomponent() {
        if (
            this.gesundheitsdaten.alter < 15 ||
            this.gesundheitsdaten.alter >= 65 ||
            this.gesundheitsdaten.hatKeinEigenesEinkommen === true ||
            this.gesundheitsdaten.monatlichesBruttoEinkommen < 451 ||
            this.gesundheitsdaten.monatlichesNettoEinkommen === 0
        ) {
            this.isZusatzkomponentVisible = false;
        } else {
            this.isZusatzkomponentVisible = true;
        }
    }

    private showCorrectText() {
        if (this.gesundheitsdaten.hatVaspVorteil && this.gesundheitsdaten.berufsunfaehigkeitsRente) {
            this.tarif = 'KTBU';
        } else {
            this.tarif = 'EKTZ';
        }
    }
}
