import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GesundheitsplanerComponent } from './gesundheitsplaner.component';
import { BubblesComponent } from './bubbles/bubbles.component';
import { SharedModule } from '../../shared/shared.module';
import { LeistungsabbauComponent } from './leistungsabbau/leistungsabbau.component';
import { PersonenangabenComponent } from './personenangaben/personenangaben.component';
import { FormsModule } from '@angular/forms';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { InputTextModule } from 'primeng/inputtext';
import { RouterModule, Routes } from '@angular/router';
import { ZahnverlustComponent } from './zahnverlust/zahnverlust.component';
import { InformationZuGesundheitskostenComponent } from './information-zu-gesundheitskosten/information-zu-gesundheitskosten.component';
import { KinderauswahlComponent } from './kinderauswahl/kinderauswahl/kinderauswahl.component';
import { KindComponent } from './kinderauswahl/kind/kind.component';
import { PlaeneDetailsComponent } from './plaene-details/plaene-details.component';
import { ModalZahnersatzComponent } from './plaene-details/modal-zahnersatz/modal-zahnersatz.component';
import { SelectButtonModule } from 'primeng/selectbutton';
import { KassenzettelComponent } from './kassenzettel/kassenzettel.component';
import { ZusammenfassungComponent } from './zusammenfassung/zusammenfassung.component';
import { KassenzettelAuswahlComponent } from './kassenzettel/kassenzettel-auswahl/kassenzettel-auswahl.component';
import { DetailsinfoRechnenbeispielComponent } from './plaene-details/detailsinfo-rechnenbeispiel/detailsinfo-rechnenbeispiel.component';
import { DesignSystemModule } from "@dvag/design-system-angular";
import { CardFooterComponent } from '../../layout/card-footer/card-footer.component';

const routes: Routes = [
    {
        path: '',
        component: GesundheitsplanerComponent
    },
    {
        path: 'beratungsmodule/personenangaben',
        component: PersonenangabenComponent
    },
    {
        path: 'beratungsmodule/zahnverlust',
        component: ZahnverlustComponent
    },
    {
        path: 'beratungsmodule/information-zu-gesundheitskosten',
        component: InformationZuGesundheitskostenComponent
    },
    {
        path: 'beratungsmodule/kinderauswahl',
        component: KinderauswahlComponent
    },
    {
        path: 'beratungsmodule/plaene',
        component: PlaeneDetailsComponent
    },
    {
        path: 'beratungsmodule/kassenzettel',
        component: KassenzettelComponent
    },
    {
        path: 'beratungsmodule/zusammenfassung',
        component: ZusammenfassungComponent
    }
];

@NgModule({
    declarations: [
        GesundheitsplanerComponent,
        BubblesComponent,
        PersonenangabenComponent,
        LeistungsabbauComponent,
        ZahnverlustComponent,
        InformationZuGesundheitskostenComponent,
        KinderauswahlComponent,
        KindComponent,
        PlaeneDetailsComponent,
        ModalZahnersatzComponent,
        KassenzettelComponent,
        ZusammenfassungComponent,
        KassenzettelAuswahlComponent,
        DetailsinfoRechnenbeispielComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        AutoCompleteModule,
        InputTextModule,
        RouterModule.forChild(routes),
        SelectButtonModule,
        DesignSystemModule,
        CardFooterComponent
    ]
})
export class GesundheitsplanerModule {}
