<dx-text class="page__heading" type="h2">Cost-Average-Effekt</dx-text>
<dx-card>
    <dx-card-header label="Wie verhält sich ein monatl. Sparplan gegenüber einem Sparbuch?"></dx-card-header>
    <dx-card-content>
        <app-ca-chart [sliderOpacity]="step===1 ? {visibility: 'visible'}:{visibility: 'hidden'}"></app-ca-chart>


        <dx-grid mq1="12/*" mq3="6-6/*" mq4="4-8/*" class="sliders">
            <dx-text type="ps">Sparbuch-Zins</dx-text>
            <dx-slider
                [min]="0"
                [max]="25"
                [value]="0"
                (valueChange)="handleInterestChange($event.detail)"
                [step]="0.25"
                [knoblabel]="'$2 %'"
                [label]="'Welchen Zins erhalten Sie auf einem Sparbuch?'"
                [readonly]="step===1"
            ></dx-slider>

            <dx-text type="ps">Geschätzte Sparplan-Rendite</dx-text>
            <dx-slider
                [min]="0"
                [max]="25"
                [value]="0"
                (valueChange)="handleRenditeChange($event.detail)"
                [step]="0.25"
                [knoblabel]="'$2 %'"
                [label]="'Wie schätzen Sie die Rendite des mtl. Sparplans mit obenstehendem Kursverlauf ein?'"
                [readonly]="step===1"
            ></dx-slider>
            @if (step === 1) {
                <dx-text type="ps">Tatsächliche
                    Sparplan-Rendite {{ (calculationService.savingsStatistics$ | async)?.return | percent: '1.2-2':'de-DE' }}
                </dx-text>
                <dx-slider
                    [min]="0"
                    [max]="25"
                    [value]="(calculationService.savingsStatistics$ | async)?.return * 100 || 0"
                    [knoblabel]="'$2 %'"
                    [readonly]="true"
                ></dx-slider>
            }
        </dx-grid>
        @if (step === 0) {
            <div class="button-container" slot="footer">
                <dx-button type="primary-m" (click)="step=1" label="Sparplan-Rendite berechnen"
                           [disabled]="!(zinsSliderCurrent&&returnRate)">
                </dx-button>
            </div>
        } @else {
            <div class="button-container" slot="footer">
                <dx-button class="button-container__back" type="secondary-m" (click)="step=0" label="Zurück">
                </dx-button>
                <dx-button type="primary-m" (click)="openExample()" label="Beispiele">
                </dx-button>
                <dx-spacer size="8h"></dx-spacer>
                <dx-button type="primary-m" (click)="openCrashSzenario()" label="Crash-Szenario">
                </dx-button>
            </div>
        }
    </dx-card-content>
</dx-card>

<dx-modal [visible]="displayModal" (modalClosed)="displayModal = false" [height]="'content'" [width]="'xxl'">
    <div slot="content" *ngIf="images">
        <dx-slide-show amount="1">
            <img class="slideshow__image" alt="" *ngFor="let image of images; let i = index" [src]="image" />
        </dx-slide-show>
    </div>
</dx-modal>

<dx-modal #modalsky [visible]="displayCrash" (modalClosed)="displayCrash = false"
          label="Kann der Cost-Average-Effekt Sie in diesem Fall retten?" [height]="'content'">
    <app-ca-crash slot="content" [isVisible]="displayCrash"></app-ca-crash>
</dx-modal>

