import { Component, ViewEncapsulation} from '@angular/core';

interface InsuranceColumn {
    name: string;
    image: string;
    enabled: boolean;
    percentage: number;
}

@Component({
    selector: 'app-persoenliche-vermoegenssicherung',
    templateUrl: './persoenliche-vermoegenssicherung.component.html',
    styleUrls: ['./persoenliche-vermoegenssicherung.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PersoenlicheVermoegenssicherungComponent {
    step = 0;
    showNextBtn = false;
    showPrevBtn = false;

    pageStructure: Array<InsuranceColumn> = [
        { name: 'Privat-Haftpflicht', image: 'PrivatHaftpflicht.svg', enabled: false, percentage: 0 },
        { name: 'Hausrat & Reise', image: 'Hausrat.svg', enabled: false, percentage: 0 },
        { name: 'Glas', image: 'Glas.svg', enabled: false, percentage: 0 },
        { name: 'Unfall', image: 'Unfall.svg', enabled: false, percentage: 0 },
        { name: 'Rechtschutz', image: 'Rechtss.svg', enabled: false, percentage: 0 },
        { name: 'KFZ', image: 'KFZ.svg', enabled: false, percentage: 0 },
        { name: 'Wohngebäude', image: 'WohnG.svg', enabled: false, percentage: 0 },
        { name: 'Gesundheit', image: 'Gesundh.svg', enabled: false, percentage: 0 }
    ];

    update(event, column) {
        column.percentage = event;
    }

    isInsuranceSelected(): boolean {
        const enabled = this.pageStructure.filter(e => e.enabled);
        return enabled.length > 0;
    }

    nextPage() {
        this.step = this.step + 1;
        this.showPrevBtn = false;
        if (this.step === 1) {
            this.showNextBtn = true;
            this.showPrevBtn = true;
        } else if (this.step === 2) {
            this.showNextBtn = false;
            this.showPrevBtn = true;
        }
    }

    isDefaultPercentageHidden(): boolean {
        return (this.isInsuranceSelected() && this.step === 0) || this.step === 1;
    }

    prevPage() {
        this.step = this.step - 1;
        if (this.step === 0) {
            this.showPrevBtn = false;
            this.showNextBtn = true;
        } else if (this.step === 1) {
            this.showPrevBtn = true;
            this.showNextBtn = true;
        } else if (this.step === 2) {
            this.showPrevBtn = true;
            this.showNextBtn = false;
        }
    }

    hideInactiveColumn(column: InsuranceColumn): boolean {
        if ((column.enabled === false && this.step === 1) || (column.enabled === false && this.step === 2)) {
            return true;
        }
    }

    isInteractive(): boolean {
        return this.step === 0;
    }

    isPositive(): boolean {
        return this.step === 2;
    }

    isNegative(): boolean {
        return this.step === 1;
    }

    percentageFor(percentage: number): string {
        if (this.step === 1) {
            return percentage - 100 + '';
        }

        return percentage + '';
    }

    selectInsurance(column: InsuranceColumn) {
        if (this.step !== 0) {
            return;
        }

        column.enabled = !column.enabled;
        this.showNextBtn = this.isInsuranceSelected();
        if (!column.enabled) {
            column.percentage = 0;
        }
    }
}
