<div class="zahnersatz-overlay">
    <div class="main-content">
        <dx-tab-bar>

            @for (slide of overlaySlides; track slide;  let  i = $index) {
                <dx-tab-page [label]="'Diagnose ' + (i+1)" [value]="i">
                    <div class="zahnersatz-box">
                        <img alt="" class="zahnersatz-image" src="{{ slide.imageUrl }}" />
                    </div>
                </dx-tab-page>
            }


        </dx-tab-bar>
    </div>
</div>
<dx-text type="its">Stand: 10/2020</dx-text>
