import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

export class SavingsStatistics {
    return: number;
    salesProceeds: number;
    profits: number;
    numberShares: number;
}

@Injectable({
    providedIn: 'root'
})
export class CostAverageCalculationsService {
    caData;
    caCrashScenarioData;
    interest;
    rendite;

    savingsStatistics$: ReplaySubject<SavingsStatistics> = new ReplaySubject<SavingsStatistics>(1);

    realTimeMode = true;

    constructor() {
        this.generateDefaultCaeData();
        this.generateDefaultSliderValues();
        this.generateDefaultCaeCrashScenarioData();
    }

    private generateDefaultCaeData() /*: DiagramData[]*/ {
        this.caData = [
            { x: 0, y: 100 },
            { x: 1, y: 150 },
            { x: 2, y: 50 },
            { x: 3, y: 150 },
            { x: 4, y: 50 },
            { x: 5, y: 150 },
            { x: 6, y: 50 },
            { x: 7, y: 150 },
            { x: 8, y: 50 },
            { x: 9, y: 150 },
            { x: 10, y: 50 },
            { x: 11, y: 100 }
        ];
        return this.caData;
    }

    private generateDefaultCaeCrashScenarioData() /*: DiagramData[]*/ {
        this.caCrashScenarioData = [
            { x: 0, y: 75 },
            { x: 1, y: 100 },
            { x: 2, y: 50 },
            { x: 3, y: 25 },
            { x: 4, y: 12.5 },
            { x: 5, y: 10 },
            { x: 6, y: 5 },
            { x: 7, y: 2 },
            { x: 8, y: 1 },
            { x: 9, y: 0.5 },
            { x: 10, y: 0.1 },
            { x: 11, y: 40 }
        ];
        return this.caCrashScenarioData;
    }

    private generateDefaultSliderValues() {
        this.interest = {
            minValue: 0,
            maxValue: 20,
            snappingsteps: 10,
            currentValue: 0
        };

        this.rendite = {
            minValue: 0,
            maxValue: 20,
            snappingsteps: 10,
            currentValue: 0
        };
    }

    public getData(): number[] {
        return this.caData.map(d => d.y);
    }

    public getCrashScenarioData(): number[] {
        return this.caCrashScenarioData.map(d => d.y);
    }

    calculateAllShares(data: number[], base: number = 100, decimals: number = 8): number[] {
        return data.map((value: number) => {
            return this.calculateShares(value, base, decimals);
        });
    }

    calculateShares(value: number, base: number = 100, decimals: number = 8): number {
        return this.round(base / value, decimals);
    }

    calculateSharesAmount(shares: Array<number>, decimals: number = 8): number {
        let amount = 0;
        shares.forEach((val: number) => {
            amount += val;
        });
        return this.round(amount, decimals);
    }

    private round(num: number, decimals: number = 8): number {
        const round = Math.pow(10, decimals);
        return Math.round(num * round) / round;
    }

    public calculateRealRendite(priceData: number[]) {
        const sharesAmounts = this.calculateSharesAmount(this.calculateAllShares(priceData));
        const cost = 1200;
        const salesProceeds = sharesAmounts * priceData[priceData.length - 1];
        const profits = salesProceeds - cost;
        const rendite = profits / cost;
        this.savingsStatistics$.next({
            return: rendite,
            salesProceeds,
            profits,
            numberShares: sharesAmounts
        } as SavingsStatistics);
    }

    public calcRenditeForEstimatedValue(investPerIndex: number, data: number[], toIndex: number): number {
        if (data[data.length - 1] === 0) {
            return -100; // if sell price 0, 100% is lost
        }
        const boughtShares = data
            .map((price: number, index: number) => {
                if (index <= toIndex) {
                    return investPerIndex / price;
                } else {
                    return 0;
                }
            })
            .reduce((pre, curr) => pre + curr);
        const renditeMoney: number = boughtShares * data[toIndex];
        const moneyInputSum = (toIndex + 1) * investPerIndex;
        const rendite = this.round(
            renditeMoney >= moneyInputSum ? (renditeMoney * 100) / moneyInputSum : ((moneyInputSum - renditeMoney) * 100) / moneyInputSum
        );
        return rendite;
    }

    public async calcNeededPriceLevel(investPerIndex: number, data: number[], forIndex: number): Promise<number> {
        let buyedShares = 0;
        data.forEach((price: number, index: number) => {
            if (index < forIndex) {
                buyedShares += investPerIndex / price;
            }
        });
        const moneyInputSum = (forIndex - 1) * investPerIndex;
        const neededPriceLevel: number = moneyInputSum / buyedShares;
        return this.round(neededPriceLevel);
    }
}
