import { Component, HostListener } from '@angular/core';
import { AppView } from '../interface';

@Component({
    selector: 'app-fipy-app-content',
    templateUrl: './fipy-app-content.component.html',
    styleUrls: ['./fipy-app-content.component.scss']
})
export class FipyAppContentComponent {
    view: AppView = AppView.DESKTOP;
    AppView = AppView;

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        const width = event.target.innerWidth;
        const view = (width < 686) ? AppView.MOBILE : AppView.DESKTOP
        if (this.view !== view) {
            this.view = view;
        }
    }

}
