<dx-text class="page__heading" type="h2">Staatliche Sparförderung</dx-text>

<dx-card>
    <dx-card-header label="Bausparen / Wertpapiersparen"></dx-card-header>
    <dx-card-content>

<div class="keep-me-for-safari">
        <div class="mainContainer">
            <!-- multi cell background boxes -->
            <div id="zuschuss-2020-box"></div>
            <div id="bausparen-box"></div>
            <div id="wertpapiersparen-box"></div>

    <!-- COLUMN col-gesetz -->
    <div class="columnTitle col-gesetz row-header">GESETZ</div>
    <div class="col-gesetz row-personkreis">Förderfähiger Personenkreis</div>
    <div class="col-gesetz row-produkt">Benötigtes Produkt</div>
    <div class="col-gesetz row-anlagebetrag">
        <small>Maximal förderfähiger Anlagebetrag pro Jahr:</small>
        <ul class="list">
            <li>Alleinstehend</li>
            <li>Verheiratet (zusammenveranlagt)</li>
        </ul>
    </div>
    <div class="col-gesetz row-zuschuss">Staatlicher Zuschuss</div>
    <div class="col-gesetz row-arbeitgeber-zuschuss">Arbeitgeberzuschuss möglich?</div>
    <div class="col-gesetz col-mindestlaufzeit row-mindestlaufzeit">Mindestlaufzeit</div>
    <div class="col-gesetz">
        <small>Einkommensgrenzen</small>
        <ul class="list">
            <li>Alleinstehend</li>
            <li>Verheiratet (zusammenveranlagt)</li>
        </ul>
    </div>


    <!-- COLUMN col-wohnungsbau -->
    <div class="columnTitle col-wohnungsbau row-header">WOHNUNGSBAUPRÄMIENGESETZ</div>
    <div class="col-wohnungsbau row-personkreis">Natürliche Personen ab 16 Jahren</div>
    <div class="col-wohnungsbau row-produkt">Bausparen</div>
    <div class="col-wohnungsbau row-anlagebetrag align-numbers">
        <small><br /></small>700,- € <br />
        1.400,- €
    </div>
    <div class="col-wohnungsbau row-zuschuss align-numbers">10 %</div>
    <div class="col-wohnungsbau row-arbeitgeber-zuschuss">Nein</div>
    <div class="col-wohnungsbau row-mindestlaufzeit">keine, aber immer wohnwirtschaftliche Verwendung</div>
    <div class="col-wohnungsbau row-einkommensgrenzen align-numbers">
        <small><br /></small>
        35.000,- € <br />
        70.000,- €
    </div>

    <!-- COLUMN col-vermoegensbildung-1 -->
    <div class="columnTitle col-vermoegensbildung-1 col-span-2 row-header">VERMÖGENSBILDUNGSGESETZ</div>
    <div class="col-vermoegensbildung-1 row-personkreis col-span-2">Arbeitnehmer und Beamte</div>
    <div class="col-vermoegensbildung-1 row-produkt">Bausparen</div>
    <div class="col-vermoegensbildung-1 row-anlagebetrag">
        <small><br /></small>470,- €
    </div>
    <div class="col-vermoegensbildung-1 row-zuschuss">9 %</div>
    <div class="col-vermoegensbildung-1 row-arbeitgeber-zuschuss">Ja</div>
    <div class="col-vermoegensbildung-1 row-mindestlaufzeit">7 Jahre</div>
    <div class="col-vermoegensbildung-1 row-einkommensgrenzen">
        <small><br /></small>
        40.000,- € <br />
        80.000,- €
    </div>
    <!-- COLUMN col-vermoegensbildung-2 -->
    <div class="col-vermoegensbildung-2 row-produkt">Wertpapier&shy;sparen</div>
    <div class="col-vermoegensbildung-2 row-anlagebetrag">
        <small><br /></small>400,- €
    </div>
    <div class="col-vermoegensbildung-2 row-zuschuss">20 %</div>
    <div class="col-vermoegensbildung-2 row-arbeitgeber-zuschuss">Ja</div>
    <div class="col-vermoegensbildung-2 row-mindestlaufzeit">7 Jahre</div>
    <div class="col-vermoegensbildung-2 row-einkommensgrenzen">
        <small><br /></small>
        40.000,- € <br />
        80.000,- €
    </div>
</div>
</div>
    </dx-card-content>
</dx-card>
