import { AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';
import * as leistungsabbauModel from 'src/app/beratungsmodule/gesundheitsplaner/json/leistungsabbau.json';

@Component({
    selector: 'app-leistungsabbau',
    templateUrl: './leistungsabbau.component.html',
    styleUrls: ['./leistungsabbau.component.scss']
})
export class LeistungsabbauComponent implements AfterViewInit {
    moduleStructure: any = (leistungsabbauModel as any).default;
    constructor(private changeDetector: ChangeDetectorRef) {}
    ngAfterViewInit(): void {
        this.changeDetector.detectChanges();
    }
    /** handles the visibility of the HTML element
     * @param: element is the HTML element which visibility needs to be handled
     * @param: index is the position of the element in the array
     */
    public getVisibility(element: HTMLElement, index: number): string {
        if (element.clientHeight < 10 || index === this.moduleStructure.length - 1) {
            return 'hidden';
        }
        return 'visible';
    }
}
