<dx-text class="page__heading" type="h2">Mein Gesundheitsplan</dx-text>
<dx-card>

    <div *ngIf="!showResult; else result">

        <dx-card-header label="Was schätzen Sie?"></dx-card-header>
        <dx-card-content>

            <div class="header-container">
                <p>Wie viele von 10 Kindern in Deutschland benötigen eine Zahnspange?</p>
            </div>

            <div class="kids-row kids-row-1">
                <app-kind
                    id="child-first-row"
                    (isChange)="activateNextButton(); checkIfChildIsSelected(i, firstRow)"
                    *ngFor="let e of [0, 0, 0, 0, 0]; let i = index"
                    [gender]="i % 2 === 0 ? Gender.BOY :Gender.GIRL"
                    [showTeeth]="firstRowArray[i]"
                ></app-kind>
            </div>
            <div class="kids-row kids-row-2">
                <app-kind
                    id="child-second-row"
                    *ngFor="let e of [0, 0, 0, 0, 0]; let i = index"
                    (isChange)="activateNextButton(); checkIfChildIsSelected(i, secondRow)"
                    [gender]="i % 2 === 0 ? Gender.BOY :Gender.GIRL"
                    [showTeeth]="secondRowArray[i]"
                ></app-kind>
            </div>
            <app-card-footer [disablePrev]="!showPrevBtn" (prevPage)="prevPage()" [disableNext]="!showNextBtn"
                             (nextPage)="nextPage()"></app-card-footer>

        </dx-card-content>
    </div>

</dx-card>

<ng-template #result>
    <dx-card-header label="Was schätzen Sie?"></dx-card-header>
    <dx-card-content>
        <div class="header-container">
            <h2>Jedes zweite Kind in Deutschland trägt eine Zahnspange</h2>
            <p>Krankenkassen übernehmen oft nicht die kompletten Kosten</p>
            <p id="bold">5 von 10</p>
        </div>
        <div id="kids-row-result" class="kids-row ">
            <app-kind
                [isEditable]="false"
                [showTeeth]="i <= 3 ? true : false"
                *ngFor="let e of [0, 0, 0, 0, 0]; let i = index"
                [gender]="i % 2 === 0 ? 'boy' : 'girl'"
            ></app-kind>
        </div>
        <div class="kids-row kids-row-2">
            <app-kind
                [isEditable]="false"
                [showTeeth]="i === 2 ? true : false"
                *ngFor="let e of [0, 0, 0, 0, 0]; let i = index"
                [gender]="i % 2 === 0 ? 'girl' : 'boy'"
            ></app-kind>
        </div>
        <div class="quelle">
            Quelle: „Barmer GEK"
        </div>
        <app-card-footer [disablePrev]="!showPrevBtn" (prevPage)="prevPage()" [disableNext]="!showNextBtn"
                         (nextPage)="nextPage()"></app-card-footer>
    </dx-card-content>
</ng-template>
