<dx-text class="page__heading" type="h2">Mein Gesundheitsplan</dx-text>
<dx-card>
    <dx-card-header label="Ihr monatlicher Beitrag"></dx-card-header>
    <dx-card-content>
        <div class="main-kassenzettel-container">
            <div class="ektz-container" *ngIf="isZusatzkomponentVisible">
                <div class="square-container">
                    <div class="square">
                        <p class="headline">Nettogehalt</p>
                        <p>wird max. 6 Wochen vom Arbeitgeber ausgezahlt</p>
                    </div>
                    <div class="square square-krankengeld">
                        <p class="headline">Krankengeld</p>
                        <p>max. für 72 Wochen <br />ca. {{ krankengeldInProzent | number }}% Ihres Nettogehalts</p>
                    </div>
                </div>

                <div class="ektz">
                    <div class="bar-container">
                        <div class="bar">
                            <div
                                class="bar-item netto"
                                id="barNetto"
                                [ngStyle]="{
                            height: getHeightOfEKTZ(gesundheitsdaten.monatlichesNettoEinkommen) + 'px'
                        }"
                            >
                                <div class="label">
                                    <p id="netteinkommen">{{ gesundheitsdaten.monatlichesNettoEinkommen | number: '1.2-2' }}
                                        €</p>
                                </div>
                            </div>
                        </div>

                        <div class="ektz-text">
                            <img id="krank-person-bild" src="assets/svg/gesundheitsplaner/SickPerson.svg" alt="Kranke Person"/>
                            <p id="{{ tarif }}-text">{{ tarif }} €</p>
                        </div>

                        <div class="bar showSecondBar">
                            <div
                                class="bar-item versorungsluecke"
                                [hidden]="restVersorgungsluecke < 0"
                                id="barVersorungsluecke"
                                [ngStyle]="{
                            height: getHeightOfEKTZ(restVersorgungsluecke) + 'px',
                            'z-index': checkVisibility(restVersorgungsluecke)
                        }"
                            >
                                <div class="label">
                                    <p id="lücke">{{ restVersorgungsluecke | number: '1.2-2' }} €</p>
                                </div>
                            </div>
                            <div
                                [hidden]="tagessatz <= 0"
                                class="bar-item tagessatz"
                                id="barTagessatz"
                                [ngStyle]="{
                            height: getHeightOfEKTZ(resultTagessatz) + 'px',
                            'z-index': checkVisibility(resultTagessatz)
                        }"
                            >
                                <div class="label label-lueckenschliesser">
                                    <p id="lückenschliesser">{{ resultTagessatz | number: '1.2-2' }} €</p>
                                </div>
                            </div>
                            <div
                                class="bar-item netto"
                                id="barRestnetto"
                                [ngStyle]="{
                            height: getHeightOfEKTZ(nettokrankengeld) + 'px',
                            'z-index': checkVisibility(nettokrankengeld)
                        }"
                            >
                                <div class="label">
                                    <p id="krankengeld">{{ nettokrankengeld | number: '1.2-2' }} €</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tagessatz-feld">
                        <p>Die Versorgungslücke schließen Sie mit einem Tagessatz von {{ maxTagessatz }} € *</p>
                        <p id="gewünschter-Tagessatz-text">Ihr gewünschter Tagessatz</p>
                            <dx-number-input
                                [label]="'* Maximal berechneter Tagessatz'"
                                id="tagessatz-input"
                                [value]="tagessatz"
                                [max]="maxTagessatz"
                                [min]="0"
                                (valueChange)="setTagessetz($event.detail)"
                                profile="currency-euro"
                            ></dx-number-input>
                    </div>
                    <dx-button icon="information" iconposition="right" type="secondary-m" (click)="openInfo()"
                               label="Leistung mit ganzheitlichem Konzept"></dx-button>
                    <dx-modal width="xl" [visible]="showVaspVorteilModal" (modalClosed)="showVaspVorteilModal = false"
                              [label]="'Volle Arbeitskraftabsicherung ist möglich'" [height]="'content'">
                        <img slot="content" class="modal__image" alt=""
                             src="assets/png/gesundheitsplaner/Overlay_Absicherung.png" />
                    </dx-modal>
                    <dx-modal width="xl" [visible]="showCommonModal" [label]="'Leistung mit ganzheitlichem Konzept'"
                              [height]="'content'" (modalClosed)="showCommonModal=false">
                        <img slot="content" class="modal__image" alt=""
                             src="assets/png/gesundheitsplaner/Overlay_Leistung.png" />
                    </dx-modal>
                </div>
            </div>
            <div class="kassenzettel-content">
                <app-kassenzettel-auswahl></app-kassenzettel-auswahl>
            </div>
        </div>
        <app-card-footer [showPrev]="showPrevBtn" [showNext]="showNextBtn" (nextPage)="nextPage()"
                         (prevPage)="prevPage()"></app-card-footer>
    </dx-card-content>
</dx-card>
