import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';

interface SliderTick {
    year: number;
    width: string;
    hide: boolean;
}

@Component({
    selector: 'app-bubbles',
    templateUrl: './bubbles.component.html',
    styleUrls: ['./bubbles.component.scss']
})
export class BubblesComponent implements OnInit, AfterViewInit {
    public showNextBtn = true;
    public year = moment().year();
    public minYear = 1981;
    public maxYear = moment().year() + 2;
    public currentYear = 1981;
    public isModalVisible = false;
    public sliderTicks: SliderTick[] = [];
    public tickwidth;
    public yearsShow = [1983, 1989, 1993, 1997, 2000, 2003, 2004, 2007, moment().year()];
    @ViewChild('sliderContainer') public sliderContainer: ElementRef;
    @ViewChild('yearSlider') public yearSlider: HTMLDxSliderElement;

    constructor(private router: Router, private route: ActivatedRoute) {
    }

    public ngOnInit(): void {
        this.maxYear = moment().year() + 2;
    }

    public ngAfterViewInit() {
        this.sliderInit();
        this.yearSlider.knoblabel = ' ';
    }

    public yearChange(year: number) {
        this.currentYear = year;
        this.yearSlider.knoblabel = ' ';
    }

    // Methode um die Breite von Ticks-Container zu berechnen
    public sliderInit(): void {
        setTimeout(
            () => {
                let hide = false;
                const width = this.sliderContainer.nativeElement.getBoundingClientRect().width;
                if (!width) {
                    return;
                }
                const ticksNumber = this.maxYear - this.minYear - 1;
                this.tickwidth = (width / ticksNumber).toString() + 'px';
                for (let i = this.minYear; i <= this.minYear + ticksNumber; i++) {
                    if (this.yearsShow.includes(i)) {
                        hide = false;
                        this.sliderTicks.push({ year: i, width: this.tickwidth, hide });
                    } else {
                        hide = true;
                        this.sliderTicks.push({ year: i, width: this.tickwidth, hide });
                    }
                }
            }, 100);
    }

    public openLeistungsabbau() {
        this.isModalVisible = true;
    }

    nextPage() {
        this.router.navigate(['/beratungsmodule/personenangaben'], { relativeTo: this.route }).then();
    }
}
