import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GesundheitsplanerService } from '../services/gesundheitsplaner.service';
import { Gesundheitsdaten, BasisEnum } from '../gesundheitsdaten.model';
import { KassenzettelService } from '../kassenzettel/kassenzettel.service';
import { KassenzettelResponse } from '../../../backend-models/KassenzettelResponse';

@Component({
    selector: 'app-zusammenfassung',
    templateUrl: './zusammenfassung.component.html',
    styleUrls: ['./zusammenfassung.component.scss']
})
export class ZusammenfassungComponent implements OnInit, OnDestroy {
    showPrevBtn = true;

    gesundheitsdaten: Gesundheitsdaten;
    kassenzetteldaten: KassenzettelResponse;
    allowedActivitiesRefundDividedBy12 = 0.0;
    optionalActivitiesRefundDividedBy12 = 0.0;

    showPlanAmbulant = false;
    showPlanStationaer = false;
    showEKTZorKTBU = false;
    showFitBonus = false;
    showBasisschutzPlus = false;
    showMeinZahnschutz = false;
    showPlanJunior = false;
    showFourOrMoreOptions = false;
    sponsorGesucht = false;

    zahnschutzProzentValue = '';
    selectedPlanText = '';
    ektzOrKTBUText = '';
    monatlicherBeitrag = 0.0;
    monatlicherBeitragOhneSponsorBKKLinde = 0.0;
    planValue = 0.0;
    planAmbulantValue = 0.0;
    planStationaerValue = 0.0;
    fitbonusValue = 0.0;
    gesamtfitbonusValue = 0.0;

    planNames = {
        BASISSCHUTZ1: 'Basisschutz Plus Plan 1',
        BASISSCHUTZ2: 'Basisschutz Plus Plan 2',
        BASISSCHUTZ3: 'Basisschutz Plus Plan 3',
        ZAHNSCHUTZ1: 'Mein Zahnschutz Plan 1',
        ZAHNSCHUTZ2: 'Mein Zahnschutz Plan 2',
        ZAHNSCHUTZ3: 'Mein Zahnschutz Plan 3',
        JUNIOR: 'Plan Junior',
        EMPTY: 'Keine Auswahl'
    };

    constructor(
        private router: Router,
        private gesundheitsplanerService: GesundheitsplanerService,
        private kassenzettelService: KassenzettelService
    ) {}

    ngOnInit(): void {
        this.initValuesFromService();
    }

    ngOnDestroy() {
        this.resetValues();
    }

    initValuesFromService() {
        this.gesundheitsdaten = this.gesundheitsplanerService.gesundheitsdaten;
        this.kassenzetteldaten = this.kassenzettelService.daten;

        if (this.gesundheitsdaten) {
            let countSelectedOptions = 0;

            if (this.gesundheitsdaten.hatektz === true) {
                this.showEKTZorKTBU = this.gesundheitsdaten.hatektz;
                countSelectedOptions++;
            } else if (this.gesundheitsdaten.hatktbu === true) {
                this.showEKTZorKTBU = this.gesundheitsdaten.hatktbu;
                countSelectedOptions++;
            }

            if (this.gesundheitsdaten.hatektz === true) {
                this.ektzOrKTBUText = EKTZKTBUEnum.EKTZ;
            } else if (this.gesundheitsdaten.hatktbu === true) {
                this.ektzOrKTBUText = EKTZKTBUEnum.KTBU;
            }

            if (this.gesundheitsdaten.hatPlanAmbulant) {
                this.showPlanAmbulant = this.gesundheitsdaten.hatPlanAmbulant;
                countSelectedOptions++;
            }

            if (this.gesundheitsdaten.hatPlanStationaer) {
                this.showPlanStationaer = this.gesundheitsdaten.hatPlanStationaer;
                countSelectedOptions++;
            }

            if (this.gesundheitsdaten.sponsorGesucht) {
                this.sponsorGesucht = this.gesundheitsdaten.sponsorGesucht;
                countSelectedOptions++;
            }

            this.showFitBonus =
                this.gesundheitsdaten.hatPflichtAktivitaetenFitBonus ||
                this.gesundheitsdaten.hatWahlAktivitaetenKinderFitBonus ||
                this.gesundheitsdaten.hatWahlAktivitaetenSchwangerenFitBonus ||
                this.gesundheitsdaten.hatGesundheitsBewusstesVerhaltenFitBonus;

            if (this.showFitBonus) {
                countSelectedOptions++;
            }

            if (
                this.gesundheitsdaten.basisPlan === BasisEnum.BASISSCHUTZ1 ||
                this.gesundheitsdaten.basisPlan === BasisEnum.BASISSCHUTZ2 ||
                this.gesundheitsdaten.basisPlan === BasisEnum.BASISSCHUTZ3
            ) {
                this.showBasisschutzPlus = true;
                countSelectedOptions++;
            }

            if (
                this.gesundheitsdaten.basisPlan === BasisEnum.ZAHNSCHUTZ1 ||
                this.gesundheitsdaten.basisPlan === BasisEnum.ZAHNSCHUTZ2 ||
                this.gesundheitsdaten.basisPlan === BasisEnum.ZAHNSCHUTZ3
            ) {
                this.showMeinZahnschutz = true;
                countSelectedOptions++;
            }

            if (this.gesundheitsdaten.basisPlan === BasisEnum.JUNIOR) {
                this.showPlanJunior = true;
                countSelectedOptions++;
            }

            switch (this.gesundheitsdaten.basisPlan) {
                case BasisEnum.BASISSCHUTZ1:
                    this.selectedPlanText = this.planNames.BASISSCHUTZ1;
                    break;

                case BasisEnum.BASISSCHUTZ2:
                    this.selectedPlanText = this.planNames.BASISSCHUTZ2;
                    break;

                case BasisEnum.BASISSCHUTZ3:
                    this.selectedPlanText = this.planNames.BASISSCHUTZ3;
                    break;

                case BasisEnum.ZAHNSCHUTZ1:
                    this.selectedPlanText = this.planNames.ZAHNSCHUTZ1;
                    this.zahnschutzProzentValue = ZahnschutzProzentEnum.PLAN1;
                    break;

                case BasisEnum.ZAHNSCHUTZ2:
                    this.selectedPlanText = this.planNames.ZAHNSCHUTZ2;
                    this.zahnschutzProzentValue = ZahnschutzProzentEnum.PLAN2;
                    break;

                case BasisEnum.ZAHNSCHUTZ3:
                    this.selectedPlanText = this.planNames.ZAHNSCHUTZ3;
                    this.zahnschutzProzentValue = ZahnschutzProzentEnum.PLAN3;
                    break;

                case BasisEnum.JUNIOR:
                    this.selectedPlanText = this.planNames.JUNIOR;
                    break;

                default:
                    break;
            }

            if (countSelectedOptions >= 4) {
                this.showFourOrMoreOptions = true;
            }

            if (this.kassenzetteldaten) {
                this.monatlicherBeitrag = this.kassenzetteldaten.sumSelectedPlaeneValues + this.kassenzetteldaten.ektz + this.kassenzetteldaten.ktbu + this.kassenzetteldaten.gesamtZuschlag;
                this.monatlicherBeitragOhneSponsorBKKLinde =
                    this.kassenzetteldaten.sumSelectedPlaeneValues + this.kassenzetteldaten.ektz + this.kassenzetteldaten.ktbu + this.kassenzetteldaten.gesamtZuschlag;
                this.planValue =
                    this.kassenzetteldaten.selectedPlaeneAndHisValues[this.gesundheitsdaten.basisPlan] +
                    this.kassenzetteldaten.gesamtZuschlag;
                this.planAmbulantValue = this.kassenzetteldaten.selectedPlaeneAndHisValues[PlanEnum.AMBULANT];
                this.planStationaerValue = this.kassenzetteldaten.selectedPlaeneAndHisValues[PlanEnum.STATIONAER];
                this.fitbonusValue = this.kassenzetteldaten.beitragsVorteil;
                this.gesamtfitbonusValue = this.kassenzetteldaten.gesamtFitBonus;
            }
            if(this.getAllowedActivitiesRefund() !== null) {
                this.allowedActivitiesRefundDividedBy12 = parseFloat(this.getAllowedActivitiesRefund())
            }
            if(this.getOptionalActivitiesRefund() !== null) {
                this.optionalActivitiesRefundDividedBy12 = parseFloat(this.getOptionalActivitiesRefund())
            }
        }
    }

    resetValues() {
        this.showPlanAmbulant = false;
        this.showPlanStationaer = false;
        this.showEKTZorKTBU = false;
        this.showFitBonus = false;
        this.showBasisschutzPlus = false;
        this.showMeinZahnschutz = false;
        this.showPlanJunior = false;
        this.showFourOrMoreOptions = false;
        this.sponsorGesucht = false;
        this.zahnschutzProzentValue = '';
        this.selectedPlanText = '';
        this.monatlicherBeitrag = 0.0;
        this.monatlicherBeitragOhneSponsorBKKLinde = 0.0;
        this.planValue = 0.0;
        this.planAmbulantValue = 0.0;
        this.planStationaerValue = 0.0;
        this.fitbonusValue = 0.0;
        this.gesamtfitbonusValue = 0.0;
        this.removeAllowedActivitiesRefund();
        this.removeOptionalActivitiesRefund();
    }

    /**
     * Navigates to the previous page
     */
    prevPage() {
        this.router.navigate(['/beratungsmodule/kassenzettel']);
    }

    private getAllowedActivitiesRefund() {
        return localStorage.getItem('allowedActivitiesRefundDividedBy12');
    }

    private removeAllowedActivitiesRefund() {
        return localStorage.removeItem('allowedActivitiesRefundDividedBy12');
    }

    private getOptionalActivitiesRefund() {
        return localStorage.getItem('optionalActivitiesRefundDividedBy12');
    }

    private removeOptionalActivitiesRefund() {
        return localStorage.removeItem('optionalActivitiesRefundDividedBy12');
    }
}

enum PlanEnum {
    AMBULANT = 'PLANA',
    STATIONAER = 'PLANS',
    EMPTY = ''
}

enum EKTZKTBUEnum {
    EKTZ = 'EKTZ',
    KTBU = 'KTBU'
}

enum ZahnschutzProzentEnum {
    PLAN1 = '90',
    PLAN2 = '80',
    PLAN3 = '70'
}
