import {Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';
import { User } from './shared/models/User';
import { filter } from 'rxjs/operators';
import { KeycloakService } from './shared/services/keycloak.service';
import { UserDataService } from './shared/services/user-data.service';
import packageJson from './../../package.json';
import { AppInsightsService } from './shared/services/app-insights.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    @ViewChild('moreMenu') public moreMenu;

    title = 'beratungsmodule';
    version: string = packageJson.version;
    user: User;
    moreMenuOpen = false;
    displayNutzungsbedingung: boolean;
    isFrontPage = false;

    @HostListener('document:click', ['$event'])
    clickout(event) {
        // Here we check if a click needs to make the More menu disappear.
        // If More menu is open ...
        if (this.moreMenu) {
            // check if More menu is the topmost view (otherwise we ignore the click)
            const highestZIndex = this.findHighestZIndexByTagName('div');
            const moreMenuZIndexString = window.getComputedStyle(this.moreMenu.nativeElement).zIndex;
            const moreMenuZIndex = parseInt(moreMenuZIndexString, 10);

            if (moreMenuZIndex === highestZIndex) {
                // More menu is topmost view -> check if the click was inside or outside
                if (!this.moreMenu.nativeElement.contains(event.target)) {
                    // click was outside More menu -> toggle it away
                    if (this.moreMenuOpen) {
                        this.moreMenuOpen = false;
                    }
                }
            }
        }
    }

    constructor(private router: Router, private keycloakService: KeycloakService, private userDataService: UserDataService, private appInsightsService: AppInsightsService) {}

    ngOnInit() {
        this.appInsightsService.load();
        this.appInsightsService.trackEvent('App started...')
        this.user = this.getUserData();
        this.router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe(event => {
            window.scrollTo(0, 0);
        });
        this.router.events.subscribe((event) => {
            this.setIsFrontpage(event);
        })
    }

    setIsFrontpage(event) {
        if(!(event instanceof NavigationEnd)) {
            return;
        }
        if(event.url === '/') {
            this.isFrontPage = true;
        } else {
            this.isFrontPage = false;
        }
    }

    logout() {
        this.keycloakService.logout();
    }

    getUserData() {
        return this.userDataService.get();
    }

    private findHighestZIndexByTagName(elem) {
        const elements = document.getElementsByTagName(elem);
        const elementList = Array.prototype.slice.call(elements);
        let highest = 0;
        for (const element of elementList) {
            const zindex = window.getComputedStyle(element, null).zIndex;
            if (parseInt(zindex, 10) > highest && zindex !== 'auto') {
                highest = parseInt(zindex, 10);
            }
        }
        return highest;
    }
}
