<div class="text-content">
    <div class="text-wrapper" *ngFor="let leistungsabbau of moduleStructure; let i = index">
        <div class="zeitleiste">
            <div class="year-container">
                <p class="year">{{ leistungsabbau.year }}</p>
            </div>
            <div #timeline [ngStyle]="{ visibility: getVisibility(timeline, i) }" class="timeline"></div>
        </div>

        <div class="text-kommentar">
            <dx-text type="h4" class="headline" id="leistungsabbau-name + {{ i }}">{{ leistungsabbau.name }}</dx-text>
            <ul class="list-item">
                <li *ngFor="let listitem of leistungsabbau.bullets" id="leistungsabbau-listitem + {{ i }}">
                    <dx-text type="its">{{ listitem }}</dx-text>
                </li>
            </ul>
        </div>
    </div>
</div>
