<div *ngIf="item">
    <div class="chart sc-kubo-item-bar-chart">
        <div class="text">
            <dx-text type="it-bold">
                {{item.alternativeTitle ?? item.value}}
                <span *ngIf="item.additionalText">({{item.additionalText}})</span>
                <span *ngIf="item.maxRabatt" class="max-rabatt"> (max. {{item.maxRabatt}} %)</span>
            </dx-text>
        </div>
        <div class="percentage">
            <div class="flex">
                <div></div>
                <div *ngIf="item.vspBonus" [class]="vspCalculate" class="vsp-bonus percent">
                    <div class="percent-text">
                        <dx-text type="it-bold">{{item.vspBonus}} %</dx-text>
                    </div>
                </div>
                <div [class]="classCalculate" class="rabatt percent">
                    <div class="percent-text">
                        <dx-text type="it-bold">{{item.calculatePercent}} %</dx-text>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
