<dx-text class="page__heading" type="h2">Versicherte Aktivitäten</dx-text>
<dx-card>
    <dx-card-header
        [label]="HEADLINES[headlineIndex]"></dx-card-header>
    <dx-card-content>
        <div class="activity-overview" *ngIf="currentState.progress < 3">
            <div
                class="activity-tile"
                *ngFor="let activity of currentState.activities"
                [class.selected]="activity.selected"
                (click)="toggleSelection(activity)"
            >
                <div class="inner-tile">
                    <div class="img-wrapper">
                        <img [src]="activity.icon" [alt]="activity.title" />
                        <div class="strike-through"
                             [class.activated]="currentState.progress === 2 && !activity.insured"></div>
                    </div>
                    <p>{{ activity.title }}</p>
                </div>
            </div>
        </div>

        <div class="chart-wrapper" *ngIf="currentState.progress === 3">
            <div>
                <p-chart type="pie" [data]="data" [options]="options" height="65vh" width="100%"></p-chart>
            </div>
        </div>
        <app-card-footer [showPrev]="showPrev" (prevPage)="prevView()" [showNext]="showNext" (nextPage)="nextView()"></app-card-footer>

    </dx-card-content>
</dx-card>
