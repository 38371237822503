import { Component, Input, OnInit } from '@angular/core';
import { FipyCard } from '../../startseite/interface';
import { KeycloakService } from '../../shared/services/keycloak.service';
import { StartseiteService } from '../../startseite/service/startseite.service';

@Component({
    selector: ' app-fipy-app-header',
    templateUrl: './fipy-app-header.component.html',
    styleUrls: ['./fipy-app-header.component.scss']
})
export class FipyAppHeaderComponent implements OnInit {

    @Input() isFrontPage = false;
    showSearchModal: boolean;
    searchCards: FipyCard[] = [];
    searchText: string;
    vbName = '';

    searchInput: string;

    private timer;

    constructor(private keycloakService: KeycloakService, private startseiteService: StartseiteService) {
    }

    ngOnInit(): void {
        this.keycloakService.profile().then((profile)=> {
            this.vbName = profile ? `${profile.firstName} ${profile.lastName}` : '';
        });
    }

    search(searchText: string) {
        if (this.timer) {
            clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
            this.searchText = searchText;
            this.searchCards = this.startseiteService.searchCards(searchText);
        }, 200);
    }

    startSearch() {

        const fakeInput = document.createElement('input')
        fakeInput.setAttribute('type', 'text')
        fakeInput.style.position = 'absolute'
        fakeInput.style.opacity = '0'
        fakeInput.style.height = '0'
        fakeInput.style.fontSize = '16px' // disable auto zoom

        // you may need to append to another element depending on the browser's auto
        // zoom/scroll behavior
        document.body.prepend(fakeInput)

        // focus so that subsequent async focus will work
        fakeInput.focus()

        this.showSearchModal = true;
        this.searchCards = [];
        this.searchText = '';
        this.searchInput = '';
        setTimeout(() => {
            fakeInput.remove()
        }, 500);
    }

    closeSearchModal() {
        this.showSearchModal = false;
        this.searchCards = [];
        this.searchText = '';
        this.searchInput = '';
    }

    signOut() {
        this.keycloakService.logout();
    }

    protected readonly window = window;
}
