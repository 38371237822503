import {
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { scan, startWith, take, takeUntil } from 'rxjs/operators';
import { interval, Observable, Subject } from 'rxjs';

@Component({
    selector: 'app-bu-select-people',
    templateUrl: './bu-select-people.component.html',
    styleUrls: ['./bu-select-people.component.scss']
})
export class BuSelectPeopleComponent implements OnDestroy, OnInit {
    @ViewChild('gridContainer') public gridContainer;
    @Input() numberOfPersons = 0;
    @Input() editable = true;
    @Input() theme = 'blue';
    @Output() selectedPersons = new EventEmitter<number>();
    @HostBinding('class.animate') public setAnimatedClass = false;
    public personen = new Array(100).fill(false);
    public count$: Observable<number>;
    // To reset animation when moving to another slide
    private destroy$ = new Subject();

    ngOnInit() {
        this.init();
    }

    public init(): void {
        if (!this.editable) {
            this.count$ = interval(25).pipe(
                startWith(0),
                take(this.numberOfPersons),
                scan((acc, curr) => curr + 1),
                takeUntil(this.destroy$),
            );

            this.count$.pipe(takeUntil(this.destroy$)).subscribe(no => {
                this.personen.fill(true, no, no + 1);
            });
        }
    }

    public destroy(): void {
        this.personen.fill(false, 0, 100);
        this.destroy$.next(null);
    }

    /** @inheritDoc */
    public ngOnDestroy(): void {
        this.destroy$.next(null);
    }

    public selectPerson(index: number) {
        if (this.editable) {
            this.fillPersons(index + 1);
        }
    }

    public getLineClass(index: number): string {
        return 'line-' + Math.floor(index / 20);
    }

    public getContainer(): ElementRef {
        return this.gridContainer;
    }

    private fillPersons(numberPersons: number) {
        this.personen.fill(false, numberPersons, 100);
        this.personen.fill(true, 0, numberPersons);
        this.selectedPersons.emit(numberPersons);
        this.numberOfPersons = numberPersons;
    }
}
