<dx-app>
    <app-fipy-app-header slot="header" [isFrontPage]="isFrontPage"></app-fipy-app-header>
    <dx-container type="page" color="background" class="content-container">
        <app-cookie-banner></app-cookie-banner>
        <router-outlet></router-outlet>
    </dx-container>
    <dx-footer slot="footer" fromcolor="background">
        <app-fipy-app-footer></app-fipy-app-footer>
    </dx-footer>
</dx-app>

