<dx-modal [visible]="true" *ngIf="showCookieModal" [height]="'content'"
          label="Cookies">
    <div slot="content">
        <dx-text type="ps">
            Unsere Web-Applikation verwendet Cookies, um Ihnen das bestmögliche Erlebnis zu bieten. Durch die Nutzung unserer
            Web-Applikation erklären Sie sich damit einverstanden.
        </dx-text>
        <br/><br/>
        <dx-text type="ps">
            Weitere Informationen und eine Widerspruchsfunktion finden Sie unter
            <a rel="noopener" href="https://www.dvag.de/dvag/datenschutz.html" target="_blank">Datenschutz </a>
        </dx-text>
    </div>
    <div slot="primary-actions">
        <dx-button type="primary-m" [label]="'OK'" (click)="acceptCookie();"></dx-button>
    </div>
</dx-modal>
