<div class="container">
    <div class="label"><dx-text [type]="'ps'">{{ label }}</dx-text></div>
    <div class="bar">
        <div *ngIf="renditeBetrag < investmentBetrag; else positiv">
            <div class="negativ bar-item" #rendite>
                <div class="tooltip">
                    <p class="text-style">Endkapital</p>
                    <p class="text-style">{{ renditeBetrag | number: '1.0-0':'de_DE' }} €</p>
                </div>
            </div>
            <div class="investment bar-item" #investment>
                <div class="tooltip rendite">
                    <p class="text-style">Einzahlung</p>
                    <p class="text-style">{{ investmentBetrag | number: '1.0-0':'de_DE' }} €</p>
                </div>
            </div>
        </div>
        <ng-template #positiv>
            <div class="rendite bar-item" #rendite>
                <div class="tooltip rendite">
                    <p class="text-style">Endkapital</p>
                    <p class="text-style">{{ renditeBetrag | number: '1.0-0':'de_DE' }} €</p>
                </div>
            </div>
            <div class="investment bar-item" #investment>
                <div class="tooltip">
                    <p class="text-style">Einzahlung</p>
                    <p class="text-style">{{ investmentBetrag | number: '1.0-0':'de_DE' }} €</p>
                </div>
            </div>
        </ng-template>
    </div>
</div>
