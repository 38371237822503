import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { WunschbetragCalculationsService } from './wunschbetrag-calculations.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-wunschbetrag',
    templateUrl: './wunschbetrag.component.html',
    styleUrls: ['./wunschbetrag.component.scss']
})
export class WunschbetragComponent implements OnInit, AfterViewInit, OnDestroy {
    public sliderMin = 1;
    public sliderMax = 10;
    public sliderCurrent = 1;

    /** Model for the button to shrink and extend */
    public bubbleSize = '244px';
    public bubbleSizePadding = '239px';
    years: number[];
    year: number;
    desiredAmount: number;
    result: number;
    private rate$: Subscription;

    animationStep = 0;

    @ViewChild('bubbleContainer') private bubbleContainer;

    constructor(private wunschbetragCalculationsService: WunschbetragCalculationsService, private cdRef: ChangeDetectorRef) {
        this.years = [];
        for (let i = 1; i <= 50; i++) {
            this.years.push(i);
        }
    }

    ngAfterViewInit(): void {
        this.calculateBubbleSize();
        this.cdRef.detectChanges();
    }

    ngOnInit(): void {
        this.rate$ = this.wunschbetragCalculationsService.rateSubject.subscribe(result => {
            this.result = result;
        });
        this.interestRateChanged(1);
    }

    ngOnDestroy(): void {
        this.rate$.unsubscribe();
        this.wunschbetragCalculationsService.init();
    }


    setDesiredAmount(amount: number) {
        if (!amount) {
            amount = 0;
        }
        this.desiredAmount = amount;
        this.desiredAmountChanged(amount);
    }


    setYear(year: any) {
        if(!year) {
            year = 0;
            this.result = 0;
        }
        this.year = year;

        if (this.year > 0) {
            this.durationInYearsChanged(this.year);
        }
    }

    /**
     * Calculates the size of the value bubble (used in the html file).
     * It is calculated by using a base size (the container height) and the current slider value.
     */
    private calculateBubbleSize() {
        if (this.bubbleContainer) {
            const baseSize = this.bubbleContainer.nativeElement.offsetHeight;
            if(baseSize < 1) {
                return;
            }
            const adjust = this.sliderCurrent * 6 * -1;
            let size = baseSize + adjust;
            if (size < 130) {
                size = 130;
            }
            this.bubbleSize = size + 'px';
            this.bubbleSizePadding = +(size-5) + 'px';
        }
    }

    /** Callback for event emitter when interest rate changes */
    public handleInterestChange(currentValue: number): void {
        this.sliderCurrent = currentValue;
        this.interestRateChanged(currentValue);
        this.calculateBubbleSize();
    }

    private interestRateChanged(rate: number) {
        if (isNaN(rate)) {
            this.result = 0;
            return;
        }

        this.wunschbetragCalculationsService.setUserInterestRate(rate);
        this.wunschbetragCalculationsService.calculateMonthlySavingsRate();
    }

    private desiredAmountChanged(amount: number) {
        this.wunschbetragCalculationsService.setUserDesiredAmount(amount);
        this.wunschbetragCalculationsService.calculateMonthlySavingsRate();
    }

    private durationInYearsChanged(duration: number) {
        this.wunschbetragCalculationsService.setUserDurationInYears(duration);
        this.wunschbetragCalculationsService.calculateMonthlySavingsRate();
    }
}
