<dx-text class="page__heading" type="h2">Mein Gesundheitsplan</dx-text>
<dx-card>
    <dx-card-header label="Gesundheitskosten sind nicht planbar"></dx-card-header>
    <dx-card-content>
        <div class="keep-me-for-safari">
        <dx-grid mq1="12/*" mq3="6-6">
            <div class="content">
                <div class="icon-content">
                    <img id="teeth-icon" src="assets/svg/gesundheitsplaner/Zahn.svg" alt="Zahn"/>
                    <img id="eye-icon" src="assets/svg/gesundheitsplaner/Auge.svg" alt="Auge"/>
                    <img id="vacation-icon" src="assets/svg/gesundheitsplaner/Urlaub.svg" alt="Urlaub"/>
                </div>
                <div class="body-content">
                    <ul>
                        <li id="text-1">
                            Allein zwei Implantate kosten ca. 5180 €
                        </li>
                        <li id="text-2">
                            64% der deutschen Bevölkerung benötigen eine Sehhilfe
                        </li>
                        <li id="text-3">
                            Jeder 20. Deutsche erkrankt oder verletzt sich im Urlaub
                        </li>
                        <li id="text-4">
                            Jeder dritte Deutsche bis 44 Jahre benötigt bereits eine Zahnkrone
                        </li>
                    </ul>
                </div>
            </div>
            <div class="bubble-container">
                <div id="bubble-large" class="bubble">
                    <p id="bubble-text">Wäre es nicht schön, diese Kosten planbar zu machen?</p>
                </div>
                <div id="bubble-middle" class="bubble-middle"></div>
                <div id="bubble-small" class="bubble-small"></div>
            </div>
        </dx-grid>
        <app-card-footer [disablePrev]="!showPrevBtn" (prevPage)="prevPage()" [disableNext]="!showNextBtn"
                         (nextPage)="nextPage()"></app-card-footer>
        </div>
    </dx-card-content>
</dx-card>

