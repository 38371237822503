import { Component, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-modal-zahnersatz',
    templateUrl: './modal-zahnersatz.component.html',
    styleUrls: ['./modal-zahnersatz.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ModalZahnersatzComponent {
    overlaySlides = [
        {
            label: 'Diagnose 1',
            value: 'diagnose1',
            imageUrl: 'assets/png/gesundheitsplaner/zahnersatz/Overlay_ZahnDiagnose1.png'
        },
        {
            label: 'Diagnose 2',
            value: 'diagnose2',
            imageUrl: 'assets/png/gesundheitsplaner/zahnersatz/Overlay_ZahnDiagnose2.png'
        },
        {
            label: 'Diagnose 3',
            value: 'diagnose3',
            imageUrl: 'assets/png/gesundheitsplaner/zahnersatz/Overlay_ZahnDiagnose3.png'
        },
        {
            label: 'Diagnose 4',
            value: 'diagnose4',
            imageUrl: 'assets/png/gesundheitsplaner/zahnersatz/Overlay_ZahnDiagnose4.png'
        },
        {
            label: 'Diagnose 5',
            value: 'diagnose5',
            imageUrl: 'assets/png/gesundheitsplaner/zahnersatz/Overlay_ZahnDiagnose5.png'
        }
    ];
    selectedSlide = this.overlaySlides[0].value;
}
