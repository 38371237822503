<dx-text class="page__heading" type="h2">Kundenbonus</dx-text>
<dx-card>
    <dx-card-header
        [label]="activeSlide === 1 ? 'So können Sie Ihre Beiträge reduzieren...' : '... und von vielen Vergünstigungen profitieren'"></dx-card-header>
    <dx-card-content>

        @if (activeSlide === 1) {
            <div class="calculator">
                <div>
                    <app-kubo-items [items]="items" [canChange]="activeSlide === 1"
                                    (itemChange)="itemsChanged($event)"></app-kubo-items>
                </div>
                <app-kubo-circle [value]="percent" [max]="25"></app-kubo-circle>
            </div>
            <app-card-footer [showPrev]="false" [disableNext]="!canGoNext" (nextPage)="goToPage(2)"></app-card-footer>
        } @else {
            <div class="calculator">
                <div>
                    <app-kubo-items [items]="items" [canChange]="activeSlide === 1"
                                    (itemChange)="itemsChanged($event)"></app-kubo-items>
                </div>
                <div class="summary">
                    <app-kubo-item-bar-chart *ngFor="let it of items; let indexOfelement=index;" [item]="it"
                                             style="--animation-order:{{indexOfelement + 1}}">
                    </app-kubo-item-bar-chart>

                    <div class="legende">
                        <div class="legende-item">
                            <div class="icon icon-hellgruen"></div>
                            <dx-text type="it">Zusätzlicher Rabatt bei VSP</dx-text>
                        </div>
                        <div class="legende-item">
                            <div class="icon icon-dunkelgruen"></div>
                            <dx-text type="it">Rabatt</dx-text>
                        </div>
                    </div>
                </div>
            </div>
            <app-card-footer [showNext]="false" (prevPage)="goToPage(1)"></app-card-footer>
        }
    </dx-card-content>
</dx-card>




