import { Component, OnDestroy, OnInit } from '@angular/core';
import { SavingsCalculationsService } from '../savings-calculations.service';
import { SavingsAmounts } from '../savings-amounts.model';

@Component({
    selector: 'app-dax-saving',
    templateUrl: './dax-saving.component.html',
    styleUrls: ['./dax-saving.component.scss']
})
export class DaxSavingComponent implements OnInit, OnDestroy {
    maxValue: number;
    amounts: SavingsAmounts;

    constructor(private savingsCalculationsService: SavingsCalculationsService) {}

    ngOnInit(): void {
        this.savingsCalculationsService.amountsReplaySubject.subscribe(amounts => {
            this.amounts = amounts;
            this.maxValue = Math.max(
                amounts.endAmountDaxOneTime || 0,
                amounts.endAmountDaxSavingsPlan || 0,
                amounts.endAmountSavingsAccount || 0,
                amounts.oneTimeSavings || 0,
                amounts.sumMonthlySavings || 0,
                amounts.sumInpaymentSavingsAccount || 0
            );
        });
    }

    ngOnDestroy(): void {
        this.savingsCalculationsService.setMonthlySavings(0);
        this.savingsCalculationsService.setInterestSavingsAccount(0);
        this.savingsCalculationsService.setOneTimeSavings(0);
    }
}
