import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
    selector: 'app-kubo-circle',
    templateUrl: './kubo-circle.component.html',
    styleUrls: ['./kubo-circle.component.scss']
})
export class KuboCircleComponent implements OnChanges {
    @Input() value: number;
    @Input() max = 100;

    radius = 30;
    stroke = 5;
    arc;

    ngOnChanges(changes: SimpleChanges) {
        this.arc = getArc(this.value, this.max, this.radius - this.stroke / 2, this.radius)
    }

}


/**
 * Generates the value for an SVG arc.
 * @param current       Current value.
 * @param total         Maximum value.
 * @param pathRadius    Radius of the SVG path.
 * @param elementRadius Radius of the SVG container.
 */
const getArc = (current: number, total: number, pathRadius: number, elementRadius: number): string => {
    const value = Math.max(0, Math.min(current || 0, total))
    const maxAngle = MAX_ANGLE
    const percentage = total === 0 ? maxAngle : (value / total) * maxAngle
    const start = polarToCartesian(elementRadius, pathRadius, percentage)
    const end = polarToCartesian(elementRadius, pathRadius, 0)
    const arcSweep = (percentage <= 180 ? 0 : 1)

    return `M ${start} A ${pathRadius} ${pathRadius} 0 ${arcSweep} 0 ${end}`
}

/**
 * Converts polar cooradinates to Cartesian.
 * @param elementRadius  Radius of the wrapper element.
 * @param pathRadius     Radius of the path being described.
 * @param angleInDegrees Degree to be converted.
 */
const polarToCartesian = (elementRadius: number, pathRadius: number, angleInDegrees: number): string => {
    const angleInRadians = (angleInDegrees - 90) * DEGREE_IN_RADIANS
    const x = elementRadius + (pathRadius * Math.cos(angleInRadians))
    const y = elementRadius + (pathRadius * Math.sin(angleInRadians))

    return x + ' ' + y
}

const MAX_ANGLE = 359.9999
const DEGREE_IN_RADIANS: number = Math.PI / 180

export const forTesting = {
    getArc, polarToCartesian, MAX_ANGLE, DEGREE_IN_RADIANS
}
