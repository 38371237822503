import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';

/**
 * This class provides a customizable slider component.
 *
 */
@Component({
    selector: 'app-slider',
    templateUrl: './slider.component.html',
    styleUrls: ['./slider.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SliderComponent implements AfterViewInit, OnChanges {
    /** Determines if current value above slider handle should be displayed. Defaults to true. */
    @Input()
    label = true;

    /** Step width of the slider. */
    @Input()
    sliderStep: number;

    /** Descriptive text below the slider. */
    @Input()
    text = '';

    /** Symbol behind the current value (can be empty). Defaults to empty string. */
    @Input()
    symbol = '';

    /** Slider width. Defaults to 100%. */
    @Input()
    width = '100%';

    /** Number of fraction digits of the current value. Defaults to 1. */
    @Input()
    fractionDigits = 1;

    /** Enables or disables the number formatter. True if the currentValue should be formatted. Otherwise, false. Defaults to false. */
    @Input()
    numberFormatterEnabled = false;

    /** Minimum value of the slider. */
    @Input()
    minValue: number;

    /** Maximum value of the slider. */
    @Input()
    maxValue: number;

    /** Current (starting) value of the slider. */
    @Input()
    currentValue: number;

    /** Sets user interaction. True if user interaction is enabled. Otherwise, false. Defaults to true. */
    @Input()
    set interactionEnabled(newValue: boolean) {
        this.userInteractionEnabled = newValue;
        this.updateCurrentValueLabel();
    }

    get interactionEnabled(): boolean {
        return this.userInteractionEnabled;
    }

    /** Current value emitted upon slider change (as number) */
    @Output()
    currentValueChange = new EventEmitter<number>();

    public percentContainerPos = '0';

    @ViewChild('slider') slider: ElementRef<HTMLDivElement>;
    private userInteractionEnabled = true;

    ngAfterViewInit(): void {
        this.updateCurrentValueLabel();
    }

    /**
     * Handler for current value changes.
     *
     * Note: The 'handleClick' function only handles direct interaction with the slider. If the 'currentValue' is set or
     * changed in code that will be caught here.
     *
     * @param changes The model changes since the last detection cycle.
     */
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.currentValue) {
            this.handleSliderChange();
        }
    }

    /**
     * Handler function for slider changes.
     *
     * Updates the slider label position and emits the new currentValue.
     */
    handleSliderChange() {
        this.updateCurrentValueLabel();
        this.currentValueChange.emit(this.currentValue);
    }

    /**
     * Updates the position of the 'currentValue label'.
     *
     * It is calculated to be positioned always above the slider handle.
     */
    updateCurrentValueLabel() {
        if (this.userInteractionEnabled) {
            const denominator = this.maxValue - this.minValue;
            const numerator = this.currentValue - this.minValue;
            const percent = (numerator / denominator) * 100;
            this.percentContainerPos = percent + '%';
        } else {
            this.percentContainerPos = '0';
        }
    }
}
