import { Component, Input } from '@angular/core';
import { FipyCard } from '../interface';

@Component({
    selector: 'app-fipy-module-cards',
    templateUrl: './fipy-module-cards.component.html',
    styleUrls: ['./fipy-module-cards.component.scss']
})
export class FipyModuleCardsComponent {
    @Input() insideModal = false;
    @Input() searchText = '';

    @Input() cards: FipyCard[];

}
