<div *ngIf="!isBoy" (click)="onShowTeeth()">
    <div class="child" *ngIf="showTeeth">
        <img src="assets/svg/gesundheitsplaner/Girl_Mouth.svg" alt="Mund"/>
    </div>
    <div class="child" *ngIf="!showTeeth">
        <img src="assets/svg/gesundheitsplaner/Girl.svg" alt="Kind"/>
    </div>
</div>

<div *ngIf="isBoy" (click)="onShowTeeth()">
    <div class="child" *ngIf="showTeeth">
        <img src="assets/svg/gesundheitsplaner/Boy_Mouth.svg" alt="Mund"/>
    </div>
    <div class="child" *ngIf="!showTeeth">
        <img src="assets/svg/gesundheitsplaner/Boy.svg" alt="Kind"/>
    </div>
</div>
