import { Injectable } from '@angular/core';
import fondsData from './referenzfonds.json';
import { ReplaySubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DaxDataService {
    selectedDaxData$ = new ReplaySubject(1);
    yearRange: number[];

    constructor() {
        this.selectedDaxData$.next(this.getAllDaxData());
    }

    setYearRange(yearRange: number[]) {
        this.yearRange = yearRange;
        this.selectedDaxData$.next(this.getSelectedDaxData());
    }

    getAllDaxData() {
        return Object.entries(fondsData.referenzfonds[0].daten).sort();
    }

    private getSelectedDaxData() {
        return this.getAllDaxData().filter(data => {
            const yearAsNumber = this.getYearFromDateString(data[0]);
            return yearAsNumber >= this.yearRange[0] && yearAsNumber < this.yearRange[1];
        });
    }

    private getYearFromDateString(dateString: string): number {
        const dateSplit = dateString.split('-');
        return parseInt(dateSplit[0], 10);
    }
}
