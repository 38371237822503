export class User {
    status: string;
    mandant: string;
    userid: string;
    email: string;
    name: string;
    anrede: string;
    vorname: string;
    premiumlizenz: boolean;
    isInnendienst: boolean;
}
