import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { GesundheitsplanerService } from '../services/gesundheitsplaner.service';
import { BasisEnum, Gesundheitsdaten } from '../gesundheitsdaten.model';
import { Tarif } from './interface/tarif';

@Component({
    selector: 'app-plaene-details',
    templateUrl: './plaene-details.component.html',
    styleUrls: ['./plaene-details.component.scss']
})
export class PlaeneDetailsComponent implements OnInit {
    isVIPTicketModalVisible = false;
    isAgeBelow49: boolean;
    isZahnersatzModalVisible = false;
    isTelemedizinModalVisible = false;
    showPrevBtn = true;
    showPlanforAdult = true;
    showVorteile = true;
    selectedTarif: Tarif;
    tarifPlansToShow: Tarif[] = [];
    gesundheitsdaten: Gesundheitsdaten;
    text = 'Gewünschten Plan hinzufügen';
    selectedName: string;
    getCorrectHeadline = 'Stellen Sie Ihren persönlichen Gesundheitsplan zusammen';
    age: number;
    private hatBasisPlan = false;
    private tarifPlans: any = {};

    @ViewChild('tarifContainer', { static: true }) tbContainer: ElementRef;

    constructor(private router: Router, private gesundheitsplanerService: GesundheitsplanerService) {
        this.age = this.gesundheitsplanerService.gesundheitsdaten.alter;
    }

    async ngOnInit() {
        this.tarifPlans = await this.gesundheitsplanerService.getPlanTarfieData();
        this.fetchPlansFromJsonFile();
        this.loadInitialPlans();
        this.fetchDataFromStore();
        this.showVIPTicket();
    }

    nextPage() {
        if (this.selectedName === undefined) {
            this.gesundheitsplanerService.gesundheitsdaten.basisPlan = 'EMPTY' as BasisEnum;
        }
        this.router.navigate(['/beratungsmodule/kassenzettel']);
    }

    /**
     * Navigates to the previous page
     */
    prevPage() {
        this.router.navigate(['/beratungsmodule/information-zu-gesundheitskosten']);
    }

    checkIfVierOderMehrZaehne(): boolean {
        if (this.gesundheitsdaten?.anzahlFehlendeZaehne === 4 || this.gesundheitsdaten?.anzahlWurzelBehandelteZaehne === 4) {
            return false;
        }
        return true;
    }

    /**
     * Saves the selected tarif in selectedTarifbezechnung variable
     * @param tarif;
     */
    selectTarif(tarif: Tarif) {
        this.selectedTarif = tarif;
    }

    /**
     * Sets label-value of button based on selected plan
     */
    getButtonLabel(): string {
        switch (this.selectedTarif?.group) {
            case 'basis':
                if (this.selectedName === this.selectedTarif.name) {
                    return 'Entfernen';
                } else {
                    return 'Hinzufügen';
                }
            case 'ambulant':
                if (this.gesundheitsdaten.hatPlanAmbulant) {
                    return 'Entfernen';
                } else {
                    return 'Hinzufügen';
                }
            case 'stationaer':
                if (this.gesundheitsdaten.hatPlanStationaer) {
                    return 'Entfernen';
                } else {
                    return 'Hinzufügen';
                }
            default:
                return 'Hinzufügen';
        }
    }

    /**
     * According to the age the plans are
     * sorted and saved in tarifPlansToShow property
     */
    fetchPlansFromJsonFile() {
        if (this.age >= 15) {
            this.showPlanforAdult = true;
            this.tarifPlansToShow = this.tarifPlans.basis.filter((plan: Tarif) => plan.age === 'adult');
        } else {
            this.showPlanforAdult = false;
            this.tarifPlansToShow = this.tarifPlans.basis.filter((plan: Tarif) => plan.age === 'junior');
        }
        this.tarifPlansToShow.push(this.tarifPlans.ambulant[0]);
        this.tarifPlansToShow.push(this.tarifPlans.stationaer[0]);
    }

    /**
     * Method adds and removes plans to circle
     * @param id value is the selected plan
     */
    addAndDeleteTarifPlan(id: string) {
        switch (id) {
            case 'basis':
                this.addBasisPlan();
                break;

            case 'ambulant':
                if (this.age > 15) {
                    this.selectedTarif = this.tarifPlansToShow[6];
                } else {
                    this.selectedTarif = this.tarifPlansToShow[1];
                }
                this.gesundheitsplanerService.gesundheitsdaten.hatPlanAmbulant = !this.gesundheitsdaten.hatPlanAmbulant;

                break;

            case 'stationaer':
                if (this.age > 15) {
                    this.selectedTarif = this.tarifPlansToShow[7];
                } else {
                    this.selectedTarif = this.tarifPlansToShow[2];
                }
                this.gesundheitsplanerService.gesundheitsdaten.hatPlanStationaer = !this.gesundheitsdaten.hatPlanStationaer;
                break;

            default:
                console.error('addAndDeleteTarifPlan not working');
                break;
        }
        this.updateHeadline();
        this.showVIPTicket();
    }

    /**
     * checks for selcted plans => method is used to show and hide the next button
     */
    isAnythingSelected(): boolean {
        if (this.gesundheitsdaten?.hatPlanAmbulant || this.gesundheitsdaten?.hatPlanStationaer || this.hatBasisPlan) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * Show VIP Ticket Infooverlay
     */
    openVIPTicketInfo() {
        this.isVIPTicketModalVisible = true;
    }

    /**
     * Show Zahnersatz Infooverlay
     */
    openZahnersatz() {
        this.isZahnersatzModalVisible = true;
    }

    /**
     * Show Telemedizin Infooverlay
     */
    openTelemedizin() {
        this.isTelemedizinModalVisible = true;
    }

    private fetchDataFromStore() {
        this.gesundheitsdaten = this.gesundheitsplanerService.gesundheitsdaten;
        const plan = this.tarifPlansToShow.find(tarifplan => tarifplan.planId === this.gesundheitsdaten?.basisPlan);
        this.selectedName = plan?.name;
        if (this.selectedName && this.selectedName !== '') {
            this.hatBasisPlan = true;
        } else {
            this.hatBasisPlan = false;
        }
    }

    private loadInitialPlans(): void {
        if (this.gesundheitsdaten?.basisPlan) {
            const filtered = this.tarifPlansToShow.filter(p => p.planId === this.gesundheitsdaten.basisPlan);
            this.selectedTarif = filtered[0];
        } else {
            if (this.age > 15) {
                this.selectedTarif = this.tarifPlansToShow[1];
            } else {
                this.selectedTarif = this.tarifPlansToShow[0];
            }
        }
        this.updateHeadline();
    }

    private updateHeadline(): void {
        if (this.isAnythingSelected()) {
            this.getCorrectHeadline = 'Ihr persönlicher Gesundheitsplan';
            this.text = '';
        } else {
            this.getCorrectHeadline = 'Stellen Sie Ihren persönlichen Gesundheitsplan zusammen';
            this.text = 'Gewünschten Plan hinzufügen';
        }
    }

    showVIPTicket() {
        if (this.age < 49) {
            if (
                this.gesundheitsdaten.basisPlan === 'PLAN1' ||
                this.gesundheitsdaten.basisPlan === 'PLAN2' ||
                this.gesundheitsdaten.hatPlanStationaer === true ||
                this.gesundheitsdaten.hatPlanAmbulant === true
            ) {
                this.isAgeBelow49 = true;
            } else {
                this.isAgeBelow49 = false;
            }
        } else {
            this.isAgeBelow49 = false;
        }
    }

    checkIfBasicPlanCanBeAdded(id: string) {
        if (this.checkIfVierOderMehrZaehne()) {
            this.addAndDeleteTarifPlan(id);
        }
    }

    private addBasisPlan() {
        switch (this.hatBasisPlan) {
            case false:
                if (this.selectedTarif.name === 'Plan Ambulant' || this.selectedTarif.name === 'Plan Stationär') {
                    if (this.age >= 15) {
                        this.selectedTarif = this.tarifPlansToShow[1];
                    } else {
                        this.selectedTarif = this.tarifPlansToShow[0];
                    }
                }
                this.selectedName = this.selectedTarif.name;
                this.gesundheitsplanerService.gesundheitsdaten.basisPlan = this.selectedTarif.planId as BasisEnum;
                this.hatBasisPlan = true;
                break;

            case true:
                if (this.selectedTarif.name === 'Plan Ambulant'
                    || this.selectedTarif.name === 'Plan Stationär'
                    || this.selectedName === this.selectedTarif.name) {
                    this.selectedName = '';
                    this.gesundheitsplanerService.gesundheitsdaten.basisPlan = 'EMPTY' as BasisEnum;
                    this.hatBasisPlan = false;
                } else {
                    this.selectedName = this.selectedTarif.name;
                    this.gesundheitsplanerService.gesundheitsdaten.basisPlan = this.selectedTarif.planId as BasisEnum;
                }
                break;
        }
    }

    getSplitTextClass(tarifName: string) {
        if ((tarifName === 'Plan Ambulant' || tarifName === 'Plan Stationär') && this.age >= 15) {
            return true;
        }
        return false;
    }

    getHideDisabledColorClass(tarifName: string) {
        if (
            !this.checkIfVierOderMehrZaehne() &&
            (tarifName.includes('Basis') || tarifName.includes('Zahn') || tarifName.includes('Junior'))
        ) {
            return true;
        }
        return false;
    }
}
