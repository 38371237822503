<dx-text class="page__heading" type="h2">Ursachen für Berufsunfähigkeit</dx-text>
<dx-card>
    <dx-card-header
        [label]="'Früher an später denken!'"></dx-card-header>
    <dx-card-content>
        @switch (slideIndex) {
            @case (0) {
                <ng-container>
                    <div>
                        <div class="slide blue">
                            <div class="header-container slide-0">
                                <dx-text type="h5">
                                    Wie viele von 100 Menschen werden im Laufe ihres Arbeitslebens berufsunfähig? Was
                                    schätzen Sie?
                                </dx-text>
                            </div>
                            <div class="subheader">
                                <p>{{ numberOfSelectedPersons }} von 100</p>
                            </div>
                            <app-bu-select-people
                                #peopleContainer
                                class="peopleContainer"
                                (selectedPersons)="getNumberOfSelectedPersons($event)"
                                theme="blue"
                            ></app-bu-select-people>
                        </div>
                    </div>
                </ng-container>
            }
            @case (1) {
                <ng-container>
                    <div class="blue">
                        <div id="people-1" class="slide" [ngClass]="slideIndex === 1 ? 'active-blue' : ''">
                            <div class="header-container fade-in-header-blue">
                                <dx-text type="h5">25 Menschen! Oder jeder Vierte.</dx-text>
                            </div>
                            <div class="subheader">
                                <p>25 von 100</p>
                            </div>
                            <app-bu-select-people
                                class="peopleContainer"
                                [numberOfPersons]="25"
                                [editable]="false"
                                theme="blue"
                            >
                            </app-bu-select-people>
                        </div>
                    </div>
                </ng-container>
            }
            @case (2) {
                <ng-container>
                    <div class="slide yellow">
                        <div class="header-container slide-2">
                            <dx-text type="h5">Bei wie vielen Menschen ist die Berufsunfähigkeit krankheitsbedingt? Was
                                schätzen
                                Sie?
                            </dx-text>
                        </div>
                        <div class="subheader">
                            <p>{{ numberOfSelectedPersonsSecondPage }} von 100</p>
                        </div>
                        <app-bu-select-people
                            class="peopleContainer"
                            (selectedPersons)="getNumberOfSelectedPersonsSecondPage($event)"
                            theme="yellow"
                        ></app-bu-select-people>
                    </div>
                </ng-container>
            }
            @case (3) {
                <ng-container>
                    <div class="slide yellow" [ngClass]="slideIndex === 3 ? 'active-yellow' : ''">
                        <div class="header-container fade-in-header-yellow">
                            <dx-text type="h5">91 von 100 Menschen werden durch Krankheit berufsunfähig</dx-text>
                        </div>
                        <div class="subheader">
                            <p>Krankheit 91 von 100</p>
                            <p class="accident">Unfall 9 von 100</p>
                        </div>
                        <app-bu-select-people
                            class="peopleContainer"
                            [numberOfPersons]="91"
                            [editable]="false"
                            theme="yellow"
                        >
                        </app-bu-select-people>

                        <dx-button type="primary-m" class="button-popup--open" (click)="openModal()"
                                   [label]="'Ursachen für Berufsunfähigkeit'"></dx-button>
                    </div>
                </ng-container>
            }
        }

        <app-card-footer [showPrev]="slideIndex>0" [disableNext]="disableNext()" (prevPage)="prevPage()"
                         (nextPage)="nextPage()"
                         [showNext]="slideIndex<3"></app-card-footer>
    </dx-card-content>
</dx-card>
<dx-modal [visible]="isModalVisible" (modalClosed)="isModalVisible=false" label="Ursachen für Berufsunfähigkeit"
          height="content">
    <div class="modal-image-container" slot="content">
        <div class="modal-image">
            <img src="assets/png/BU/image-2023-07-31-13-49-42-579.png" alt="Ursachen für Berufsunfähigkeit" />
        </div>
    </div>
</dx-modal>

