import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { SavingsCalculationsService } from '../savings-calculations.service';

@Component({
    selector: 'app-savings-input',
    templateUrl: './savings-input.component.html',
    styleUrls: ['./savings-input.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SavingsInputComponent {
    public sliderMin = 0;
    public sliderMax = 5;

    monthlySaving: number;
    oneTimeSaving: number;

    @Output()
    pageReady: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(private savingsCalculationsService: SavingsCalculationsService) {
    }

    setMonthlySaving(value: number) {
        if (!value) {
            value = 0;
        }
        this.savingsCalculationsService.setMonthlySavings(value);
        this.monthlySaving = value;
        this.setPageReady();
    }

    setOneTimeSaving(value: number) {
        if (!value) {
            value = 0;
        }
        this.savingsCalculationsService.setOneTimeSavings(value);
        this.oneTimeSaving = value;
        this.setPageReady();
    }

    setInterest(currentValue: number) {
        this.savingsCalculationsService.setInterestSavingsAccount(currentValue);
        this.setPageReady();
    }

    setPageReady() {
        this.pageReady.emit(this.monthlySaving > 0 || this.oneTimeSaving > 0);
    }
}
