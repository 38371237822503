<dx-text [type]="'h3'">Deutscher Aktienindex (DAX)</dx-text>
<div class="chart-container">
    <p-chart type="line" [data]="chartData" width="100%" height="30vh" [options]="chartOptions"></p-chart>
    <div class="slider-wrapper">
        <p-slider
            styleClass="dax-slider"
            [(ngModel)]="yearRange"
            [range]="true"
            [min]="startYear"
            [max]="endYear"
            [step]="1"
            (onChange)="calculateSavings()"
        ></p-slider>
    </div>
</div>
<div class="stats-bar">
    <p>
        {{ yearRange[1] - yearRange[0] }} Jahre Spardauer <br />
        <small>(von 01.{{ yearRange[0] }} bis 12.{{ yearRange[1] - 1 }})</small>
    </p>
    <p *ngIf="this.returnRates?.onTimeSaving">
        &empty; {{ this.returnRates?.onTimeSaving | percent: '1.1':'de-DE' }} p.a Rendite Einmalanlage
    </p>
    <p *ngIf="returnRates?.savingsPlan">&empty; {{ this.returnRates?.savingsPlan | percent: '1.1':'de-DE' }} p.a. Rendite Sparplan</p>
</div>
