import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { SavingsAmounts } from './savings-amounts.model';
import { ReturnRates } from './return-rates.model';
import { DaxDataService } from './dax-data.service';

@Injectable({
    providedIn: 'root'
})
export class SavingsCalculationsService {
    private monthlySavings: number;
    private oneTimeSavings: number;
    private interestSavingsAccount: number;
    private years = 25;
    private savingsAmounts: SavingsAmounts = new SavingsAmounts();
    private returnRates: ReturnRates = new ReturnRates();
    private selectedDaxData;
    amountsReplaySubject: ReplaySubject<SavingsAmounts> = new ReplaySubject<SavingsAmounts>(1);
    returnRatesReplaySubject: ReplaySubject<ReturnRates> = new ReplaySubject<ReturnRates>(1);

    constructor(private daxData: DaxDataService) {
        this.daxData.selectedDaxData$.subscribe(selectedDaxData => {
            this.selectedDaxData = selectedDaxData;
        });
    }

    setMonthlySavings(monthlySavings: number) {
        if (monthlySavings < 0) {
            return;
        }
        this.monthlySavings = monthlySavings;
        this.savingsAmounts.sumInpaymentSavingsAccount = this.getSumMonthlySavings() + this.getOneTimeSavings();
        this.savingsAmounts.sumMonthlySavings = this.getSumMonthlySavings();
    }

    setOneTimeSavings(oneTimeSavings: number) {
        if (oneTimeSavings < 0) {
            return;
        }
        this.oneTimeSavings = oneTimeSavings;
        this.savingsAmounts.oneTimeSavings = oneTimeSavings;
        this.savingsAmounts.sumInpaymentSavingsAccount = this.getSumMonthlySavings() + this.getOneTimeSavings();
    }

    setInterestSavingsAccount(interestSavingsAccount: number) {
        this.interestSavingsAccount = interestSavingsAccount;
    }

    getOneTimeSavings(): number {
        return this.oneTimeSavings || 0;
    }

    getSumMonthlySavings() {
        return this.monthlySavings * 12 * this.years || 0;
    }

    calculateSavingsAndReturns() {
        this.years = this.extractYearsFromDaxData();
        if (this.monthlySavings) {
            this.savingsAmounts.endAmountDaxSavingsPlan = this.calcSavingsPlanEndAmount(this.monthlySavings, this.selectedDaxData);
            this.returnRates.savingsPlan = this.calcSavingsPlanPerAnnumReturn(
                this.savingsAmounts.endAmountDaxSavingsPlan,
                this.monthlySavings,
                this.years
            );
        }
        if (this.oneTimeSavings) {
            this.savingsAmounts.endAmountDaxOneTime = this.calcOneTimeEndAmount(this.oneTimeSavings, this.selectedDaxData);
            this.returnRates.onTimeSaving = this.calcOneTimePerAnnumReturn(
                this.oneTimeSavings,
                this.savingsAmounts.endAmountDaxOneTime,
                this.years
            );
        }
        if (this.interestSavingsAccount) {
            this.savingsAmounts.endAmountSavingsAccount = this.calcSavingsAccountEndAmount(
                this.oneTimeSavings || 0,
                this.monthlySavings || 0,
                this.interestSavingsAccount,
                this.years
            );
        } else {
            this.savingsAmounts.endAmountSavingsAccount = 0;
        }

        this.savingsAmounts.sumInpaymentSavingsAccount = this.getSumMonthlySavings() + this.getOneTimeSavings();
        this.savingsAmounts.sumMonthlySavings = this.getSumMonthlySavings();
        this.amountsReplaySubject.next(this.savingsAmounts);
        this.returnRatesReplaySubject.next(this.returnRates);
    }

    private extractYearsFromDaxData() {
        return this.selectedDaxData.length / 12;
    }

    private calcSavingsPlanEndAmount(savingsAmount: number, daxRange: number[]) {
        const daxEndPrice = daxRange[daxRange.length - 1][1];
        return daxRange
            .map(currentDaxPrice => {
                return (1 + this.calcDaxRangeReturnRate(currentDaxPrice[1], daxEndPrice)) * savingsAmount;
            })
            .reduce((prevValue, currVal) => prevValue + currVal);
    }

    private calcSavingsPlanPerAnnumReturn(endAmount, monthlySavings, years) {
        function getMonthlySavingsEndAmount(monthlyInterest) {
            return new Array(years * 12)
                .fill(0)
                .map((_, index) => monthlySavings * Math.pow(1 + monthlyInterest, years * 12 - index))
                .reduce((prev, curr) => prev + curr);
        }

        let monthlyInterestRate = 0.00001;
        const step = 0.00001;

        const rates = new Array(5000).fill(0).map(() => {
            if (endAmount <= monthlySavings * years * 12) {
                monthlyInterestRate -= step;
            } else {
                monthlyInterestRate += step;
            }
            const diff = endAmount - getMonthlySavingsEndAmount(monthlyInterestRate);
            return [monthlyInterestRate, diff];
        });
        const lowestDiffInterest = rates.reduce((prev, cur) => {
            if (Math.abs(cur[1]) < Math.abs(prev[1])) {
                return cur;
            } else {
                return prev;
            }
        });
        return Math.pow(1 + lowestDiffInterest[0], 12) - 1;
    }

    private calcOneTimePerAnnumReturn(savingsAmount, endAmount, years) {
        return Math.pow(1 + this.calcDaxRangeReturnRate(savingsAmount, endAmount), 1 / years) - 1;
    }

    private calcOneTimeEndAmount(savingsAmount: number, daxRange: number[]): number {
        return (1 + this.calcDaxRangeReturnRate(daxRange[0][1], daxRange[daxRange.length - 1][1])) * savingsAmount;
    }

    private calcSavingsAccountEndAmount(
        savingsAmountOneTime: number,
        savingsAmountMonthly: number,
        savingsInterest: number,
        years: number
    ): number {
        let sumYearlySavingsAfterInterest = 0;
        let oneTimeSavingsAfterInterest = 0;

        if (savingsAmountMonthly > 0) {
            sumYearlySavingsAfterInterest = new Array(years)
                .fill(0)
                .map((_, index) => Math.pow(1 + savingsInterest / 100, years - index) * savingsAmountMonthly * 12)
                .reduce((prev, cur) => prev + cur);
        }
        if (savingsAmountOneTime) {
            oneTimeSavingsAfterInterest = Math.pow(1 + savingsInterest / 100, years) * savingsAmountOneTime;
        }
        return sumYearlySavingsAfterInterest + oneTimeSavingsAfterInterest;
    }

    private calcDaxRangeReturnRate(currentDaxPrice: number, endDaxPrice: number): number {
        return endDaxPrice / currentDaxPrice - 1;
    }
}
