import { Injectable } from '@angular/core';
import { User } from '../models/User';
import { KeycloakService } from './keycloak.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class UserDataService {
    constructor(private keycloakService: KeycloakService, private http: HttpClient) {}

    get(): User {
        return this.keycloakService.getUserData();
    }
}
