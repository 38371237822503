<dx-text class="page__heading" type="h2">Mein Gesundheitsplan</dx-text>
<dx-card>
    <dx-card-header label="Leistungskürzungen von 1983 bis heute - wo geht die Reise hin?"></dx-card-header>
    <dx-card-content>

        <div class="bubbles-slider-container">
            <div class="bubbles-container">
                    <div class="zahnersatz-bubble-wrapper">
                        <div
                            id="bubble-zahnersatz"
                            [ngClass]="{
                            percent70: currentYear >= 1997 && currentYear < 2004,
                            percent60: currentYear >= 2004 && currentYear < year,
                            percent50: currentYear >= year
                        }"
                            class="bubble"
                        >
                            <img src="assets/svg/gesundheitsplaner/Zahnersatz-01.svg" alt="Zahnersatz"/>
                            <p>Zahnersatz</p>
                        </div>
                </div>
                    <div class="krankengeld-bubble-wrapper">
                        <div
                            id="bubble-krankengeld"
                            [ngClass]="{
                            percent90: currentYear >= 1997 && currentYear < 2004,
                            percent80: currentYear >= 2004 && currentYear < year,
                            percent70: currentYear >= year
                        }"
                            class="bubble bubble-krankengeld"
                        >
                            <img src="assets/svg/gesundheitsplaner/Krankengeld-01.svg" alt="Krankengeld" />
                            <p>Krankengeld</p>
                        </div>
                </div>
                    <div class="arzneimittel-bubble-wrapper">
                        <div
                            id="bubble-arzneimittel"
                            [ngClass]="{
                            percent90: currentYear >= 1989 && currentYear < 1993,
                            percent80: currentYear >= 1993 && currentYear < 1997,
                            percent70: currentYear >= 1997 && currentYear < 2000,
                            percent60: currentYear >= 2000
                        }"
                            class="bubble bubble-arzneimittel"
                        >
                            <img src="assets/svg/gesundheitsplaner/Arzneimittel-01.svg" alt="Arzneimittel"/>
                            <p>Arzneimittel</p>
                        </div>
                </div>
                    <div class="sterbegeld-bubble-wrapper">
                        <div
                            id="bubble-sterbegeld"
                            [ngClass]="{ percent70: currentYear >= 2003 && currentYear < 2004, zoomOut: currentYear >= 2004 }"
                            class="bubble bubble-sterbegeld"
                        >
                            <img class="sterbegeld-icon" src="assets/svg/gesundheitsplaner/Sterbegeld-01.svg" alt="Sterbegeld" />
                            <p>Sterbegeld</p>
                        </div>
                </div>
                    <div class="sehhilfen-bubble-wrapper">
                        <div [ngClass]="{ zoomOut: currentYear >= 2004 }" id="bubble-sehhilfen"
                             class="bubble bubble-sehhilfen">
                            <img src="assets/svg/gesundheitsplaner/Sehhilfen-01.svg" alt="Sehhilfe"/>
                            <p>Sehhilfen</p>
                        </div>
                </div>
                    <div class="krankenhaus-bubble-wrapper">
                        <div
                            id="bubble-krankenhaus"
                            [ngClass]="{
                            percent85: currentYear >= 1989 && currentYear < 1993,
                            percent80: currentYear >= 1993 && currentYear < 1997,
                            percent75: currentYear >= 1997 && currentYear < 2000,
                            percent70: currentYear >= 2000 && currentYear < 2003,
                            percent65: currentYear >= 2003 && currentYear < 2007,
                            percent60: currentYear >= 2007
                        }"
                            class="bubble bubble-krankenhaus"
                        >
                            <img src="assets/svg/gesundheitsplaner/Krankenhaus-01.svg" alt="Krankenhaus"/>
                            <p>Krankenhaus</p>
                        </div>
                </div>
                    <div class="brillenfassung-bubble-wrapper">
                        <div id="bubble-brillenfassung" [ngClass]="{ zoomOut: currentYear >= 1997 }"
                             class="bubble bubble-brillenfassung">
                            <img src="assets/svg/gesundheitsplaner/Brillenfassung-01.svg" alt="Brillenfassung"/>
                            <p>Brillenfassung</p>
                        </div>
                </div>
            </div>
            <div class="slider-container" #sliderContainer>
                <dx-button class="info-button" type="secondary-s" iconposition="right" icon="information" [label]="'Leistungsabbau'" (click)="openLeistungsabbau()"></dx-button>
                <div class="custom-slider-container">
                    <div class="background-slider-range">
                        <div
                            *ngFor="let tick of sliderTicks; let index = tick"
                            [style]="{ width: tickwidth }"
                            [class.hide-tick-mark]="tick.hide"
                            [class.tick-2004]="tick.year === 2004"
                            [class.tick-2003]="tick.year === 2003"
                            class="tick-mark"
                        >
                            <div></div>
                            <p>{{ tick.year === year ? 'heute' : tick.year }}</p>
                        </div>
                    </div>
                    <dx-slider
                        #yearSlider
                        [min]="minYear"
                        [max]="maxYear"
                        [value]="currentYear"
                        [step]="1"
                        (valueChange)="yearChange($event.detail)"
                    ></dx-slider>

                </div>
            </div>
        </div>
        <app-card-footer [showPrev]="false" [disableNext]="!showNextBtn"
                         (nextPage)="nextPage()"></app-card-footer>

    </dx-card-content>
</dx-card>
<dx-modal [label]="'Leistungsabbau'" [visible]="isModalVisible" (modalClosed)="isModalVisible = false" [height]="'content'">
    <app-leistungsabbau slot="content"></app-leistungsabbau>
</dx-modal>
