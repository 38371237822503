import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Gesundheitsdaten } from '../gesundheitsdaten.model';

@Injectable({
    providedIn: 'root'
})
export class GesundheitsplanerService {
    public gebissMap: Map<any, any> = new Map();
    public gesundheitsdaten: Gesundheitsdaten = new Gesundheitsdaten();
    beitragsbemessungsgrenze: number;
    constructor(private http: HttpClient) {
        this.fetchBeitragsbemessungsgrenzeFromBackend();
    }

    saveBeitragsbemessungsgrenze(bm: number) {
        this.beitragsbemessungsgrenze = bm;
    }

    retrieveBeitragsbemessungsgrenze() {
        return this.beitragsbemessungsgrenze;
    }

    getBeitragsbemessungsgrenze(): Observable<number> {
        return this.http.get<number>(environment.backend.baseUrl + '/beitragsbemessungsgrenze');
    }
    public getVersicherungen(): Observable<string[]> {
        return this.http.get<string[]>(environment.backend.baseUrl + '/versicherungen');
    }

    public getPlanTarfieData(): Promise<string[]> {
        return firstValueFrom(this.http.get<string[]>(environment.backend.baseUrl + '/plantarife'));
    }

    private fetchBeitragsbemessungsgrenzeFromBackend() {
        this.getBeitragsbemessungsgrenze().subscribe(beitragsbemessungsgrenze => {
            this.saveBeitragsbemessungsgrenze(beitragsbemessungsgrenze);
        });
    }
}
