<div class="info-overlay">
    <dx-button type="secondary-m" (click)="openInfoOverlay()" [label]="'Runden Sie Ihren Versicherungsschutz ab'"
               [iconposition]="'right'" icon="information"></dx-button>
</div>
<div class="kassenzettel-auswahl-container">
    <div
        *ngIf="this.gesundheitsdaten.anzahlFehlendeZaehne !== 4 && this.gesundheitsdaten.anzahlWurzelBehandelteZaehne !== 4">
        <div class="checkbox-subcontainer">
            <dx-dropdown class="dx__dropdown" [label]="'Ihr Basisplan'" [value]="gesundheitsdaten.basisPlan"
                         (valueChange)="basisplanChanged($event.detail)">
                @for (basisPlan of basisplanList; track basisPlan.value) {
                    <dx-dropdown-option [label]="basisPlan.label" [value]="basisPlan.value"></dx-dropdown-option>
                }
            </dx-dropdown>
            <div class="basisplan-betrag">
                <p>
                    {{
                        kassenzetteldaten?.selectedPlaeneAndHisValues[gesundheitsdaten?.basisPlan] || 0 | currency: 'EUR':'symbol':'1.2-2'
                    }}
                </p>
            </div>
        </div>
        <div class="auswahl-container zuschlag" *ngIf="kassenzetteldaten?.gesamtZuschlag > 0">
            <p id="zuschlaege">Zuschläge</p>
            <p>{{ kassenzetteldaten?.gesamtZuschlag | currency: 'EUR':'symbol':'1.2-2' }}</p>
        </div>
    </div>

    <div class="auswahl-container">
        <div class="checkbox-subcontainer">
            <dx-checkbox [label]="'Plan Ambulant'" [checked]="gesundheitsdaten.hatPlanAmbulant" [templatevalue]="!gesundheitsdaten.hatPlanAmbulant"
                         (checkedChange)="ambulantChanged()" [templatevalue]="!gesundheitsdaten.hatPlanAmbulant"></dx-checkbox>
        </div>
        <p>
            {{ kassenzetteldaten?.selectedPlaeneAndHisValues['PLANA'] || 0 | currency: 'EUR':'symbol':'1.2-2' }}
        </p>
    </div>
    <div class="auswahl-container">
        <div class="checkbox-subcontainer">
            <dx-checkbox [label]="'Plan Stationär'" [checked]="gesundheitsdaten.hatPlanStationaer" [templatevalue]="!gesundheitsdaten.hatPlanStationaer"
                         (checkedChange)="stationaerChanged()"></dx-checkbox>
        </div>
        <p>
            {{ kassenzetteldaten?.selectedPlaeneAndHisValues['PLANS'] || 0 | currency: 'EUR':'symbol':'1.2-2' }}
        </p>
    </div>
    <div
        class="auswahl-container"
        *ngIf="showEKTZ && (gesundheitsdaten.monatlichesBruttoEinkommen > 450 || gesundheitsdaten.hatKeinEigenesEinkommen === false)"
    >
        <div class="checkbox-subcontainer">
            <dx-checkbox [label]="tarif" [checked]="gesundheitsdaten.hatektz" [templatevalue]="!gesundheitsdaten.hatektz"
                         (checkedChange)="ektzChanged()"></dx-checkbox>
        </div>
        <p>
            {{ kassenzetteldaten?.ektz || 0 | currency: 'EUR':'symbol':'1.2-2' }}
        </p>
    </div>
    <div
        class="auswahl-container"
        *ngIf="showKTBU && (gesundheitsdaten.monatlichesBruttoEinkommen > 450 || gesundheitsdaten.hatKeinEigenesEinkommen === false)"
    >
        <div class="checkbox-subcontainer">
            <dx-checkbox [label]="tarif" [checked]="gesundheitsdaten.hatktbu" [templatevalue]="!gesundheitsdaten.hatktbu"
                         (checkedChange)="ktbuChanged()"></dx-checkbox>
        </div>
        <p>
            {{ kassenzetteldaten?.ktbu || 0 | currency: 'EUR':'symbol':'1.2-2' }}
        </p>
    </div>
    <div class="auswahl-container">
        <div class="checkbox-subcontainer">
            <dx-checkbox [label]="'Sponsor gesucht'" [checked]="gesundheitsdaten.sponsorGesucht" [templatevalue]="!gesundheitsdaten.sponsorGesucht"
                         (checkedChange)="sponsorGesuchtChanged()"></dx-checkbox>
        </div>
        <p class="ihr-sponsor">Ihr Sponsor BKK Linde</p>
    </div>
    <div class="container-header fitbonus">
        <div class="header-content">
            <div class="fitbonus-plus">
                <p [ngClass]="{ inactive: !gesundheitsdaten.sponsorGesucht }" class="main-header" id="fitbonus">FitBonus<sup
                    class="fitbonus-sup">+</sup></p>
            </div>
            <div class="fitbonus-betrag">
                <p [ngClass]="{ inactive: !gesundheitsdaten.sponsorGesucht }">
                    - {{ allowedActivitiesRefundDividedBy12 + optionalActivitiesRefundTotal || 0 | currency: 'EUR':'symbol':'1.2-2' }}
                </p>
            </div>
        </div>

        <div *ngIf="gesundheitsdaten.sponsorGesucht" class="main-content">
            <div>
                <div *ngIf="gesundheitsdaten.alter >= 18" class="dropdown-container">
                    <dx-text type="ps">Sind Sie Generali Vitality Mitglied? Falls ja, welchen Vitality-Status planen Sie
                        zu erreichen?
                    </dx-text>
                    <dx-dropdown class="dx__dropdown" [value]="fitbonusplus.vitalityStatus"
                                 (valueChange)="vitalityStatusChanged($event.detail)">
                        @for (vit of vitalityList; track vit) {
                            <dx-dropdown-option
                                [label]="vit.label" [value]="vit.value"></dx-dropdown-option>
                        }
                    </dx-dropdown>
                </div>
                <div
                    *ngIf="fitbonusplus.vitalityStatus === 'KEIN MITGLIED' || fitbonusplus.vitalityStatus === undefined"
                    class="sportclub">
                    <div *ngIf="gesundheitsdaten.alter >= 18">
                        <dx-text type="ps">Sind Sie in einem Sportverein/Fitnessstudio gemeldet oder nehmen Sie
                            regelmäßig an Präventionskursen teil?
                        </dx-text>
                        <div class="content">
                            <div class="auswahl-container">
                                <div class="checkbox-subcontainer">
                                    <dx-checkbox [label]="'Ja'"
                                                 [checked]="fitbonusplus.sportaktivitaten"
                                                 [templatevalue]="!fitbonusplus.sportaktivitaten"
                                                 (checkedChange)="sportClubChanged($event.detail)"></dx-checkbox>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="fitbonusplus-content">
                    <div *ngIf="(categories | json) == '{}'" class="loading"></div>
                    <div *ngFor="let category of categories | keyvalue " class="fitbonusplus-list">
                        <div class="header"><dx-text type="ps">{{ category.key }}</dx-text>
                            <dx-text type="ps">Betrag</dx-text></div>
                        <div *ngFor="let item of category.value" class="inner-list">
                            <div class="auswahl-container">
                                <div class="checkbox-subcontainer">
                                    <dx-checkbox
                                        [label]="item.name"
                                        [checked]="fitbonusplus[formatName(item.name)]"
                                        (checkedChange)="fitBonusChanged($event, item.name)"
                                        [disabled]="$any(item).isDisabled"
                                        [templatevalue]="!fitbonusplus[formatName(item.name)]"
                                    > <dx-info id="info" slot="info">
                                        <dx-popover id="popover" slot="modal">
                                            <dx-text>{{item.description}}</dx-text>
                                        </dx-popover>
                                    </dx-info></dx-checkbox>

                                </div>
                                <div
                                    class="fitbonusplus-refund">{{ $any(item).refund || 0 | currency: 'EUR':'symbol':'1.2-2' }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-header beitragsvorteil" *ngIf="kassenzetteldaten?.beitragsVorteil > 0">
        <p [ngClass]="{ inactive: !gesundheitsdaten.sponsorGesucht }" id="beitragsvorteil">Wechselvorteil
            (brutto)</p>
        <p [ngClass]="{ inactive: !gesundheitsdaten.sponsorGesucht }">
            {{ kassenzetteldaten?.beitragsVorteil * -1 | currency: 'EUR':'symbol':'1.2-2' }}
        </p>
    </div>
    <div class="container-header gesamtbetrag">
        <p id="gesamtbetrag">Gesamtbeitrag</p>
        <p *ngIf="gesundheitsdaten.sponsorGesucht">{{ kassenzetteldaten?.sumSelectedPlaeneValues + kassenzetteldaten?.ektz + kassenzetteldaten?.ktbu + kassenzetteldaten?.gesamtZuschlag - kassenzetteldaten?.beitragsVorteil - allowedActivitiesRefundDividedBy12 - optionalActivitiesRefundTotal | currency: 'EUR':'symbol':'1.2-2' }}</p>
        <p *ngIf="!gesundheitsdaten.sponsorGesucht">{{ kassenzetteldaten?.sumSelectedPlaeneValues + kassenzetteldaten?.ektz + kassenzetteldaten?.ktbu + kassenzetteldaten?.gesamtZuschlag - allowedActivitiesRefundDividedBy12 - optionalActivitiesRefundTotal | currency: 'EUR':'symbol':'1.2-2' }}</p>
    </div>
</div>
<dx-modal width="xl" [visible]="showModal" [label]="'Runden Sie Ihren Versicherungsschutz ab'" [height]="'content'"
          (modalClosed)="showModal = false">
    <img slot="content" class="modal__image" alt="" src="assets/png/gesundheitsplaner/Overlay_Abrundung.png" />
</dx-modal>
