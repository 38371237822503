import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SparfoerderungComponent } from './sparfoerderung.component';
import { DesignSystemModule } from '@dvag/design-system-angular';

@NgModule({
    declarations: [SparfoerderungComponent],
    imports: [CommonModule, DesignSystemModule]
})
export class SparfoerderungModule {}
