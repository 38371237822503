<dx-text class="page__heading" type="h2">Wunschbetrag</dx-text>
<dx-card>
    <dx-card-header label="Wie hoch wäre Ihre monatliche Sparrate?"></dx-card-header>
    <dx-card-content>
        <dx-grid class="grid" mq1="12/*" mq3="6-6">
            <div class="grid-left">
                <div class="bubble-container" #bubbleContainer>
                    <div
                        class="bubble"
                        id="bubble"
                        [ngClass]="result > 0 ? 'bubble-active' : 'bubble-inactive'"
                        [ngStyle]="{ 'padding-bottom': bubbleSizePadding, width: bubbleSize }">
                        <span class="bubble-value" [ngClass]="result > 0 ? 'bubble-text-active' : 'bubble-text-inactive'">
                            {{ result | currency: 'EUR':'symbol':'1.0-0':'de_DE' }}
                        </span>
                    </div>
                </div>
                <div class="sparrate-text">
                    @if(result > 0) {
                        <dx-text type="it">
                            Ihre monatliche Sparrate für einen Wunschbetrag von<br />
                            {{ desiredAmount | currency: 'EUR':'symbol':'1.0-0':'de_DE' }} in {{ year }} Jahren
                        </dx-text>
                    }
                </div>
                <dx-slider
                    [min]="sliderMin"
                    [max]="sliderMax"
                    [value]="1"
                    [label]="'Angenommene Rendite p.a.'"
                    [step]="1"
                    [knoblabel]="'$1 %'"
                    (valueChange)="handleInterestChange($event.detail)"
                    [disabled]="!year || !desiredAmount"
                ></dx-slider>
            </div>
            <div class="grid-right">
                <dx-text type="h5">Ihre Angaben zum Wunschbetrag</dx-text>
                <dx-number-input
                    label="Wunschbetrag"
                    (valueChange)="setDesiredAmount($event.detail)"
                    [limitlength]="6"
                    [min]="0"
                    [precision]="0"
                    profile="currency-euro"
                    placeholder="Betrag eingeben"
                ></dx-number-input>

                <dx-dropdown label="Spardauer" (valueChange)="setYear($event.detail)"
                             placeholder="Spardauer auswählen">
                    @for (year of years; track year) {
                        <dx-dropdown-option label="{{year}}&nbsp;{{(year===1) ? 'Jahr' : 'Jahre'}}"
                                            value="{{year}}"></dx-dropdown-option>
                    }
                </dx-dropdown>
            </div>
        </dx-grid>
    </dx-card-content>
</dx-card>
