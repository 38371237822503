import { Component, EventEmitter, Input, Output } from '@angular/core';
import { KuboItemModel } from '../interface';
import { KundenbonusService } from '../service/kundenbonus.service';

@Component({
    selector: 'app-kubo-items',
    templateUrl: './kubo-items.component.html'
})
export class KuboItemsComponent {
    @Input() canChange = false;
    @Input() items: KuboItemModel[];

    @Output() itemChange: EventEmitter<KuboItemModel[]> = new EventEmitter();

    constructor(private kundenbonusService: KundenbonusService) {
    }

    update(item: KuboItemModel) {
        if (this.canChange && item.canToggle) {
            const tempItems = this.kundenbonusService.toggle(item.value, this.items);
            this.itemChange.emit(tempItems);
        }
    }

}
