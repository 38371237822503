import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SparplanComponent } from './sparplan.component';
import { FormsModule } from '@angular/forms';
import { SliderModule } from 'primeng/slider';
import { SharedModule } from '../../shared/shared.module';
import { DesignSystemModule } from '@dvag/design-system-angular';

@NgModule({
    declarations: [SparplanComponent],
    imports: [CommonModule, SliderModule, FormsModule, SharedModule, DesignSystemModule]
})
export class SparplanModule {}
