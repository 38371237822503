<app-dax-chart></app-dax-chart>

<div class="container">
    <app-dax-comparison
        *ngIf="amounts?.endAmountSavingsAccount > 0"
        [investmentBetrag]="this.amounts.sumInpaymentSavingsAccount"
        [renditeBetrag]="this.amounts.endAmountSavingsAccount"
        [maxValue]="this.maxValue"
        label="Sparbuch"
    ></app-dax-comparison>

    <app-dax-comparison
        *ngIf="amounts?.sumMonthlySavings > 0"
        [investmentBetrag]="this.amounts.sumMonthlySavings"
        [renditeBetrag]="this.amounts.endAmountDaxSavingsPlan"
        [maxValue]="this.maxValue"
        label="DAX-Sparplan"
    ></app-dax-comparison>

    <app-dax-comparison
        *ngIf="amounts?.oneTimeSavings > 0"
        [investmentBetrag]="this.amounts.oneTimeSavings"
        [renditeBetrag]="this.amounts.endAmountDaxOneTime"
        [maxValue]="this.maxValue"
        label="DAX-Einmalanlage"
    ></app-dax-comparison>
</div>
