import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class WunschbetragCalculationsService {
    private userDesiredAmount: number;
    private userDurationInYears: number;
    private userInterestRate: number;

    rateSubject: Subject<number> = new Subject<number>();

    constructor() {
        this.init();
    }

    setUserDesiredAmount(amount: number) {
        if (amount < 0 || isNaN(amount)) {
            return;
        }
        this.userDesiredAmount = amount;
    }

    setUserDurationInYears(durationInYears: number) {
        if (durationInYears < 0 || isNaN(durationInYears)) {
            return;
        }
        this.userDurationInYears = durationInYears;
    }

    setUserInterestRate(rate: number) {
        if (rate < 0 || isNaN(rate)) {
            return;
        }
        this.userInterestRate = rate;
    }

    calculateMonthlySavingsRate() {
        if (this.userDesiredAmount <= 0 || this.userDurationInYears <= 0 || this.userInterestRate <= 0) {
            this.rateSubject.next(0);
            return;
        }
        const i = 1.0 + this.userInterestRate / 100.0;
        const p = (i - 1.0) / 12.0 + 1.0; // Payment is made per month, and not per year!!!
        const quot = (Math.pow(p, this.userDurationInYears * 12) - 1.0) / (p - 1.0);
        let monthlyRate = this.userDesiredAmount / (p * quot);
        monthlyRate = Math.round(monthlyRate);

        this.rateSubject.next(monthlyRate);
    }

    /**
     * Resets the the following properties to 0:
     * - userDesiredAmount
     * - userDurationInYears
     * - userInterestRate
     */
    public init(): void {
        this.userDesiredAmount = 0;
        this.userDurationInYears = 0;
        this.userInterestRate = 0;
    }
}
