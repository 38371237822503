import { Pipe, PipeTransform } from '@angular/core';
import { FipyCard, FipyModuleCategory } from '../interface';

@Pipe({
    name: 'cardsByCategory'
})
export class CardsByCategoryPipe implements PipeTransform {

    transform(cards: FipyCard[], category: FipyModuleCategory): FipyCard[] {
        return cards.filter(it => {
            return it.categories.some(cat => cat.category === category);
        }).map(it => {
            return {
                ...it,
                prio: it.categories.find(cat => cat.category === category).prio,
            } as FipyCard;
        }).sort((a, b) => {
            if (a.prio < b.prio) {
                return -1;
            } else if (a.prio > b.prio) {
                return 1;
            }
            return 0;
        });
    }

}
