import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SparplanCalculationsService {

    calculateROIForYears(years: number, zins: number, einzahlungenPerMonth: number): number {
        if (!years || years < 0 || !zins || zins < 0 || !einzahlungenPerMonth || einzahlungenPerMonth < 0) {
            return 0;
        }

        const m = 12;
        const i = zins / 100.0;
        if (i === 0) {
            return einzahlungenPerMonth * m * years;
        }

        const q = 1.0 + i;
        let roi = einzahlungenPerMonth * (m + ((m + 1) * i) / 2) * ((Math.pow(q, years) - 1) / i);
        roi = Math.floor(roi);
        return roi;
    }

    calculateOverallPayments(spardauer: number, sparbetrag: number, shortening: number): number {
        if (
            !spardauer ||
            spardauer < 0 ||
            !sparbetrag ||
            sparbetrag < 0 ||
            isNaN(shortening) ||
            typeof shortening === 'undefined' ||
            shortening < 0
        ) {
            return 0;
        }

        const yearsWithShortening = spardauer - shortening;
        const overallPayments = yearsWithShortening * 12.0 * sparbetrag;
        return overallPayments;
    }

    /**
     * This function adjusts numbers that have more than 7 digits so that all digits after the 7th are set to 0.
     * Example: 123456789 -> 123456700
     * @param amount [description]
     */
    roundNumberAfter7thDigit(amount: number): number {
        if (!amount || amount < 0) {
            return 0;
        }

        let roundedAmount = amount;
        const numberLength = amount.toFixed(0).length;
        // we want to round everything after the 7th digit
        if (numberLength >= 7) {
            const digitsToRound = numberLength - 7;

            const roundingFactor = Math.pow(10, digitsToRound);
            roundedAmount = Math.ceil(amount / roundingFactor) * roundingFactor;
        }

        return roundedAmount;
    }
}
