import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Tarif } from '../interface/tarif';

@Component({
    selector: 'app-detailsinfo-rechnenbeispiel',
    templateUrl: './detailsinfo-rechnenbeispiel.component.html',
    styleUrls: ['./detailsinfo-rechnenbeispiel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DetailsinfoRechnenbeispielComponent {
    @Input() selectedTarif: Tarif;
    @Input() buttonLabel: string;
    @Output() addAndDeletePlan = new EventEmitter<string>();
    @Input() isMehrAlsVierZaehne = true;
    showVorteile = true;
    private maxValue: number;

    /**
     * When called, showVorteile is set to true and
     * the column with tarif benefits is displayed in template
     */
    callVorteile() {
        this.showVorteile = true;
    }

    /**
     * When called, showVorteile is set to false and
     * the column with calculation examples is displayed in template
     */
    callRechenbeispiele() {
        this.showVorteile = false;
    }

    /**
     * Saves the selected tarif in selectedTarifbezechnung variable
     * @param tarif;
     */
    selectTarif(tarif: Tarif) {
        this.selectedTarif = tarif;
    }

    emitAddAndDeletePlan() {
        this.addAndDeletePlan.emit();
    }

    getKoaStyle(koa: string): any {
        const style = {
            height: this.getHeightForKOA(koa) + 'px'
        };
        return style;
    }

    getKmaStyle(kma: string): any {
        const style = {
            height: this.getHeightForKMA(kma) + 'px'
        };
        return style;
    }

    /**
     * Calculates the height of the
     * 'Kosten ohne Absicherung' container
     * @param value includes the amount of money to be displayed;
     * The height of the container depends on the amount of money
     */
    getHeightForKOA(value: string) {
        this.maxValue =
            parseInt(this.selectedTarif.koasie, 10) +
            parseInt(this.selectedTarif.koaerstattung, 10) +
            parseInt(this.selectedTarif.koagkv, 10);
        return (Number.parseInt(value, 10) / this.maxValue) * 204;
    }

    /**
     * Calculates the height of the
     * 'Kosten mit Absicherung' container
     * @param value includes the amount of money to be displayed;
     * The height of the container depends on the amount of money
     */
    getHeightForKMA(value: string) {
        this.maxValue =
            parseInt(this.selectedTarif.kmasie, 10) +
            parseInt(this.selectedTarif.kmaerstattung, 10) +
            parseInt(this.selectedTarif.kmagkv, 10);
        return (Number.parseInt(value, 10) / this.maxValue) * 204;
    }

    showCorrectPlan() {
        if (this.isMehrAlsVierZaehne || this.selectedTarif.name === 'Plan Ambulant' || this.selectedTarif.name === 'Plan Stationär') {
            return true;
        }
        return false;
    }
}
