<div class="chart-container">
    <div class="savings-stats chart-info" [ngStyle]="{ visibility: sliderOpacity.visibility }">
        <dx-text type="ps">
            Verkaufserlös:
            {{ (calculationService.savingsStatistics$ | async)?.salesProceeds | currency: 'EUR':'symbol':'1.2-2':'de-DE' }}
        </dx-text>
        <dx-text type="ps">
            Gewinn:
            {{ (calculationService.savingsStatistics$ | async)?.profits | currency: 'EUR':'symbol':'1.2-2':'de-DE' }}
        </dx-text>
        <dx-text type="ps">
            Anteile: {{ (calculationService.savingsStatistics$ | async)?.numberShares | number: '1.2-2':'de-DE' }} für
            {{ 1200 | currency: 'EUR':'symbol':'1.2-2':'de-DE' }}
        </dx-text>
    </div>
    <div class="savings-stats"><p class="padding-left">€</p></div>
    <div class="chart-wrapper">
        <p-chart #caChart type="line" [data]="chartData" [options]="chartOptions" height="32vh"></p-chart>
        <div class="sider-wrapper">
            <p-slider
                id="slider-0"
                class="chart-slider"
                [style]="{ height: '100%' }"
                orientation="vertical"
                [(ngModel)]="stockData[0]"
                [min]="1"
                [max]="maxStockValue"
                [ngStyle]="{ visibility: sliderOpacity.visibility }"
            ></p-slider>

            <p-slider
                id="slider-1"
                class="chart-slider"
                [style]="{ height: '100%' }"
                orientation="vertical"
                [(ngModel)]="stockData[1]"
                [min]="1"
                [max]="maxStockValue"
                [ngStyle]="{ visibility: sliderOpacity.visibility }"
            ></p-slider>

            <p-slider
                id="slider-2"
                class="chart-slider"
                [style]="{ height: '100%' }"
                orientation="vertical"
                [(ngModel)]="stockData[2]"
                [min]="1"
                [max]="maxStockValue"
                [ngStyle]="{ visibility: sliderOpacity.visibility }"
            ></p-slider>

            <p-slider
                id="slider-3"
                class="chart-slider"
                [style]="{ height: '100%' }"
                orientation="vertical"
                [(ngModel)]="stockData[3]"
                [min]="1"
                [max]="maxStockValue"
                [ngStyle]="{ visibility: sliderOpacity.visibility }"
            ></p-slider>

            <p-slider
                id="slider-4"
                class="chart-slider"
                [style]="{ height: '100%' }"
                orientation="vertical"
                [(ngModel)]="stockData[4]"
                [min]="1"
                [max]="maxStockValue"
                [ngStyle]="{ visibility: sliderOpacity.visibility }"
            ></p-slider>

            <p-slider
                id="slider-5"
                class="chart-slider"
                [style]="{ height: '100%' }"
                orientation="vertical"
                [(ngModel)]="stockData[5]"
                [min]="1"
                [max]="maxStockValue"
                [ngStyle]="{ visibility: sliderOpacity.visibility }"
            ></p-slider>

            <p-slider
                id="slider-6"
                class="chart-slider"
                [style]="{ height: '100%' }"
                orientation="vertical"
                [(ngModel)]="stockData[6]"
                [min]="1"
                [max]="maxStockValue"
                [ngStyle]="{ visibility: sliderOpacity.visibility }"
            ></p-slider>

            <p-slider
                id="slider-7"
                class="chart-slider"
                [style]="{ height: '100%' }"
                orientation="vertical"
                [(ngModel)]="stockData[7]"
                [min]="1"
                [max]="maxStockValue"
                [ngStyle]="{ visibility: sliderOpacity.visibility }"
            ></p-slider>

            <p-slider
                id="slider-8"
                class="chart-slider"
                [style]="{ height: '100%' }"
                orientation="vertical"
                [(ngModel)]="stockData[8]"
                [min]="1"
                [max]="maxStockValue"
                [ngStyle]="{ visibility: sliderOpacity.visibility }"
            ></p-slider>

            <p-slider
                id="slider-9"
                class="chart-slider"
                [style]="{ height: '100%' }"
                orientation="vertical"
                [(ngModel)]="stockData[9]"
                [min]="1"
                [max]="maxStockValue"
                [ngStyle]="{ visibility: sliderOpacity.visibility }"
            ></p-slider>

            <p-slider
                id="slider-10"
                class="chart-slider"
                [style]="{ height: '100%' }"
                orientation="vertical"
                [(ngModel)]="stockData[10]"
                [min]="1"
                [max]="maxStockValue"
                [ngStyle]="{ visibility: sliderOpacity.visibility }"
            ></p-slider>

            <p-slider
                id="slider-11"
                class="chart-slider"
                [style]="{ height: '100%' }"
                orientation="vertical"
                [(ngModel)]="stockData[11]"
                [min]="1"
                [max]="maxStockValue"
                [ngStyle]="{ visibility: sliderOpacity.visibility }"
            ></p-slider>
        </div>
    </div>
    <div class="share-amounts chart-info">
        <dx-text type="it" class="axis-x-value" *ngFor="let shares of sharesAmount" [ngStyle]="{ visibility: sliderOpacity.visibility }">
            {{ shares | number: '1.2-2':'de-DE' }}
        </dx-text>
    </div>
</div>
