<dx-text class="page__heading" type="h2">Mein Gesundheitsplan</dx-text>
<dx-card>
    <dx-card-header label="Angaben zu Ihrer Person"></dx-card-header>
    <dx-card-content>
        <dx-grid mq1="12/*" mq3="6-6/*" class="grid">
            <dx-grid mq1="4-8/12/6-6/6-6">
                <dx-dropdown [label]="'Anrede'" (valueChange)="gesundheitsdaten.anrede = $event.detail.toString()"
                             [value]="gesundheitsdaten.anrede">
                    <dx-dropdown-option value="frau" label="Frau"></dx-dropdown-option>
                    <dx-dropdown-option value="herr" label="Herr"></dx-dropdown-option>
                </dx-dropdown>
                <dx-date-input [label]="'Geburtsdatum'" (valueComplete)="setDateOfBirth($event.detail)"
                               [value]="gesundheitsdaten.geburtsDatum?.toString()"></dx-date-input>
                <dx-combobox (search)="search($event)" [label]="'Gesetzliche Krankenversicherung'"
                             (valueComplete)="gesundheitsdaten.aktuelleKrankenkasse = $event.detail.toString()"
                             [value]="gesundheitsdaten.aktuelleKrankenkasse"></dx-combobox>
                <dx-number-input [label]="'Monatliches Bruttoeinkommen'"
                                 [value]="gesundheitsdaten.monatlichesBruttoEinkommen"
                                 [disabled]="gesundheitsdaten.hatKeinEigenesEinkommen"
                                 (valueChange)="gesundheitsdaten.monatlichesBruttoEinkommen = $event.detail"></dx-number-input>
                <dx-number-input [label]="'Monatliches Nettoeinkommen'"
                                 [value]="gesundheitsdaten.monatlichesNettoEinkommen"
                                 [disabled]="gesundheitsdaten.hatKeinEigenesEinkommen"
                                 (valueChange)="gesundheitsdaten.monatlichesNettoEinkommen = $event.detail"></dx-number-input>
                <div>
                    <dx-dropdown [label]="'Fehlende Zähne'" [clearable]="false" [value]="0"
                                 (valueChange)="gesundheitsdaten.anzahlFehlendeZaehne = +$event.detail"
                                 [value]="gesundheitsdaten.anzahlFehlendeZaehne">
                        <dx-dropdown-option value="0" label="0"></dx-dropdown-option>
                        <dx-dropdown-option value="1" label="1"></dx-dropdown-option>
                        <dx-dropdown-option value="2" label="2"></dx-dropdown-option>
                        <dx-dropdown-option value="3" label="3"></dx-dropdown-option>
                        <dx-dropdown-option value="4" label="4 oder mehr"></dx-dropdown-option>
                    </dx-dropdown>
                    <dx-text type="its" *ngIf="gesundheitsdaten.anzahlFehlendeZaehne === 4">Tarif kann max. mit 3
                        fehlenden Zähnen abgeschlossen werden.
                    </dx-text>
                    <dx-text type="its" *ngIf="gesundheitsdaten.anzahlFehlendeZaehne !== 4">* fehlende nicht ersetzte
                        Zähne ohne Weisheitszähne.
                    </dx-text>
                </div>
                <div>
                    <dx-dropdown [label]="'Wurzelbehandelte Zähne'" [clearable]="false"
                                 [value]="gesundheitsdaten.anzahlWurzelBehandelteZaehne"
                                 (valueChange)="gesundheitsdaten.anzahlWurzelBehandelteZaehne = +$event.detail">
                        <dx-dropdown-option value="0" label="0"></dx-dropdown-option>
                        <dx-dropdown-option value="1" label="1"></dx-dropdown-option>
                        <dx-dropdown-option value="2" label="2"></dx-dropdown-option>
                        <dx-dropdown-option value="3" label="3"></dx-dropdown-option>
                        <dx-dropdown-option value="4" label="4 oder mehr"></dx-dropdown-option>
                    </dx-dropdown>
                    <dx-text type="its" *ngIf="gesundheitsdaten.anzahlWurzelBehandelteZaehne === 4">Tarif kann max. mit
                        3 wurzelbehandelten Zähnen abgeschlossen werden.
                    </dx-text>
                    <dx-text type="its" *ngIf="gesundheitsdaten.anzahlWurzelBehandelteZaehne !== 4">* in den letzten
                        zwei Jahren.
                    </dx-text>
                </div>
            </dx-grid>
            <div>
                <dx-checkbox [label]="'Kein eigenes Einkommen'" [checked]="gesundheitsdaten.hatKeinEigenesEinkommen" [templatevalue]="!gesundheitsdaten.hatKeinEigenesEinkommen"
                             (checkedChange)="gesundheitsdaten.hatKeinEigenesEinkommen = $event.detail"></dx-checkbox>
                <dx-checkbox *ngIf="between21And39" [label]="'In Ausbildung'" [checked]="gesundheitsdaten.istInAusbildung" [templatevalue]="!gesundheitsdaten.istInAusbildung"
                             (checkedChange)="gesundheitsdaten.istInAusbildung = $event.detail"></dx-checkbox>
                <dx-checkbox
                    [label]="'Besteht für Sie ein Vermögensaufbau & Sicherheitsplan mit Berufsunfähigkeitsrente?'" [checked]="gesundheitsdaten.berufsunfaehigkeitsRente" [templatevalue]="!gesundheitsdaten.berufsunfaehigkeitsRente"
                    (checkedChange)="toggleBU($event.detail)"></dx-checkbox>
                <dx-checkbox [label]="'Sehhilfe vorhanden'" [checked]="gesundheitsdaten.istSehHilfeVorhanden" [templatevalue]="!gesundheitsdaten.istSehHilfeVorhanden"
                             (checkedChange)="gesundheitsdaten.istSehHilfeVorhanden = $event.detail"></dx-checkbox>
                <dx-checkbox [label]="'VSP-Nachlass'" [checked]="gesundheitsdaten.mitVspNachlass" [templatevalue]="!gesundheitsdaten.mitVspNachlass"
                             (checkedChange)="gesundheitsdaten.mitVspNachlass = $event.detail"></dx-checkbox>
                <dx-checkbox [checked]="gesundheitsdaten.hatVaspVorteil" *ngIf="gesundheitsdaten.berufsunfaehigkeitsRente" [label]="'VASP Vorteil'" [templatevalue]="!gesundheitsdaten.hatVaspVorteil"
                             (checkedChange)="gesundheitsdaten.hatVaspVorteil = $event.detail"></dx-checkbox>
            </div>
        </dx-grid>
        <app-card-footer [disablePrev]="!showPrevBtn" (prevPage)="prevPage()" [disableNext]="!showNextButton()"
                         (nextPage)="nextPage()"></app-card-footer>
    </dx-card-content>
</dx-card>

<dx-alert [visible]="showAlert" [type]="'error'" [titlestring]="'Fehler'" [content]="'Fehler beim Abruf der Daten vom Server.'">
    <dx-button id="button-ok" type="text" slot="action" label="Schließen" (click)="showAlert=false"></dx-button>
</dx-alert>
