import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FipyCard } from '../interface';
import { StartseiteService } from '../service/startseite.service';

enum ButtonAction {
    ABBRECHEN = 'Abbrechen',
    DOWNLOAD = 'App herunterladen',
    OPEN = 'App öffnen',
}

export enum FipyCardSource {
    UNKNOWN,
    WEB,
    APPPORTAL,
    NICHT_MEHR_VERFUEGBAR,
    VP_DIGITAL_BERATUNGSWELT,
    BALD_IN_VP_DIGITAL,
}

@Component({
    selector: 'app-fipy-module-card',
    templateUrl: './fipy-module-card.component.html',
    styleUrls: ['./fipy-module-card.component.scss']
})
export class FipyModuleCardComponent implements OnInit, OnChanges {
    @Input() card: FipyCard;

    ButtonAction = ButtonAction;
    FipyCardSource = FipyCardSource;

    modalOpenAppVisible = false;
    modalOpenNoIpad = false;
    isIpad = false;

    notMaintainedText = '';

    isNichtMehrVerfuegbar = false;
    isBaldInVPDigital = false;
    isVPDigitalBeratungswelt = false;

    constructor(private startseiteService: StartseiteService) {
    }

    ngOnInit(): void {
        const source = this.card.source as FipyCardSource;
        if (source === FipyCardSource.NICHT_MEHR_VERFUEGBAR) {
            this.isNichtMehrVerfuegbar = true;
        } else if (source === FipyCardSource.BALD_IN_VP_DIGITAL) {
            this.isBaldInVPDigital = true;
        } else if (source === FipyCardSource.VP_DIGITAL_BERATUNGSWELT) {
            this.isVPDigitalBeratungswelt = true;
        }
    }

    ngOnChanges(): void {
        this.notMaintainedText = this.card.maintained ? null : 'Hinweis: Dieses Modul wird nicht mehr gewartet.';
        this.isIpad = this.IS_IPAD();
    }

    IS_IPAD(): boolean {
        if (/iPad/.test(navigator.userAgent)) {
            return true;
        } else {
            return navigator.maxTouchPoints &&
                navigator.maxTouchPoints > 2 &&
                /MacIntel/.test(navigator.userAgent);
        }
    }

    closeModalNoIpad() {
        setTimeout(() => this.modalOpenNoIpad = false, 200)
    }

    openApp() {
        if (this.card.source === FipyCardSource.APPPORTAL)  {
            window.open(`dvag-webportal://${this.card.link}`, '_blank');
        }
        this.modalOpenAppVisible = false;
    }

    open() {
        if (this.card.source === FipyCardSource.WEB) {
            const url = this.startseiteService.getVbAppsUrl()
            const link = this.card.link.replace('https://vb-apps.dvag/', url)
            window.open(link, '_blank');
        } else if (this.card.source === FipyCardSource.APPPORTAL)  {
            console.log('try to open', this.isIpad)
            if (this.isIpad) {
                this.modalOpenAppVisible = true;
            } else {
                this.modalOpenNoIpad = true;
            }
        }
    }
}
