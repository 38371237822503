<div class="content">
    <div class="graph-wrapper">
        <div class="flex-container">
            <div class="time">
                <div class="flex-item-2">
                    <p>0.00</p>
                </div>
                <div class="flex-item-2">
                    <p>3.00</p>
                </div>
                <div class="flex-item-2">
                    <p>6.00</p>
                </div>
                <div class="flex-item-2">
                    <p>9.00</p>
                </div>
                <div class="flex-item-2">
                    <p>12.00</p>
                </div>
                <div class="flex-item-2">
                    <p>15.00</p>
                </div>
                <div class="flex-item-2">
                    <p>18.00</p>
                </div>
                <div class="flex-item-2">
                    <p>21.00</p>
                </div>
                <div class="flex-item-2">
                    <p>24.00</p>
                </div>
            </div>
            <div class="time-divider" [ngClass]="type">
                <div class="divider"></div>
                <div class="divider"></div>
                <div class="divider"></div>
                <div class="divider"></div>
                <div class="divider"></div>
                <div class="divider"></div>
                <div class="divider"></div>
                <div class="divider"></div>
                <div class="divider"></div>
            </div>
        </div>
        <div class="week-container">
            <div class="flex-item-2">
                <p>Mo</p>
            </div>
            <div class="flex-item-2">
                <p>Di</p>
            </div>
            <div class="flex-item-2">
                <p>Mi</p>
            </div>
            <div class="flex-item-2">
                <p>Do</p>
            </div>
            <div class="flex-item-2">
                <p>Fr</p>
            </div>
            <div class="flex-item-2">
                <p>Sa</p>
            </div>
            <div class="flex-item-2">
                <p>So</p>
            </div>
        </div>
        <div class="versicherte-zeit" [ngClass]="type">
            <div class="text">
                <p class="text-versicherte-zeit">Versicherte Zeit</p>
                <p class="hour-text" [ngClass]="type">{{ hours }} h</p>
            </div>
        </div>
    </div>
</div>
