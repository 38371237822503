<div>
    <dx-grid class="cards" mq2="12/*" mq3="6-6/*" mq4="4-4-4/*" mq5="3-3-3-3/*">
        <app-fipy-module-card *ngFor="let it of cards" aria-label="Karte {{it.title}}"
                              [ngClass]="{'in-modal': this.insideModal}" [card]="it"></app-fipy-module-card>
    </dx-grid>
    <div *ngIf="this.searchText?.length > 2 && this.cards?.length === 0" class="search-text">
        <dx-text type="it">Zum Suchbegriff "{{ searchText }}" gab es keine Treffer.</dx-text>
    </div>
    <div *ngIf="this.searchText && this.searchText?.length <= 2 && this.cards?.length === 0" class="search-text">
        <dx-text type="it">Mindestens drei Zeichen zum Suchen eingeben.</dx-text>
    </div>
</div>
