import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Gender } from '../gender.enum';

@Component({
    selector: 'app-kind',
    templateUrl: './kind.component.html',
    styleUrls: ['./kind.component.scss']
})
export class KindComponent implements OnInit {
    @Input() public gender = Gender.BOY;
    @Input() public isEditable = true;
    @Input() public showTeeth = false;
    @Output() isChange = new EventEmitter<boolean>();
    public isBoy = true;

    /**
     * @inheritDoc
     */
    public ngOnInit(): void {
        this.genderSelection();
    }

    /** Toggles boy/girl icon: with vs without teeth
     */
    public onShowTeeth(): void {
        if (this.isEditable) {
            this.showTeeth = !this.showTeeth;
            this.isChanged(true);
        }
    }

    /** Handles the icon to be shown: girl vs boy
     */
    public genderSelection() {
        if (this.gender === Gender.BOY) {
            this.isBoy = true;
        } else {
            this.isBoy = false;
        }
    }

    public isChanged(event: boolean) {
        this.isChange.emit(event);
    }
}
