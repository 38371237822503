import { Component } from '@angular/core';

@Component({
    selector: 'app-fipy-app-footer',
    templateUrl: './fipy-app-footer.component.html',
    styleUrls: ['./fipy-app-footer.component.scss']
})
export class FipyAppFooterComponent {
    nutzungsbedingungenModalVisible = false;
    year = new Date().getFullYear();

    showNutzungsbedingungen() {
        this.nutzungsbedingungenModalVisible = true;

    }

}
